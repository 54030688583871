export type BadgeVariant = "default" | "destructive" | "secondary" | "outline";

export const getDeviceDisplayStatus = (
  status: string | undefined,
  testStatus: string | undefined
) => {
  // Convert status to uppercase for case-insensitive comparison
  const upperStatus = status?.toUpperCase();
  const upperTestStatus = testStatus?.toUpperCase();

  console.log("upperStatus", upperStatus);
  console.log("upperTestStatus", upperTestStatus);

  // BOOTING cases
  if (
    ["CREATING", "STARTING", "BOOTING"].includes(upperStatus) &&
    ["ENGAGED", "WAITING"].includes(upperTestStatus)
  ) {
    return {
      label: "BOOTING",
      variant: "destructive" as BadgeVariant
    };
  }

  // ONLINE_IDLE case
  if (upperStatus === "ON" && upperTestStatus === "WAITING") {
    return {
      label: "ONLINE_IDLE",
      variant: "default" as BadgeVariant
    };
  }

  // ONLINE_TESTING case
  if (
    upperStatus === "ON" &&
    (upperTestStatus === "RUNNING" || upperTestStatus === "ENGAGED")
  ) {
    return {
      label: "ONLINE_TESTING",
      variant: "default" as BadgeVariant
    };
  }

  // Special cases where test status doesn't matter
  if (upperStatus === "OFFLINE") {
    return {
      label: "OFF",
      variant: "secondary" as BadgeVariant
    };
  }

  if (upperStatus === "SAVING") {
    return {
      label: "SAVING",
      variant: "destructive" as BadgeVariant
    };
  }

  if (
    [
      "RECYCLED",
      "ERROR",
      "REVOKED",
      "EXPIRED",
      "STOPPING",
      "DELETING"
    ].includes(upperStatus)
  ) {
    return {
      label: "UNUSABLE",
      variant: "destructive" as BadgeVariant
    };
  }

  // Default case
  return {
    label: "UNUSABLE",
    variant: "secondary" as BadgeVariant
  };
};
