import React, { useState, useEffect, useRef } from "react";
import { Package, Loader2, Upload, ArrowLeft } from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Button } from "@/components/ui/button";
import { useAuthenticatedApi } from "@/api/api";
import { useAuth0 } from "@auth0/auth0-react";
import { logger } from "@/utils/logger";

const ApkVersionsList = ({ versions }) => {
  if (!versions.length) {
    return (
      <div className="text-center text-muted-foreground py-8">
        No versions available
      </div>
    );
  }

  return (
    <div className="space-y-3">
      {versions.map((version, index) => (
        <Card key={index}>
          <CardContent className="py-4">
            <div className="grid grid-cols-3 gap-4">
              <div>
                <p className="text-sm font-medium">Version</p>
                <p className="text-sm text-muted-foreground">
                  {version.displayVersion}
                </p>
              </div>
              <div>
                <p className="text-sm font-medium">Build</p>
                <p className="text-sm text-muted-foreground">
                  {version.buildVersion}
                </p>
              </div>
              <div>
                <p className="text-sm font-medium">Released</p>
                <p className="text-sm text-muted-foreground">
                  {new Date(version.createTime).toLocaleDateString()}
                </p>
              </div>
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

const ApkDetails = ({ apk, onBack }) => {
  const [versions, setVersions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState("");
  const [uploadStatus, setUploadStatus] = useState("idle");
  const fileInputRef = useRef(null);
  const api = useAuthenticatedApi();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    fetchVersions();
    // Reset upload states when changing APKs
    setUploadMessage("");
    setUploadStatus("idle");
  }, [apk.id]);

  const fetchVersions = async () => {
    try {
      const data = await api.get(`/apk-versions/${apk.id}/releases`);
      setVersions(data);
    } catch (error) {
      logger.error("Error fetching versions:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpload = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (!file.name.endsWith(".apk")) {
      setUploadMessage("Please select an APK file");
      setUploadStatus("error");
      return;
    }

    setIsUploading(true);
    setUploadStatus("loading");
    setUploadMessage("Uploading APK...");

    try {
      const formData = new FormData();
      formData.append("file", file);

      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/apk-versions/${apk.id}/upload`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`
          },
          body: formData
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.detail || "Upload failed");
      }

      setUploadMessage(data.message);
      setUploadStatus("success");
      await fetchVersions();
    } catch (error) {
      logger.error("Upload error:", error);
      setUploadMessage(error.message || "Failed to upload APK");
      setUploadStatus("error");
    } finally {
      setIsUploading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Button variant="ghost" size="sm" onClick={onBack}>
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back
          </Button>
        </div>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleUpload}
          accept=".apk"
          className="hidden"
        />
        <Button
          onClick={() => fileInputRef.current?.click()}
          disabled={isUploading}
        >
          {isUploading ? (
            <>
              <Loader2 className="h-4 w-4 mr-2 animate-spin" />
              Uploading...
            </>
          ) : (
            <>
              <Upload className="h-4 w-4 mr-2" />
              Upload APK
            </>
          )}
        </Button>
      </div>

      <Card>
        <CardHeader>
          <div className="flex items-center space-x-2">
            <Package className="h-5 w-5 text-muted-foreground" />
            <CardTitle>{apk.app_name}</CardTitle>
          </div>
          <CardDescription>{apk.package_name}</CardDescription>
        </CardHeader>
      </Card>

      {uploadMessage && (
        <div
          className={`p-4 rounded-lg ${
            uploadStatus === "error"
              ? "bg-destructive/10 text-destructive"
              : uploadStatus === "success"
              ? "bg-green-500/10 text-green-600"
              : "bg-muted text-muted-foreground"
          }`}
        >
          {uploadMessage}
        </div>
      )}

      <div>
        <h3 className="text-lg font-medium mb-4">Versions</h3>
        {isLoading ? (
          <div className="flex justify-center py-8">
            <Loader2 className="h-6 w-6 animate-spin text-muted-foreground" />
          </div>
        ) : (
          <ApkVersionsList versions={versions} />
        )}
      </div>
    </div>
  );
};

export const ApkList = () => {
  const [apks, setApks] = useState([]);
  const [selectedApk, setSelectedApk] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const api = useAuthenticatedApi();

  useEffect(() => {
    const fetchApks = async () => {
      try {
        const data = await api.get("/apks/organization-apks");
        setApks(data);
      } catch (error) {
        logger.error("Error fetching APKs:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchApks();
  }, []);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-40">
        <Loader2 className="h-6 w-6 animate-spin text-muted-foreground" />
      </div>
    );
  }

  if (selectedApk) {
    return <ApkDetails apk={selectedApk} onBack={() => setSelectedApk(null)} />;
  }

  return (
    <ScrollArea className="h-[calc(100vh-240px)]">
      <div className="space-y-4">
        {apks.map((apk) => (
          <Card
            key={apk.id}
            className="group hover:shadow-md transition-shadow cursor-pointer"
            onClick={() => setSelectedApk(apk)}
          >
            <CardHeader className="pb-2">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <Package className="h-5 w-5 text-muted-foreground" />
                  <CardTitle className="text-base font-medium">
                    {apk.app_name}
                  </CardTitle>
                </div>
              </div>
              <CardDescription className="text-sm text-muted-foreground">
                {apk.package_name}
              </CardDescription>
            </CardHeader>
          </Card>
        ))}
      </div>
    </ScrollArea>
  );
};

export default ApkList;
