import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "@/store/hooks";
import { selectConfig } from "@/store/features/editorSlice";
import { logger } from "@/utils/logger";

export const useConfigCheck = () => {
  const navigate = useNavigate();
  const config = useAppSelector(selectConfig);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!config.instanceName || !config.logId) {
      logger.log("Dont have config Name");
      // Preserve all existing query parameters
      const currentParams = new URLSearchParams(searchParams);

      // Navigate to /start with existing params
      navigate({
        pathname: "/start",
        search: currentParams.toString()
      });
    }
  }, [config, navigate, searchParams]);

  return {
    hasValidConfig: !!(config.instanceName && config.appName),
    config
  };
};
