import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Sun, Moon, User, Settings, Building2, LogOut } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
  DropdownMenuLabel,
  DropdownMenuGroup
} from "@/components/ui/dropdown-menu";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { cn } from "@/lib/utils";

interface HeaderProps {
  isSidebarCollapsed: boolean;
}

const HeaderProfile = () => {
  const { logout, user } = useAuth0();

  return (
    <div className="flex items-center gap-4">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-8 w-8 rounded-full">
            <Avatar className="h-8 w-8">
              <AvatarImage src={user?.picture} alt={user?.name} />
              <AvatarFallback className="bg-muted text-muted-foreground">
                {user?.name?.slice(0, 2).toUpperCase()}
              </AvatarFallback>
            </Avatar>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-56 mt-1">
          <DropdownMenuLabel className="font-normal">
            <div className="flex flex-col space-y-1">
              <p className="text-sm font-medium text-foreground">
                {user?.name}
              </p>
              <p className="text-xs text-muted-foreground">{user?.email}</p>
            </div>
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          {/* <DropdownMenuGroup>
            <DropdownMenuItem>
              <User className="h-4 w-4 mr-2" />
              Profile
            </DropdownMenuItem>
            <DropdownMenuItem>
              <Settings className="h-4 w-4 mr-2" />
              Settings
            </DropdownMenuItem>
          </DropdownMenuGroup> */}
          <DropdownMenuSeparator />
          <DropdownMenuItem
            className="text-destructive"
            onClick={() =>
              logout({ logoutParams: { returnTo: window.location.origin } })
            }
          >
            <LogOut className="h-4 w-4 mr-2" />
            Log out
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

const Header: React.FC<HeaderProps> = ({ isSidebarCollapsed }) => {
  const { user } = useAuth0();

  return (
    <header className="h-14 border-b border-border bg-background/80 backdrop-blur-xl fixed top-0 right-0 left-0 z-20">
      <div
        className={cn(
          "h-full flex items-center justify-between px-4",
          isSidebarCollapsed ? "ml-16" : "ml-64"
        )}
      >
        <div className="flex items-center gap-2">
          <span className="text-sm font-medium text-muted-foreground">
            Welcome back, {user?.name?.split(" ")[0] || "User"}
          </span>
        </div>
        <HeaderProfile />
      </div>
    </header>
  );
};

export default Header;
