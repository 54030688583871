import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  Github,
  GitBranch,
  Lock,
  Globe,
  Check,
  RefreshCw,
  Loader2,
  Plus
} from "lucide-react";
import { Switch } from "@/components/ui/switch";
import { toast } from "@/hooks/use-toast";
import { useAuthenticatedApi } from "@/api/api";

const GitHubSettings = ({ project, onProjectUpdate }) => {
  const api = useAuthenticatedApi();

  // States
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("select");
  const [saving, setSaving] = useState(false);
  const [connecting, setConnecting] = useState(false);
  const [disconnecting, setDisconnecting] = useState(false);
  const [githubStatus, setGithubStatus] = useState(null);
  const [repositories, setRepositories] = useState([]);
  const [selectedRepo, setSelectedRepo] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [branches, setBranches] = useState([]);
  const [syncEnabled, setSyncEnabled] = useState(
    project?.github_sync_enabled || false
  );
  const [showRepoSelection, setShowRepoSelection] = useState(false);

  // New repository states
  const [newRepoData, setNewRepoData] = useState({
    repo_name: "",
    description: "",
    private: true
  });

  // Initialize
  useEffect(() => {
    const initialize = async () => {
      try {
        const integration = await api.get("/github/integration");
        if (integration) {
          setGithubStatus(integration);
          await fetchRepositories();

          // Set current project repo if exists
          if (project?.github_repo_name) {
            const repo = repositories.find(
              (r) =>
                r.name === project.github_repo_name &&
                r.owner.login === project.github_repo_owner
            );
            if (repo) {
              setSelectedRepo(repo);
              await fetchBranches(repo.full_name);
              setSelectedBranch(project.github_branch || "main");
            }
          }
        }
      } catch (error) {
        if (error.response?.status !== 404) {
          console.error("Failed to check GitHub status:", error);
        }
      } finally {
        setLoading(false);
      }
    };

    initialize();
  }, [project?.id]);

  // Fetch repositories
  const fetchRepositories = async () => {
    try {
      const response = await api.get("/github/repositories");
      setRepositories(response.repositories);
    } catch (error) {
      console.error("Failed to fetch repositories:", error);
      toast({
        title: "Error",
        description: "Failed to fetch repositories",
        variant: "destructive"
      });
    }
  };

  const checkInstallationStatus = async (sessionId) => {
    try {
      const response = await api.get(
        `/github/installation/${sessionId}/status`
      );
      if (response.status === "completed") {
        setGithubStatus(response);
        return true;
      }
      return false;
    } catch (error) {
      console.error("Failed to check installation status:", error);
      return false;
    }
  };

  const handleConnectGitHub = async () => {
    setConnecting(true);
    try {
      const response = await api.post("/github/installation/init", {});
      const { installation_url, session_id } = response;

      // Open GitHub installation in new tab
      window.open(installation_url, "_blank");

      // Poll for status
      const pollInterval = setInterval(async () => {
        const isComplete = await checkInstallationStatus(session_id);
        if (isComplete) {
          clearInterval(pollInterval);
          setConnecting(false);
          await fetchRepositories();
          toast({
            title: "Success",
            description: "GitHub connected successfully"
          });
        }
      }, 2000);
    } catch (error) {
      setConnecting(false);
      toast({
        title: "Error",
        description: "Failed to connect to GitHub",
        variant: "destructive"
      });
    }
  };

  // Fetch branches
  const fetchBranches = async (fullName) => {
    try {
      const response = await api.get(
        `/github/repositories/${fullName}/branches`
      );
      setBranches(response.branches);
    } catch (error) {
      console.error("Failed to fetch branches:", error);
      toast({
        title: "Error",
        description: "Failed to fetch repository branches",
        variant: "destructive"
      });
    }
  };

  // Handle repository selection
  const handleRepoSelect = async (repo) => {
    setSelectedRepo(repo);
    await fetchBranches(repo.full_name);
    setSelectedBranch(repo.default_branch || "main");
  };

  // Create new repository
  const handleCreateRepo = async () => {
    try {
      if (!newRepoData.repo_name) {
        toast({
          title: "Error",
          description: "Repository name is required",
          variant: "destructive"
        });
        return;
      }

      const response = await api.post("/github/create_repo", newRepoData);

      // Create repo object
      const newRepo = {
        id: response.id,
        name: response.name,
        full_name: response.full_name,
        description: response.description,
        private: response.private,
        owner: response.owner,
        default_branch: response.default_branch
      };

      // Update repositories and select the new one
      setRepositories((prev) => [...prev, newRepo]);
      await handleRepoSelect(newRepo);

      toast({
        title: "Success",
        description: "Repository created successfully"
      });

      // Switch to select tab after successful creation
      setActiveTab("select");
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to create repository",
        variant: "destructive"
      });
    }
  };

  // Save GitHub settings
  const handleSave = async () => {
    if (!selectedRepo) return;

    setSaving(true);
    try {
      const updateData = {
        github_repo_id: selectedRepo.id,
        github_repo_owner: selectedRepo.owner.login,
        github_repo_name: selectedRepo.name,
        github_branch: selectedBranch,
        github_sync_enabled: syncEnabled
      };

      const updated = await api.patch(`/projects/${project.id}`, updateData);
      onProjectUpdate(updated);
      setShowRepoSelection(false);

      toast({
        title: "Success",
        description: "GitHub settings updated successfully"
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update GitHub settings",
        variant: "destructive"
      });
    } finally {
      setSaving(false);
    }
  };

  // Handle disconnect
  const handleDisconnect = async () => {
    setDisconnecting(true);
    try {
      await api.delete("/github/integration");

      // Clear GitHub-related project data
      const updateData = {
        github_repo_id: null,
        github_repo_owner: null,
        github_repo_name: null,
        github_branch: null,
        github_sync_enabled: false
      };

      const updated = await api.patch(`/projects/${project.id}`, updateData);
      onProjectUpdate(updated);

      // Reset states
      setGithubStatus(null);
      setSelectedRepo(null);
      setSelectedBranch("");
      setBranches([]);
      setSyncEnabled(false);
      setShowRepoSelection(false);

      toast({
        title: "Success",
        description: "GitHub disconnected successfully"
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to disconnect GitHub",
        variant: "destructive"
      });
    } finally {
      setDisconnecting(false);
    }
  };

  // Render loading state
  if (loading) {
    return (
      <Card>
        <CardContent className="flex items-center justify-center py-6">
          <Loader2 className="h-6 w-6 animate-spin text-primary" />
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="border-border">
      <CardHeader>
        <CardTitle className="flex items-center gap-2 text-lg text-card-foreground">
          <Github className="h-5 w-5 text-primary" />
          GitHub Integration
        </CardTitle>
        <CardDescription>
          Configure repository synchronization settings
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-6">
        {/* Status Bar */}
        <div className="flex items-center justify-between p-4 rounded-lg bg-primary/5 border border-primary/10">
          <div className="flex items-center gap-3">
            <div className="h-8 w-8 rounded-full bg-primary/10 flex items-center justify-center">
              {githubStatus ? (
                <Check className="h-4 w-4 text-primary" />
              ) : (
                <Github className="h-4 w-4 text-primary" />
              )}
            </div>
            <div>
              <div className="font-medium">
                {githubStatus ? "Connected to GitHub" : "GitHub Integration"}
              </div>
              <div className="text-sm text-muted-foreground">
                {githubStatus
                  ? githubStatus.username
                  : "Connect to enable repository sync"}
              </div>
            </div>
          </div>

          <div className="flex items-center gap-2">
            {githubStatus ? (
              <>
                <Button
                  variant="ghost"
                  size="sm"
                  className="gap-2"
                  onClick={() => setShowRepoSelection(!showRepoSelection)}
                >
                  {showRepoSelection ? "Cancel" : "Change Repository"}
                </Button>
                <Button
                  variant="ghost"
                  size="sm"
                  className="gap-2 text-destructive hover:text-destructive hover:bg-destructive/10"
                  onClick={handleDisconnect}
                  disabled={disconnecting}
                >
                  {disconnecting ? (
                    <Loader2 className="h-4 w-4 animate-spin" />
                  ) : (
                    <Github className="h-4 w-4" />
                  )}
                  Disconnect
                </Button>
              </>
            ) : (
              <Button
                variant="outline"
                size="sm"
                className="gap-2"
                onClick={handleConnectGitHub}
                disabled={connecting}
              >
                {connecting ? (
                  <>
                    <Loader2 className="h-4 w-4 animate-spin" />
                    Connecting...
                  </>
                ) : (
                  <>
                    <Github className="h-4 w-4" />
                    Connect GitHub
                  </>
                )}
              </Button>
            )}
          </div>
        </div>

        {/* <div className="flex items-center gap-2">
            {githubStatus && (
              <>
                <Button
                  variant="ghost"
                  size="sm"
                  className="gap-2"
                  onClick={() => setShowRepoSelection(!showRepoSelection)}
                >
                  {showRepoSelection ? "Cancel" : "Change Repository"}
                </Button>
                <Button
                  variant="ghost"
                  size="sm"
                  className="gap-2 text-destructive hover:text-destructive hover:bg-destructive/10"
                  onClick={handleDisconnect}
                  disabled={disconnecting}
                >
                  {disconnecting ? (
                    <Loader2 className="h-4 w-4 animate-spin" />
                  ) : (
                    <Github className="h-4 w-4" />
                  )}
                  Disconnect
                </Button>
              </>
            )}
          </div> */}
        {/* </div> */}

        {/* Current Repository Display */}
        {githubStatus && selectedRepo && !showRepoSelection && (
          <div className="space-y-4">
            <div className="p-4 rounded-lg border">
              <div className="flex items-center gap-2 mb-2">
                {selectedRepo.private ? (
                  <Lock className="h-4 w-4" />
                ) : (
                  <Globe className="h-4 w-4" />
                )}
                <span className="font-medium">{selectedRepo.name}</span>
                <Badge variant="secondary">
                  {selectedRepo.private ? "Private" : "Public"}
                </Badge>
              </div>
              <p className="text-sm text-muted-foreground mb-2">
                {selectedRepo.description || "No description"}
              </p>
              <div className="flex items-center gap-2 text-sm">
                <GitBranch className="h-4 w-4" />
                <span>Branch: {selectedBranch}</span>
              </div>
            </div>

            {project?.github_last_sync_at && (
              <div className="flex items-center gap-2 p-3 rounded-md bg-secondary text-sm">
                <RefreshCw className="h-4 w-4 text-primary" />
                <span>
                  Last synced{" "}
                  {new Date(project.github_last_sync_at).toLocaleString()}
                </span>
              </div>
            )}
          </div>
        )}

        {/* Repository Selection/Creation Tabs */}
        {githubStatus && showRepoSelection && (
          <Tabs
            value={activeTab}
            onValueChange={setActiveTab}
            className="w-full"
          >
            <TabsList className="w-full mb-4">
              <TabsTrigger value="select" className="w-1/2">
                Select Repository
              </TabsTrigger>
              <TabsTrigger value="create" className="w-1/2">
                Create Repository
              </TabsTrigger>
            </TabsList>

            <TabsContent value="select" className="space-y-4">
              {repositories.map((repo) => (
                <div
                  key={repo.id}
                  className="flex items-center justify-between p-3 rounded-lg border cursor-pointer hover:bg-accent"
                  onClick={() => handleRepoSelect(repo)}
                >
                  <div className="flex items-center gap-2">
                    {repo.private ? (
                      <Lock className="h-4 w-4" />
                    ) : (
                      <Globe className="h-4 w-4" />
                    )}
                    <div>
                      <div className="font-medium">{repo.name}</div>
                      <div className="text-sm text-muted-foreground">
                        {repo.description || "No description"}
                      </div>
                    </div>
                  </div>
                  <Badge
                    variant={
                      selectedRepo?.id === repo.id ? "default" : "outline"
                    }
                  >
                    {repo.private ? "Private" : "Public"}
                  </Badge>
                </div>
              ))}
            </TabsContent>

            <TabsContent value="create" className="space-y-4">
              <Input
                placeholder="Repository name"
                value={newRepoData.repo_name}
                onChange={(e) =>
                  setNewRepoData({
                    ...newRepoData,
                    repo_name: e.target.value
                  })
                }
              />
              <Textarea
                placeholder="Repository description"
                value={newRepoData.description}
                onChange={(e) =>
                  setNewRepoData({
                    ...newRepoData,
                    description: e.target.value
                  })
                }
              />
              <div className="flex items-center justify-between p-3 rounded-lg border">
                <span>Private Repository</span>
                <Switch
                  checked={newRepoData.private}
                  onCheckedChange={(checked) =>
                    setNewRepoData({
                      ...newRepoData,
                      private: checked
                    })
                  }
                />
              </div>
              <Button
                className="w-full gap-2"
                onClick={handleCreateRepo}
                disabled={!newRepoData.repo_name}
              >
                <Plus className="h-4 w-4" />
                Create Repository
              </Button>
            </TabsContent>
          </Tabs>
        )}

        {/* Save Button */}
        {githubStatus && selectedRepo && showRepoSelection && (
          <Button
            className="w-full gap-2"
            onClick={handleSave}
            disabled={saving || !selectedRepo || !selectedBranch}
          >
            {saving ? (
              <>
                <Loader2 className="h-4 w-4 animate-spin" />
                Saving...
              </>
            ) : (
              <>
                <Check className="h-4 w-4" />
                Save GitHub Settings
              </>
            )}
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default GitHubSettings;
