import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";

export function PublicRoute({ children }) {
  const { isAuthenticated, isLoading } = useAuth0();

  // Show nothing while checking auth state
  if (isLoading) {
    return null;
  }

  // Redirect authenticated users away from public routes (like login)
  if (isAuthenticated) {
    return <Navigate to="/start" replace />;
  }

  // Allow unauthenticated users to access the public route
  return children;
}
