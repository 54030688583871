import React, { useEffect, useCallback, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { EditorContainer } from "@/components/features/editor/EditorContainer";
import { Logger } from "@/components/features/logger/Logger";
import { Player } from "@/components/features/player/Player";
import { useTestExecution } from "@/hooks/useTestExecution";
import { useLogger } from "@/hooks/useLogger";
import { API_BASE_URL, SINGLE_TEST_URL } from "@/config";
import { constructPlayerUrl } from "@/utils/urlUtils";
import Header from "@/components/layout/HeaderDashboard";
import * as Sentry from "@sentry/react";
import "@/style/editor.css";
import {
  addExecutedLine,
  clearExecutedLines,
  resetEditor,
  selectConfig,
  selectExecutedLines,
  selectInitializingSingleLineMode,
  selectInstanceUUID,
  selectIsLoading,
  selectIsSingleLineMode,
  selectPlayerUrl,
  selectShowEditor,
  selectSingleModeLoading,
  setConfig,
  setInstanceUUID,
  setIsLoading,
  setIsSingleLineMode,
  setTestLogId,
  setPlayerUrl,
  setShowEditor,
  setSingleModeLoading,
  updateSaveInstance,
  selectTestLogId
} from "@/store/features/editorSlice";
import { TestConfig, EditorContainerRef } from "@/types";
import { Sidebar } from "@/components/features/sidebar/Sidebar";
import { useConfigCheck } from "@/hooks/useConfigRedirect";
import EmulatorStatusModal from "@/components/layout/EmulatorStatusModal";
import { retryFetchOperation } from "@/lib/retryFetchOperation";
import { useAuth0 } from "@auth0/auth0-react";
import { selectApis, setApis } from "@/store/features/apiSlice";
import LoadingDialog from "@/components/shared/SingleLineLoading";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useTestFile } from "../new-design/hooks/useTestFile";
import { useProjects } from "../new-design/hooks/useProject";
import { TestManagementFileDialog } from "@/components/features/test-management/TestFileDialog";
import { useAuthenticatedApi } from "@/api/api";
import { useFolder } from "../new-design/hooks/useFolder";
import ResizableLogger from "@/components/layout/ResizableLogger";
import { logger } from "@/utils/logger";

const getRelevantApiFiles = (apis: any[], testContent: string): File[] => {
  // Directly filter APIs based on whether their names appear in the test content
  const relevantApis = apis.filter((api) =>
    testContent.includes(`${api.name}`)
  );

  logger.log(
    "Found matching APIs:",
    relevantApis.map((api) => api.name)
  );

  // Create files for matching APIs
  return relevantApis.map((api) => {
    const apiBlob = new Blob([JSON.stringify(api)], {
      type: "application/json"
    });
    return new File([apiBlob], `${api.name}`);
  });
};

export const Dashboard: React.FC = () => {
  const { logs, addLog, clearLogs, parseANSIColors } = useLogger();
  const {
    testState,
    setTestState,
    parseTestSteps,
    startTest,
    streamStatus,
    stopTest,
    clearState,
    currentStreamController
  } = useTestExecution();

  const dispatch = useAppDispatch();
  const showEditor = useAppSelector(selectShowEditor);
  const isSingleLineMode = useAppSelector(selectIsSingleLineMode);
  const initializingSingleLineMode = useAppSelector(
    selectInitializingSingleLineMode
  );
  const navigate = useNavigate();
  const isLoading = useAppSelector(selectIsLoading);
  const config = useAppSelector(selectConfig);
  const testLogId = useAppSelector(selectTestLogId);
  const playerUrl = useAppSelector(selectPlayerUrl);
  const executedLines = useAppSelector(selectExecutedLines);
  const singleModeLoading = useAppSelector(selectSingleModeLoading);
  const { hasValidConfig } = useConfigCheck();
  const [showStatusModal, setShowStatusModal] = React.useState(false);
  const [statusData, setStatusData] = React.useState<any>(null);
  const [modalError, setModalError] = React.useState<string>("");
  const instanceUUID = useAppSelector(selectInstanceUUID);
  const initializationStartedRef = useRef(false);
  const editorRef = useRef<EditorContainerRef>(null);
  const logsContainer = useRef<HTMLDivElement>(null);
  const [initialContent, setInitialContent] = useState("");
  const [searchParams] = useSearchParams();
  const testcaseId = searchParams.get("testcase_id");
  const projectId = searchParams.get("project_id");

  const authenticatedApi = useAuthenticatedApi();
  const apis = useAppSelector(selectApis);
  const { user, logout } = useAuth0();
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [projects, setProjects] = useState<any[]>([]);
  const [folders, setFolders] = useState<any[] | null>(null);
  const [selectedProject, setSelectedProject] = useState<any | null>(null);
  const [isProjectLoading, setIsProjectLoading] = useState(false);
  const [initialTestCase, setInitialTestCase] = useState(null);
  const { listProjects, getProject } = useProjects();
  const { getFolders } = useFolder();
  const { updateTestFile, createTestFile, getTestFile } = useTestFile();

  useEffect(() => {
    const loadAllData = async () => {
      dispatch(setIsLoading(true));
      try {
        // Load APIs and Projects in parallel
        const [apisData, projectsData] = await Promise.all([
          authenticatedApi.get("/apis"),
          listProjects({})
        ]);

        dispatch(setApis(apisData));
        setProjects(projectsData || []);

        let selectedProj;

        if (projectId && projectsData) {
          selectedProj = projectsData.find((p) => p.id === parseInt(projectId));
        }

        const projectToSelect =
          selectedProj || (projectsData && projectsData[0]);

        if (projectToSelect) {
          try {
            // Fetch project details and folders in parallel
            const [projectDetails, foldersData] = await Promise.all([
              getProject(projectToSelect.id),
              getFolders(projectToSelect.id)
            ]);

            logger.log("projectDetails", projectDetails);

            // Update state with complete project data and folders
            setSelectedProject(projectDetails);
            setFolders(foldersData || []);

            // Update URL if projectId isn't present
            if (!projectId) {
              const newSearchParams = new URLSearchParams(searchParams);
              newSearchParams.set("project_id", projectToSelect.id.toString());
              navigate(`?${newSearchParams.toString()}`);
            }
          } catch (error) {
            logger.error("Error fetching project data:", error);
            addLog("Failed to load project data", "error");
          }
        }
      } catch (error) {
        logger.error("Error loading initial data:", error);
      } finally {
        dispatch(setIsLoading(false));
      }
    };

    loadAllData();
  }, []);

  useEffect(() => {
    const loadTestCase = async () => {
      if (!testcaseId) return;

      dispatch(setIsLoading(true));
      try {
        const testCase = await getTestFile(parseInt(testcaseId));
        setInitialTestCase(testCase);
        const formattedContent = [testCase.content].join("\n");
        setInitialContent(formattedContent);
        if (editorRef.current) {
          editorRef.current.setValue(formattedContent);
        }
        logger.log("formattedContent", formattedContent);
        parseTestSteps(testCase.content);
      } catch (error) {
        logger.error("Error loading test case:", error);
        addLog("Failed to load test case", "error");
      } finally {
        dispatch(setIsLoading(false));
      }
    };

    loadTestCase();
  }, [testcaseId]);

  useEffect(() => {
    currentStreamController.current = new AbortController();
    return () => {
      if (currentStreamController.current) {
        currentStreamController.current.abort();
      }
    };
  }, []);

  useEffect(() => {
    if (logsContainer.current) {
      logsContainer.current.scrollTop = logsContainer.current.scrollHeight;
    }
  }, [logs]);

  useEffect(() => {
    const handleInitialization = async () => {
      if (
        !isSingleLineMode &&
        initializingSingleLineMode &&
        !initializationStartedRef.current
      ) {
        initializationStartedRef.current = true;
        try {
          logger.log("Starting Single Line Mode");
          await initializeSingleLineMode(true);
          dispatch(setIsSingleLineMode(true));
        } catch (error) {
          logger.error("Failed to initialize single line mode:", error);
        } finally {
          initializationStartedRef.current = false;
        }
      }
    };

    handleInitialization();
  }, [initializingSingleLineMode]);

  const handleFileSelect = (file) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("testcase_id", file.id.toString());
    navigate(`?${newSearchParams.toString()}`);

    // Fetch the selected file content
    if (file.id) {
      fetchTestCase(file.id);
    }
  };

  const fetchTestCase = async (testcaseId) => {
    try {
      dispatch(setIsLoading(true));
      const response = await getTestFile(parseInt(testcaseId));

      // Set the content in editor
      if (editorRef.current) {
        const formattedContent = [response.content].join("\n");
        editorRef.current.setValue(formattedContent);
        setInitialContent(formattedContent);
        parseTestSteps(response.content);
      }

      setInitialTestCase(response);
    } catch (error) {
      logger.error("Error fetching test case:", error);
      addLog("Failed to load test case", "error");
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  // Handle project selection
  const handleProjectSelect = async (project) => {
    setSelectedProject(project);
    setFolders(null);

    try {
      const [projectDetails, foldersData] = await Promise.all([
        getProject(project.id),
        getFolders(project.id)
      ]);

      setSelectedProject(projectDetails);
      setFolders(foldersData);

      // Update URL
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("project_id", project.id.toString());
      navigate(`?${newSearchParams.toString()}`);
    } catch (error) {
      logger.error("Error fetching project data:", error);
      addLog("Failed to load project data", "error");
    }
  };

  const handleRun = async () => {
    if (!config) {
      setTestState({ ...testState, isRunning: false });
      return;
    }
    if (currentStreamController.current) {
      currentStreamController.current.abort();
    }
    currentStreamController.current = new AbortController();

    try {
      setTestState({ ...testState, isRunning: true });
      console.log("Editor Status", testState.isRunning);
      const editorContent = editorRef.current?.getValue() || "";
      if (!editorContent.trim()) {
        addLog("Please enter test content or upload a test file.", "error");
        setTestState({ ...testState, isRunning: false });
        return;
      }

      clearLogs();
      const blob = new Blob([editorContent], { type: "text/plain" });
      const testFile = new File([blob], "test-script", { type: "text/plain" });

      const statusResponse = await retryFetchOperation(() =>
        fetch(
          `${API_BASE_URL}/emulator-status/?instance_name=${config.instanceName}`,
          {
            headers: {
              "ngrok-skip-browser-warning": "1"
            }
          }
        )
      );
      const status = null;
      const data = await statusResponse.json();

      if (
        statusResponse.status === 404 ||
        (data && data.test_status === "waiting")
      ) {
        const apiFiles = getRelevantApiFiles(apis, editorContent);
        logger.log("apiFiles", apiFiles);
        // Log which APIs were found and will be used
        logger.log(`Found ${apiFiles.length} relevant APIs for the test`);
        apiFiles.forEach((file) => {
          addLog(`Including API: ${file.name}`, "info");
        });

        const appDisplayInfo = config.appName?.manualAppName
          ? config.appName.manualAppName
          : config.appName?.selectedApps
              ?.map((app) => `${app.appName} (${app.packageName})`)
              .join(", ");

        const parameterBox = `
          <div class="outer-log-box">
            <p class="log-title">Starting Test With Following Parameters</p>
            <p><span class="parameter-label">Instance Name:</span><span class="parameter-value">${
              config.instanceName
            }</span></p>
            <p><span class="parameter-label">Application:</span><span class="parameter-value">${
              appDisplayInfo || "Not specified"
            }</span></p>
            <p><span class="parameter-label">File Name:</span><span class="parameter-value">test-script</span></p>
            <p><span class="parameter-label">Save Instance:</span><span class="parameter-value">${
              config.saveInstance
            }</span></p>
          </div>
        `;
        addLog(parameterBox);
        let appName;
        let appPackages;

        if (config.appName?.manualAppName) {
          // If manual app name is used
          appName = config.appName.manualAppName;
          appPackages = null; // Don't send appPackages for manual app
        } else {
          // If selected apps are used
          appName = config.appName?.selectedApps?.[0]?.packageName;
          appPackages = config.appName?.selectedApps?.map((app) => ({
            app_package_name: app.packageName,
            app_name: app.appName,
            version: app.version
          }));
        }

        logger.log("appName", appName);
        logger.log("config.saveInstance", config.saveInstance);
        logger.log("Config", config);

        if (!appName) {
          addLog("No package name found in configuration.", "error");
          setTestState({ ...testState, isRunning: false });
          return;
        }

        console.log("App Name Config", config);

        const data = await startTest(
          config.instanceName,
          appName,
          testFile,
          config.saveInstance,
          apiFiles,
          appPackages
        );

        dispatch(setTestLogId(data.test_log_id));

        if (data.status === "running") {
          const statusUrl1 = `${API_BASE_URL}/status/${config.instanceName}/${config.logId}`;
          const statusUrl2 = `${API_BASE_URL}/status/${config.instanceName}/${data.test_log_id}`;
          console.log("statusUrl2", statusUrl1);
          console.log("statusUrl2", statusUrl2);
          try {
            await Promise.all([
              streamStatus(
                statusUrl1,
                {
                  onLogUpdate: (chunk) => {
                    const parsedChunk = parseANSIColors(chunk);
                    addLog(parsedChunk);
                  },
                  onInstanceFound: async (instanceUuid, url) => {
                    try {
                      dispatch(setInstanceUUID(instanceUuid));
                      const playerUrl = await constructPlayerUrl(
                        instanceUuid,
                        url
                      );
                      dispatch(setPlayerUrl(playerUrl));
                    } catch (error) {
                      logger.error("JWT Token error:", error);
                    }
                  }
                },
                currentStreamController.current
              ),
              streamStatus(
                statusUrl2,
                {
                  onLogUpdate: (chunk) => {
                    const parsedChunk = parseANSIColors(chunk);
                    addLog(parsedChunk);
                  },
                  onInstanceFound: async (instanceUuid, url) => {
                    try {
                      dispatch(setInstanceUUID(instanceUuid));
                      const playerUrl = await constructPlayerUrl(
                        instanceUuid,
                        url
                      );
                      dispatch(setPlayerUrl(playerUrl));
                    } catch (error) {
                      logger.error("JWT Token error:", error);
                    }
                  }
                },
                currentStreamController.current
              )
            ]);
          } catch (error) {
            logger.error("Stream error:", error);
            setTestState({ ...testState, isRunning: false });
          }
        } else {
          addLog("Error: Could not start test.", "error");
          setTestState({ ...testState, isRunning: false });
        }
      } else if (data.detail) {
        setModalError(data.detail);
        setShowStatusModal(true);
        setTestState({ ...testState, isRunning: false });
      } else {
        setStatusData(data);
        setModalError("");
        setShowStatusModal(true);
        setTestState({ ...testState, isRunning: false });
      }
    } catch (error) {
      Sentry.captureException(new Error(error));
      if (error instanceof Error) {
        addLog(`${error.message}`, "error");
      }
      setTestState({ ...testState, isRunning: false });
    }
  };

  const handleSaveCode = async () => {
    if (!editorRef.current) return;
    const content = editorRef.current.getValue();

    // Skip if content is empty or only whitespace
    if (!content.trim()) return;

    const testcaseId = searchParams.get("testcase_id");

    if (testcaseId) {
      // Direct save for existing test case
      try {
        const testData = {
          content: content,
          name: initialTestCase?.name,
          test_metadata: initialTestCase?.test_metadata,
          status: "NEW",
          version: 1
        };

        await updateTestFile(parseInt(testcaseId), testData);
        addLog("Test case updated successfully", "info");
      } catch (error) {
        logger.error("Error updating test case:", error);
        addLog("Failed to update test case", "error");
      }
    } else {
      // Show dialog for new test case
      setSaveDialogOpen(true);
    }
  };

  const handleSaveConfirm = async ({ projectId, folderId, fileName }) => {
    if (!editorRef.current) return;
    const content = editorRef.current.getValue();

    // Skip if content is empty or only whitespace
    if (!content.trim()) return;

    try {
      const testData = {
        name: fileName,
        content: content,
        folder_id: folderId,
        test_metadata: {
          description: "Test case created from editor"
        },
        status: "NEW",
        version: 1
      };

      const response = await createTestFile(testData);
      // Add testcaseId to URL params after successful save
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("testcase_id", response.id.toString());
      navigate(`?${newSearchParams.toString()}`);

      if (selectedProject) {
        const updatedFolders = await getFolders(selectedProject.id);
        setFolders(updatedFolders);
      }

      addLog("Test case saved successfully", "info");
      setSaveDialogOpen(false);
    } catch (error) {
      logger.error("Error saving test case:", error);
      addLog("Failed to save test case", "error");
    }
  };

  const handleStop = async () => {
    await stopTest();
  };

  const handleEditorChange = (value: string) => {
    parseTestSteps(value);
  };

  const handleSaveInstanceChange = (checked: boolean) => {
    dispatch(updateSaveInstance(checked));
  };

  const initializeSingleLineMode = async (enabled: boolean) => {
    dispatch(setSingleModeLoading(true));
    if (enabled && config.instanceName && config.appName) {
      try {
        currentStreamController.current = new AbortController();
        logger.log("Before process");
        addLog("Initializing single line mode...", "info");

        const response = await fetch(`${SINGLE_TEST_URL}/start_test`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            instance_name: config.instanceName,
            package_name: config.appName,
            client_ID: "client123"
          })
        });
        dispatch(setSingleModeLoading(false));
        const data = await response.json();
        logger.log("data", data);
        if (data.status === "Test started") {
          dispatch(clearExecutedLines());
          dispatch(setIsSingleLineMode(true));

          // Log initial setup with required patterns that streamStatus expects
          const initialLogs = [
            `Starting emulator instance...`,
            `Found instance_uuid: ${data.emulator_id}`,
            `Found webrtc_url: ${data.webrtc_url}`,
            `Instance initialized successfully`
          ].join("\n");

          addLog(initialLogs);

          const parameterBox = `
          <div class="outer-log-box">
            <p class="log-title">Single Line Mode Initialized</p>
            <p><span class="parameter-label">Instance Name:</span><span class="parameter-value">${config.instanceName}</span></p>
            <p><span class="parameter-label">Package Name:</span><span class="parameter-value">${config.appName}</span></p>
            <p><span class="parameter-label">Emulator ID:</span><span class="parameter-value">${data.emulator_id}</span></p>
            <p><span class="parameter-label">WebRTC URL:</span><span class="parameter-value">${data.webrtc_url}</span></p>
          </div>
        `;
          addLog(parameterBox);

          try {
            const playerUrl = await constructPlayerUrl(
              data.emulator_id,
              data.webrtc_url
            );
            dispatch(setPlayerUrl(playerUrl));
            addLog("Player URL configured successfully", "info");

            // Start streaming with a slight delay to ensure instance is ready
            setTimeout(async () => {
              if (currentStreamController.current) {
                // Use the same URL pattern as handleRun
                const statusUrl = `${SINGLE_TEST_URL}/status/${config.instanceName}`;

                addLog("Establishing log stream connection...", "info");

                try {
                  await streamStatus(
                    statusUrl,
                    {
                      onLogUpdate: (chunk) => {
                        const parsedChunk = parseANSIColors(chunk);
                        logger.log("parsedChunk", parsedChunk);
                        addLog(parsedChunk, "info");
                      },
                      onInstanceFound: async () => {}
                    },
                    currentStreamController.current
                  );
                } catch (error) {
                  if (error instanceof Error && error.name !== "AbortError") {
                    logger.error("Stream error:", error);
                    addLog(`Stream error: ${error.message}`, "error");
                  }
                }
              }
            }, 2000);

            addLog(
              "Single line mode ready. You can now execute instructions line by line.",
              "info"
            );
          } catch (error) {
            logger.error("Setup error:", error);
            addLog(
              "Setup completed with limited functionality. Your instance is still running.",
              "error"
            );
          }
        } else {
          dispatch(setSingleModeLoading(false));
          throw new Error("Failed to initialize single line mode");
        }
      } catch (error) {
        logger.error("Error initializing single line mode:", error);
        dispatch(setSingleModeLoading(false));
        addLog(
          `Failed to initialize single line mode: ${
            error instanceof Error ? error.message : "Unknown error"
          }`,
          "error"
        );
        dispatch(setIsSingleLineMode(false));
      }
    } else {
      dispatch(clearExecutedLines());
      dispatch(setIsSingleLineMode(false));
      dispatch(setSingleModeLoading(false));
    }
  };

  const handleReset = async () => {
    const shouldClear = window.confirm(
      "Are you sure you want to reset everything?"
    );
    if (shouldClear) {
      try {
        const formData = new FormData();
        formData.append("instance_name", config.instanceName);
        formData.append(
          "caller_id",
          user?.email ? user.email.split("@")[0] : ""
        );

        await fetch(`${API_BASE_URL}/stop_instance/`, {
          method: "POST",
          headers: {
            "ngrok-skip-browser-warning": "1"
          },
          body: formData
        });
        await clearState();
        clearLogs();
        dispatch(resetEditor());

        if (editorRef.current) {
          editorRef.current.setValue("");
          if (editorRef.current.clearExecutedLines) {
            editorRef.current.clearExecutedLines();
          }
        }

        localStorage.removeItem("editorContent");

        setTimeout(() => {
          addLog("Logs will appear here...", "info");
        }, 100);
      } catch (error) {
        logger.error("Error during clear:", error);
      }
    }
  };

  const handleCloseInstance = async () => {
    try {
      // First abort any ongoing streams
      if (currentStreamController.current) {
        currentStreamController.current.abort();
      }

      // Show loading in logs
      addLog("Closing instance...", "info");

      const response = await fetch(`${SINGLE_TEST_URL}/stop_test`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          instance_name: config.instanceName,
          package_name: config.appName,
          client_ID: "client123"
        })
      });

      const data = await response.json();
      logger.log("Data", data);
      handleReset();
    } catch (error) {
      logger.error("Error closing instance:", error);
      addLog(
        `Failed to close instance: ${
          error instanceof Error ? error.message : "Unknown error"
        }`,
        "error"
      );
    }
  };

  const handleDownloadCode = () => {
    if (!editorRef.current) return;

    const content = editorRef.current.getValue();
    if (!content) {
      addLog("No content to download", "error");
      return;
    }

    try {
      const element = document.createElement("a");
      const file = new Blob([content], { type: "text/plain" });
      element.href = URL.createObjectURL(file);
      element.download = `${config.instanceName || "code"}_${
        new Date().toISOString().split("T")[0]
      }.txt`;
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (error) {
      logger.error("Error downloading code:", error);
      addLog(
        `Failed to download code: ${
          error instanceof Error ? error.message : "Unknown error"
        }`,
        "error"
      );
    }
  };

  const handleGitHubSync = async () => {
    if (!selectedProject?.id) return;

    try {
      dispatch(setIsLoading(true));

      // First save the current file if there are changes
      if (editorRef.current) {
        const content = editorRef.current.getValue();
        if (content.trim() && testcaseId) {
          try {
            const testData = {
              content: content,
              name: initialTestCase?.name,
              test_metadata: initialTestCase?.test_metadata,
              status: "NEW",
              version: 1
            };
            await updateTestFile(parseInt(testcaseId), testData);
          } catch (saveError) {
            logger.error("Error saving file before sync:", saveError);
            addLog("Failed to save current file before sync", "error");
            dispatch(setIsLoading(false));
            return; // Don't proceed with sync if save fails
          }
        }
      }

      // Then proceed with GitHub sync
      await authenticatedApi.post(
        `/github/sync?project_id=${selectedProject.id}`,
        {}
      );
      addLog("Successfully synced with GitHub", "info");
    } catch (error) {
      logger.error("Failed to sync with GitHub:", error);
      const errorMessage =
        error.response?.data?.detail || "Failed to sync with GitHub";

      let userMessage = errorMessage;
      if (errorMessage.includes("not configured with GitHub")) {
        userMessage =
          "This project is not connected to GitHub. Please configure GitHub integration first.";
      } else if (errorMessage.includes("integration not found")) {
        userMessage =
          "GitHub integration not found. Please connect your GitHub account first.";
      }

      addLog(userMessage, "error");
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  if (!hasValidConfig) {
    return null;
  }

  const handleNewFile = () => {
    // Clear the testcase_id from URL
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete("testcase_id");
    navigate(`?${newSearchParams.toString()}`);

    // Clear editor content
    if (editorRef.current) {
      editorRef.current.setValue("");
    }

    // Reset initial test case
    setInitialTestCase(null);

    addLog("Created new file", "info");
  };

  const handleSaveAs = () => {
    // Clear the testcase_id from URL without clearing content
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete("testcase_id");
    navigate(`?${newSearchParams.toString()}`);

    // Reset initial test case but keep content
    setInitialTestCase(null);

    // Open save dialog
    setSaveDialogOpen(true);
  };

  return (
    <div className="min-h-screen flex flex-col editor-body " id="editor-body">
      <LoadingDialog isOpen={singleModeLoading} />
      <Header config={config} testState={testState} />
      <div className="flex h-screen pl-16  w-full bg-[--bg-secondary]  ">
        <div className="left-section border-0">
          <div className="card" id="formCard">
            <>
              <div id="editorSection">
                <EditorContainer
                  canStop={testState.canStop}
                  isStopping={testState.isStopping}
                  isInCooldown={testState.isInCooldown}
                  ref={editorRef}
                  onEditorChange={handleEditorChange}
                  onRun={handleRun}
                  onStop={handleStop}
                  onReset={handleReset}
                  isRunning={testState.isRunning}
                  initialValue={initialContent}
                  config={config}
                  isSingleLineMode={isSingleLineMode}
                  onSaveInstanceChange={handleSaveInstanceChange}
                  onCloseInstance={handleCloseInstance}
                  onDownloadCode={handleDownloadCode}
                  isLoading={isLoading}
                  onSaveCode={handleSaveCode}
                  onNewFile={handleNewFile}
                  onSaveAs={handleSaveAs}
                  testcaseId={testcaseId}
                  showGithubSync={!!selectedProject?.github_repo_name}
                  onGitHubSync={handleGitHubSync}
                  setIsLoading={(loading: boolean) =>
                    dispatch(setIsLoading(loading))
                  }
                />
              </div>
            </>
          </div>

          <ResizableLogger initialHeight={300}>
            <Logger testScript={testState.scriptSteps} logs={logs} />
          </ResizableLogger>
        </div>

        <Player url={playerUrl} />
      </div>
      <Sidebar
        projects={projects}
        folders={folders}
        selectedProject={selectedProject}
        isLoading={isProjectLoading}
        onProjectSelect={handleProjectSelect}
        onFileSelect={handleFileSelect}
        onNewFile={handleNewFile}
      />

      <EmulatorStatusModal
        isOpen={showStatusModal}
        onClose={() => {
          setShowStatusModal(false);
          setModalError("");
        }}
        status={statusData}
        error={modalError}
        onReset={async () => {
          setShowStatusModal(false);
          setModalError("");
          await handleReset();
        }}
      />
      <div className="bg-background bg-dark">
        <TestManagementFileDialog
          isOpen={saveDialogOpen}
          onClose={() => setSaveDialogOpen(false)}
          projectId={initialTestCase?.project_id}
          folderId={initialTestCase?.folder_id}
          onSave={handleSaveConfirm}
          projects={projects}
          folders={folders}
          selectedProject={selectedProject}
          isLoading={isProjectLoading}
          onProjectSelect={handleProjectSelect}
        />
      </div>
    </div>
  );
};
