import { useAuthenticatedApi } from "@/api/api";

export const useFolder = () => {
  const api = useAuthenticatedApi();

  const getFolders = async (projectId: string) => {
    return api.get(`/folders/project/${projectId}`);
  };

  const createFolder = async (folderData: {
    name: string;
    parent_id?: number | null;
    project_id: string;
  }) => {
    return api.post("/folders", folderData);
  };

  const updateFolder = async (
    folderId: number,
    folderData: {
      name?: string;
      parent_id?: number | null;
    }
  ) => {
    return api.patch(`/folders/${folderId}`, folderData);
  };

  const deleteFolder = async (folderId: number) => {
    return api.delete(`/folders/${folderId}`);
  };

  return {
    getFolders,
    createFolder,
    updateFolder,
    deleteFolder
  };
};
