import React, { useEffect, useState } from "react";
import { Card } from "@/components/ui/card";
import {
  Github,
  Calendar,
  MoreHorizontal,
  Plus,
  Search,
  ArrowUpRight,
  ExternalLink,
  Loader2,
  FolderPlus
} from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
  DropdownMenuLabel
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Badge } from "@/components/ui/badge";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "@/components/ui/table";
import { Link, useNavigate } from "react-router-dom";
import { useAuthenticatedApi } from "@/api/api";
import { cn } from "@/lib/utils";

const ProjectsDashboard = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const api = useAuthenticatedApi();

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const data = await api.get("/projects");
        setProjects(data);
        setError(null);
      } catch (err) {
        if (err?.message?.includes('"status_code":404')) {
          setProjects([]);
          setError(null);
        } else {
          console.error("Error fetching projects:", err);
          setError(err);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  const filteredProjects = searchQuery
    ? projects.filter(
        (project) =>
          project.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          project.description?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : projects;

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric"
    });
  };

  const EmptyState = () => (
    <div className="flex flex-col items-center justify-center py-16 px-4">
      <div className="h-16 w-16 bg-primary/10 rounded-full flex items-center justify-center mb-6">
        <FolderPlus className="h-8 w-8 text-primary" />
      </div>

      <h3 className="text-xl font-semibold text-foreground mb-6">
        No Projects Yet
      </h3>

      <Button
        onClick={() => navigate("/project/create")}
        className="gap-2"
        size="lg"
      >
        <Plus className="h-4 w-4" />
        Create Your First Project
      </Button>
    </div>
  );

  return (
    <div className="h-full bg-background">
      <div className="p-8 space-y-8 max-w-7xl mx-auto">
        {/* Header Section */}
        <div className="flex flex-col gap-8">
          <div className="flex justify-between items-center">
            <div>
              <h1 className="text-3xl font-semibold tracking-tight text-foreground">
                Projects
              </h1>
              <p className="text-muted-foreground mt-1.5">
                Manage and organize your test automation projects
              </p>
            </div>
            <Button
              className="gap-2"
              size="lg"
              onClick={() => navigate("/project/create")}
            >
              <Plus className="h-4 w-4" />
              New Project
            </Button>
          </div>

          {/* Search Bar */}
          <div className="flex gap-2 items-center">
            <div className="relative flex-1 max-w-md">
              <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-muted-foreground" />
              <Input
                placeholder="Search projects..."
                className={cn(
                  "pl-9 w-full",
                  "bg-background text-foreground",
                  "border-input",
                  "focus-visible:ring-ring focus-visible:ring-2",
                  "placeholder:text-muted-foreground"
                )}
                value={searchQuery}
                onChange={handleSearch}
              />
            </div>
          </div>
        </div>

        {/* Projects Table */}
        <Card className="border-border">
          <div className="p-6">
            {loading ? (
              <div className="flex justify-center items-center py-8">
                <Loader2 className="h-8 w-8 animate-spin text-primary" />
              </div>
            ) : error ? (
              <div className="flex justify-center items-center py-8 text-destructive">
                <div className="text-center">
                  <p className="text-lg font-medium mb-2">
                    Failed to load projects
                  </p>
                  <p className="text-sm text-muted-foreground">
                    Please try again later or contact support if the issue
                    persists.
                  </p>
                </div>
              </div>
            ) : projects.length === 0 ? (
              searchQuery ? (
                <div className="flex flex-col items-center justify-center py-8 text-muted-foreground">
                  <div className="text-lg font-medium">
                    No matching projects found
                  </div>
                  <p className="text-sm mt-1">
                    Try adjusting your search terms
                  </p>
                </div>
              ) : (
                <EmptyState />
              )
            ) : (
              <Table>
                <TableHeader>
                  <TableRow className="hover:bg-transparent border-border">
                    <TableHead className="w-[100px] py-4 pl-4 text-muted-foreground">
                      ID
                    </TableHead>
                    <TableHead className="py-4 min-w-[300px] text-muted-foreground">
                      Project
                    </TableHead>
                    <TableHead className="py-4 text-muted-foreground">
                      Status
                    </TableHead>
                    <TableHead className="py-4 text-muted-foreground">
                      Created
                    </TableHead>
                    <TableHead className="py-4 text-muted-foreground">
                      GitHub
                    </TableHead>
                    <TableHead className="w-[50px] py-4 pr-4"></TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {filteredProjects.map((project) => (
                    <TableRow
                      key={project.id}
                      className={cn(
                        "group border-border",
                        "hover:bg-muted/50 transition-colors"
                      )}
                    >
                      <TableCell className="py-4 pl-4 font-medium text-muted-foreground">
                        {project.id}
                      </TableCell>

                      <TableCell className="py-4">
                        <Link to={`/project/${project.id}`}>
                          <div className="flex flex-col gap-1.5">
                            <div className="font-medium text-foreground flex items-center gap-2 group/link">
                              {project.name}
                              <ExternalLink className="h-3.5 w-3.5 text-muted-foreground/50 opacity-0 group-hover/link:opacity-100 transition-opacity" />
                            </div>
                            <span className="text-sm text-muted-foreground leading-relaxed">
                              {project.description}
                            </span>
                          </div>
                        </Link>
                      </TableCell>

                      <TableCell className="py-4">
                        <Badge
                          variant={
                            project.status === "ACTIVE"
                              ? "default"
                              : "secondary"
                          }
                        >
                          {project.status}
                        </Badge>
                      </TableCell>

                      <TableCell className="py-4">
                        <div className="flex items-center gap-2 text-muted-foreground">
                          <Calendar className="h-4 w-4" />
                          <span>{formatDate(project.created_at)}</span>
                        </div>
                      </TableCell>

                      <TableCell className="py-4">
                        <div className="flex items-center gap-2">
                          <Github className="h-4 w-4 text-muted-foreground" />
                          {project.github_repo ? (
                            <Badge
                              variant="outline"
                              className="border-green-200 text-green-600 dark:border-green-800 dark:text-green-400"
                            >
                              Connected
                            </Badge>
                          ) : (
                            <Badge
                              variant="outline"
                              className="border-border text-muted-foreground"
                            >
                              Not Connected
                            </Badge>
                          )}
                        </div>
                      </TableCell>

                      <TableCell className="py-4 pr-4">
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button
                              variant="ghost"
                              size="sm"
                              className={cn(
                                "h-8 w-8 p-0",
                                "opacity-0 group-hover:opacity-100 transition-opacity",
                                "hover:bg-accent hover:text-accent-foreground"
                              )}
                            >
                              <MoreHorizontal className="h-4 w-4" />
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent align="end" className="w-48">
                            <DropdownMenuLabel className="text-foreground">
                              Actions
                            </DropdownMenuLabel>
                            <DropdownMenuSeparator className="bg-border" />
                            <DropdownMenuItem
                              className="gap-2"
                              onClick={() => navigate(`/project/${project.id}`)}
                            >
                              View Details
                              <ArrowUpRight className="h-4 w-4 ml-auto" />
                            </DropdownMenuItem>
                            <DropdownMenuItem
                              onClick={() =>
                                navigate(`/project/setting/${project.id}`)
                              }
                            >
                              Project Settings
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ProjectsDashboard;
