import React, { useEffect, useRef, useState } from "react";
import {
  ChevronLeft,
  ChevronRight,
  FileText,
  Settings,
  Variable,
  Loader2,
  FolderOpen,
  Package
} from "lucide-react";
import { ApiList } from "@/components/features/api-management/ApiList";
import { ApiForm } from "@/components/features/api-management/ApiForm";
import { GlobalVariablesManager } from "@/components/features/api-management/GlobalVariablesManager";
import { FileExplorerContent } from "@/components/features/file-explorer/FileExplorerContent";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Separator } from "@/components/ui/separator";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "@/components/ui/tooltip";
import { ApiItem, TabType } from "@/types";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import {
  setSidebarExpanded,
  setActiveTab,
  setApis,
  setNeedsRefresh,
  selectNeedsRefresh
} from "@/store/features/apiSlice";
import { useAuth0 } from "@auth0/auth0-react";
import ApkList from "../apk-management/ApkList";

export const TABS = {
  LIST: "list" as const,
  ADD: "add" as const,
  EDIT: "edit" as const,
  VARIABLES: "variables" as const,
  FILES: "files" as const,
  APK: "apk" as const
};

interface TabConfig {
  id: TabType;
  icon: React.ReactNode;
  activeIcon?: React.ReactNode;
  tooltip: string;
  title: string;
}

interface SidebarProps {
  projects: any[];
  folders: any[];
  selectedProject: any;
  isLoading: boolean;
  onProjectSelect: (project: any) => void;
  onFileSelect: (file: any) => void;
  onNewFile: () => void;
}

export const Sidebar: React.FC<SidebarProps> = ({
  projects,
  folders,
  selectedProject,
  isLoading,
  onProjectSelect,
  onFileSelect,
  onNewFile
}) => {
  const dispatch = useAppDispatch();
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeTab, setActiveTabState] = useState<TabType | null>(null);
  const [selectedApi, setSelectedApi] = useState<ApiItem | null>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);

  const tabs: TabConfig[] = [
    {
      id: TABS.LIST,
      icon: <FileText className="h-5 w-5" />,
      // activeIcon: isLoading ? (
      //   <Loader2 className="h-5 w-5 animate-spin" />
      // ) : undefined,
      tooltip: "API List",
      title: "API List"
    },
    {
      id: TABS.FILES,
      icon: <FolderOpen className="h-5 w-5" />,
      tooltip: "File Explorer",
      title: "File Explorer"
    },
    {
      id: TABS.APK,
      icon: <Package className="h-5 w-5" />,
      tooltip: "APKs",
      title: "APKs"
    }
  ];

  const handleClickOutside = React.useCallback(
    (event: MouseEvent) => {
      const sidebar = sidebarRef.current;
      const target = event.target as Element;

      if (
        target.closest('[role="combobox"]') ||
        target.closest('[role="listbox"]') ||
        target.closest('[role="option"]')
      ) {
        return;
      }

      if (sidebar && !sidebar.contains(target) && isExpanded) {
        setIsExpanded(false);
        setActiveTabState(null);
        setSelectedApi(null);
        dispatch(setSidebarExpanded(false));
        dispatch(setActiveTab(null));
      }
    },
    [isExpanded, dispatch]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [handleClickOutside]);

  const handleTabClick = (tab: TabType) => {
    if (activeTab === tab) {
      setActiveTabState(null);
      setIsExpanded(false);
      setSelectedApi(null);
      dispatch(setSidebarExpanded(false));
      dispatch(setActiveTab(null));
    } else {
      setActiveTabState(tab);
      setIsExpanded(true);
      dispatch(setSidebarExpanded(true));
      dispatch(setActiveTab(tab));
      if (tab === TABS.LIST) {
        setSelectedApi(null);
      }
    }
  };

  const handleApiSelect = (api: ApiItem | null) => {
    setSelectedApi(api);
    if (api) {
      setActiveTabState(TABS.EDIT);
      dispatch(setActiveTab(TABS.EDIT));
    }
  };

  const handleAddNewApi = () => {
    setActiveTabState(TABS.ADD);
    setIsExpanded(true);
    dispatch(setSidebarExpanded(true));
    dispatch(setActiveTab(TABS.ADD));
  };

  const handleFormComplete = () => {
    setActiveTabState(TABS.LIST);
    setSelectedApi(null);
    dispatch(setActiveTab(TABS.LIST));
  };

  const handleFileSelect = () => {
    // Wait for 1 second before closing
    setIsExpanded(false);
    setActiveTabState(null);
    setSelectedApi(null);
    dispatch(setSidebarExpanded(false));
    dispatch(setActiveTab(null));
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case TABS.LIST:
        return (
          <ApiList
            isLoading={isLoading}
            onSelect={handleApiSelect}
            onAddNew={handleAddNewApi}
          />
        );
      case TABS.FILES:
        return (
          <FileExplorerContent
            projects={projects}
            folders={folders}
            selectedProject={selectedProject}
            isLoading={isLoading}
            onProjectSelect={onProjectSelect}
            onFileSelect={(file) => {
              onFileSelect(file);
              handleFileSelect();
            }}
            onNewFile={onNewFile}
          />
        );
      case TABS.ADD:
      case TABS.EDIT:
        return (
          <ApiForm initialData={selectedApi} onSaved={handleFormComplete} />
        );
      // case TABS.VARIABLES:
      //   return <GlobalVariablesManager />;
      case TABS.APK:
        return <ApkList />;
      default:
        return null;
    }
  };

  const getCurrentTabConfig = () => {
    if (!activeTab) return null;
    if (activeTab === TABS.ADD)
      return { icon: <Settings className="h-5 w-5" />, title: "Create API" };
    if (activeTab === TABS.EDIT)
      return { icon: <Settings className="h-5 w-5" />, title: "Edit API" };
    return tabs.find((tab) => tab.id === activeTab);
  };

  const currentTab = getCurrentTabConfig();

  return (
    <div
      ref={sidebarRef}
      id="sidebar"
      className={`fixed left-0 h-[calc(100vh-35px)] transition-all duration-300 z-10 flex top-[35px]`}
      style={{
        width: isExpanded ? "600px" : "56px"
      }}
    >
      {/* Navigation */}
      <div className="flex flex-col h-full w-14 bg-background border-r">
        <div className="flex flex-col gap-2 p-2 mt-4">
          {tabs.map((tab) => (
            <TooltipProvider key={tab.id}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant={activeTab === tab.id ? "default" : "ghost"}
                    size="icon"
                    onClick={() => handleTabClick(tab.id)}
                    // disabled={tab.id === TABS.LIST && isLoading}
                  >
                    {tab.activeIcon || tab.icon}
                  </Button>
                </TooltipTrigger>
                {!isExpanded && (
                  <TooltipContent side="right">
                    <p>{tab.tooltip}</p>
                  </TooltipContent>
                )}
              </Tooltip>
            </TooltipProvider>
          ))}
        </div>

        {/* <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost"
                size="icon"
                className="mt-auto mb-4 mx-auto"
                onClick={() => {
                  const newExpanded = !isExpanded;
                  setIsExpanded(newExpanded);
                  dispatch(setSidebarExpanded(newExpanded));
                  if (!newExpanded) {
                    setActiveTabState(null);
                    setSelectedApi(null);
                    dispatch(setActiveTab(null));
                  }
                }}
                disabled={isLoading}
              >
                {isExpanded ? (
                  <ChevronLeft className="h-5 w-5" />
                ) : (
                  <ChevronRight className="h-5 w-5" />
                )}
              </Button>
            </TooltipTrigger>
            {!isExpanded && (
              <TooltipContent side="right">
                <p>Expand sidebar</p>
              </TooltipContent>
            )}
          </Tooltip>
        </TooltipProvider> */}
      </div>

      <div
        className={`h-full overflow-hidden transition-all duration-300 ${
          isExpanded ? "w-[calc(100%-56px)]" : "w-0"
        }`}
      >
        <div className="w-full h-full bg-background/95 backdrop-blur-sm">
          <ScrollArea className="h-full">
            <div className="py-6 px-8">
              {currentTab && (
                <>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <h2 className="text-foreground font-medium text-lg">
                        {currentTab.title}
                      </h2>
                    </div>
                    {/* Add New File button when Files tab is active */}
                    {activeTab === TABS.FILES && (
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={onNewFile}
                        className="flex items-center gap-2"
                      >
                        <FileText className="h-4 w-4" />
                        <span>New File</span>
                      </Button>
                    )}
                  </div>
                  <Separator className="my-4" />
                </>
              )}
              <div className="px-1">{renderTabContent()}</div>
            </div>
          </ScrollArea>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
