import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  FileText,
  PlayCircle,
  Menu,
  Bell,
  LogOut,
  Layout,
  Calendar,
  Book,
  HelpCircle,
  LayoutDashboard,
  User,
  Settings,
  Building2,
  Terminal,
  Mail,
  LucideIcon,
  Sun,
  Moon
} from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
  DropdownMenuLabel,
  DropdownMenuGroup
} from "@/components/ui/dropdown-menu";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils";
import { Link } from "react-router-dom";

interface NavItemProps {
  icon: LucideIcon;
  label: string;
  href: string;
  isCollapsed: boolean;
  isActive?: boolean;
}

interface AppLayoutProps {
  children: React.ReactNode;
}

const NavItem = ({
  icon: Icon,
  label,
  href,
  isCollapsed,
  isActive = false
}: NavItemProps) => (
  <Link
    to={href}
    className={cn(
      "flex items-center gap-3 px-4 py-2.5",
      "transition-all rounded-lg mx-2",
      "group relative",
      isActive
        ? "bg-accent text-accent-foreground"
        : "text-muted-foreground hover:bg-accent hover:text-accent-foreground"
    )}
  >
    <Icon
      className={cn(
        "h-5 w-5 transition-colors",
        isActive
          ? "text-foreground"
          : "text-muted-foreground group-hover:text-foreground"
      )}
    />
    {!isCollapsed && (
      <span className="font-medium text-sm group-hover:translate-x-0.5 transition-transform">
        {label}
      </span>
    )}
  </Link>
);

const ThemeToggle = () => {
  const [theme, setTheme] = useState(() => {
    if (typeof window !== "undefined") {
      return document.documentElement.classList.contains("dark")
        ? "dark"
        : "light";
    }
    return "light";
  });

  useEffect(() => {
    const root = document.documentElement;
    if (theme === "dark") {
      root.classList.add("dark");
    } else {
      root.classList.remove("dark");
    }
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prev) => (prev === "dark" ? "light" : "dark"));
  };

  return (
    <Button
      variant="ghost"
      size="icon"
      onClick={toggleTheme}
      className="hover:bg-accent"
    >
      {theme === "dark" ? (
        <Sun className="h-5 w-5 text-foreground" />
      ) : (
        <Moon className="h-5 w-5 text-foreground" />
      )}
    </Button>
  );
};

const HeaderProfile = () => {
  const { logout, user } = useAuth0();

  return (
    <div className="flex items-center gap-4">
      <ThemeToggle />

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-8 w-8 rounded-full">
            <Avatar className="h-8 w-8">
              <AvatarImage src={user?.picture} alt={user?.name} />
              <AvatarFallback className="bg-muted text-muted-foreground">
                {user?.name?.slice(0, 2).toUpperCase()}
              </AvatarFallback>
            </Avatar>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-56 mt-1">
          <DropdownMenuLabel className="font-normal">
            <div className="flex flex-col space-y-1">
              <p className="text-sm font-medium text-foreground">
                {user?.name}
              </p>
              <p className="text-xs text-muted-foreground">{user?.email}</p>
            </div>
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuItem>
              <User className="h-4 w-4 mr-2" />
              Profile
            </DropdownMenuItem>
            <DropdownMenuItem>
              <Settings className="h-4 w-4 mr-2" />
              Settings
            </DropdownMenuItem>
            <DropdownMenuItem>
              <Building2 className="h-4 w-4 mr-2" />
              Organization
            </DropdownMenuItem>
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            className="text-destructive"
            onClick={() =>
              logout({ logoutParams: { returnTo: window.location.origin } })
            }
          >
            <LogOut className="h-4 w-4 mr-2" />
            Log out
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
  const location = useLocation();
  const { user } = useAuth0();

  const navItems = [
    { icon: LayoutDashboard, label: "Playground", href: "/start" },
    { icon: FileText, label: "Projects", href: "/project" },
    { icon: PlayCircle, label: "Test Plan", href: "/testplan" }
  ];

  const isNavItemActive = (href: string) => location.pathname.startsWith(href);

  return (
    <div className="min-h-screen bg-background">
      {/* Sidebar */}
      <aside
        className={cn(
          "fixed left-0 top-0 bottom-0 z-30 flex flex-col",
          "border-r border-border",
          "bg-background/80 backdrop-blur-xl",
          "transition-all duration-300 ease-in-out",
          isSidebarCollapsed ? "w-16" : "w-64"
        )}
      >
        {/* Logo Area */}
        <div className="h-14 flex items-center px-4 border-b border-border">
          {!isSidebarCollapsed && (
            <div className="flex items-center gap-2">
              <Terminal className="h-6 w-6 text-foreground" />
              <div className="flex flex-col">
                <span className="font-bold text-lg text-foreground">Drizz</span>
                <span className="text-xs text-muted-foreground -mt-1">
                  Playground
                </span>
              </div>
            </div>
          )}
          <Button
            variant="ghost"
            size="icon"
            className={cn(
              "hover:bg-accent text-muted-foreground",
              isSidebarCollapsed ? "mx-auto" : "ml-auto"
            )}
            onClick={() => setSidebarCollapsed(!isSidebarCollapsed)}
          >
            <Menu className="h-4 w-4" />
          </Button>
        </div>

        {/* Navigation */}
        <nav className="flex-1 overflow-y-auto py-4 space-y-1">
          {navItems.map((item) => (
            <NavItem
              key={item.label}
              icon={item.icon}
              label={item.label}
              href={item.href}
              isCollapsed={isSidebarCollapsed}
              isActive={isNavItemActive(item.href)}
            />
          ))}
        </nav>
      </aside>

      {/* Main Content Area */}
      <div
        className={cn(
          "transition-all duration-300",
          isSidebarCollapsed ? "ml-16" : "ml-64"
        )}
      >
        {/* Header */}
        <header className="h-14 border-b border-border bg-background/80 backdrop-blur-xl fixed top-0 right-0 left-0 z-20">
          <div
            className={cn(
              "h-full flex items-center justify-between px-4",
              isSidebarCollapsed ? "ml-16" : "ml-64"
            )}
          >
            <div className="flex items-center gap-2">
              <span className="text-sm font-medium text-muted-foreground">
                Welcome back, {user?.name?.split(" ")[0] || "User"}
              </span>
            </div>
            <HeaderProfile />
          </div>
        </header>

        {/* Main Content */}
        <main className="pt-14 p-6">
          <div className="max-w-7xl mx-auto">{children}</div>
        </main>
      </div>
    </div>
  );
};

export default AppLayout;

// { icon: PlayCircle, label: "Test Plan", href: "/testplan" },
// { icon: Calendar, label: "Scheduler", href: "/scheduler" },
// { icon: FileText, label: "Execution & Reports", href: "/executions" },
// { icon: Book, label: "Documentation", href: "/documentation" },
// { icon: HelpCircle, label: "Help & Support", href: "/support" }
