import { useState, useEffect } from "react";
import { useAuthenticatedApi } from "@/api/api";

export const useProjects = () => {
  const api = useAuthenticatedApi();

  const listProjects = async (filters) => {
    const queryParams = new URLSearchParams();

    if (filters) {
      Object.entries(filters).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
          queryParams.append(key, value.toString());
        }
      });
    }

    const queryString = queryParams.toString();
    const endpoint = queryString ? `/projects?${queryString}` : "/projects";

    return api.get(endpoint);
  };

  const getProject = async (projectId: number) => {
    return api.get(`/projects/${projectId}`);
  };

  const createProject = async (projectData) => {
    return api.post("/projects", projectData);
  };

  const updateProject = async (projectId, projectData) => {
    return api.patch(`/projects/${projectId}`, projectData);
  };

  const archiveProject = async (projectId: number) => {
    return api.put(`/projects/${projectId}/archive`, {});
  };

  const deleteProject = async (projectId: number) => {
    return api.delete(`/projects/${projectId}`);
  };

  return {
    listProjects,
    getProject,
    createProject,
    updateProject,
    archiveProject,
    deleteProject
  };
};

export const useCreateProject = () => {
  const [loading, setLoading] = useState(false);
  const api = useAuthenticatedApi();

  const createProject = async (projectData) => {
    try {
      setLoading(true);
      const response = await api.post("/projects", projectData);
      return response;
    } catch (error) {
      console.error("Failed to create project:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { createProject, loading };
};
