/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
  DialogFooter
} from "@/components/ui/dialog";
import { Textarea } from "@/components/ui/textarea";
import {
  ChevronRight,
  ChevronDown,
  Folder,
  FileText,
  Plus,
  Trash2,
  RefreshCw
} from "lucide-react";
import { cn } from "@/lib/utils";
import { useFolder } from "../../hooks/useFolder";
import { useTestFile } from "../../hooks/useTestFile";
import { useAuthenticatedApi } from "@/api/api";
import { logger } from "@/utils/logger";
// FolderItem Component
const FolderItem = ({
  item,
  level = 0,
  onCreateFolder,
  onCreateTest,
  onDeleteFolder,
  onDeleteTest,
  onTestClick,
  isTestFile = false
}) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const hasChildren = !isTestFile && item.children?.length > 0;

  return (
    <div className="w-full">
      <div
        className={cn(
          "group flex items-center gap-2 p-2.5 rounded-lg cursor-pointer",
          "bg-accent/10 dark:bg-accent/5"
        )}
        style={{ paddingLeft: `${level * 16 + 12}px` }}
        onClick={() => {
          if (isTestFile) {
            onTestClick(item.id);
          } else {
            setIsExpanded(!isExpanded);
          }
        }}
      >
        <div className="flex items-center gap-2.5 flex-1">
          {hasChildren ? (
            isExpanded ? (
              <ChevronDown className="h-4 w-4 text-muted-foreground" />
            ) : (
              <ChevronRight className="h-4 w-4 text-muted-foreground" />
            )
          ) : (
            <div className="w-4" /> // Spacer for alignment
          )}

          {isTestFile ? (
            <FileText className="h-4 w-4 text-violet-500 dark:text-violet-400" />
          ) : (
            <Folder className="h-4 w-4 text-indigo-500 dark:text-indigo-400" />
          )}

          <span className="flex-1 font-medium text-foreground/80">
            {item.name}
          </span>
        </div>

        <div className="flex items-center gap-1.5">
          {!isTestFile && (
            <>
              <Button
                variant="ghost"
                size="icon"
                className="h-8 w-8 text-muted-foreground"
                onClick={(e) => {
                  e.stopPropagation();
                  onCreateFolder(item.id);
                }}
              >
                <Plus className="h-4 w-4" />
              </Button>
              <Button
                variant="ghost"
                size="icon"
                className="h-8 w-8 text-muted-foreground"
                onClick={(e) => {
                  e.stopPropagation();
                  onCreateTest(item);
                }}
              >
                <FileText className="h-4 w-4" />
              </Button>
            </>
          )}
          <Button
            variant="ghost"
            size="icon"
            className="h-8 w-8 text-destructive"
            onClick={(e) => {
              e.stopPropagation();
              isTestFile ? onDeleteTest(item.id) : onDeleteFolder(item);
            }}
          >
            <Trash2 className="h-4 w-4" />
          </Button>
        </div>
      </div>

      {!isTestFile && isExpanded && (
        <div className="w-full pt-1">
          {/* Test files */}
          {item.children
            ?.filter((child) => !child.children)
            ?.map((testFile) => (
              <FolderItem
                key={testFile.id}
                item={testFile}
                level={level + 1}
                onCreateFolder={onCreateFolder}
                onCreateTest={onCreateTest}
                onDeleteFolder={onDeleteFolder}
                onDeleteTest={onDeleteTest}
                onTestClick={onTestClick}
                isTestFile={true}
              />
            ))}

          {/* Nested folders */}
          {item.children
            ?.filter((child) => child.children)
            ?.map((folder) => (
              <FolderItem
                key={folder.id}
                item={folder}
                level={level + 1}
                onCreateFolder={onCreateFolder}
                onCreateTest={onCreateTest}
                onDeleteFolder={onDeleteFolder}
                onDeleteTest={onDeleteTest}
                onTestClick={onTestClick}
              />
            ))}
        </div>
      )}
    </div>
  );
};

// Main TestFileExplorer Component
const TestFileExplorer = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { getFolders, createFolder, deleteFolder } = useFolder();
  const { createTestFile, deleteTestFile } = useTestFile();

  const [folders, setFolders] = useState(null);
  const [loading, setLoading] = useState(true);
  const [createFolderOpen, setCreateFolderOpen] = useState(false);
  const [createTestOpen, setCreateTestOpen] = useState(false);
  const [selectedFolderId, setSelectedFolderId] = useState(null);
  const [newFolderParentId, setNewFolderParentId] = useState(null);
  const [syncLoading, setSyncLoading] = useState(false);

  const api = useAuthenticatedApi();

  const handleGitHubSync = async () => {
    try {
      setSyncLoading(true);
      await api.post(`/github/sync?project_id=${projectId}`, {});

      // Refresh folders after sync
      await fetchFolders();
    } catch (error) {
      logger.error("Failed to sync with GitHub:", error);

      // Handle specific error messages from the backend
      const errorMessage =
        error.response?.data?.detail || "Failed to sync with GitHub";

      // Show more user-friendly messages for specific errors
      let userMessage = errorMessage;
      if (errorMessage.includes("not configured with GitHub")) {
        userMessage =
          "This project is not connected to GitHub. Please configure GitHub integration first.";
      } else if (errorMessage.includes("integration not found")) {
        userMessage =
          "GitHub integration not found. Please connect your GitHub account first.";
      }
    } finally {
      setSyncLoading(false);
    }
  };

  useEffect(() => {
    if (projectId) {
      fetchFolders();
    }
  }, [projectId]);

  const fetchFolders = async () => {
    try {
      setLoading(true);
      const data = await getFolders(projectId);
      setFolders(data);
    } catch (err) {
      logger.error("Error fetching folders:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateFolder = (parentId) => {
    console.log("parentId", parentId);
    setNewFolderParentId(parentId);
    setCreateFolderOpen(true);
  };

  const handleCreateTest = (folder) => {
    setSelectedFolderId(folder.id);
    setCreateTestOpen(true);
  };

  const handleDeleteFolder = async (folder) => {
    if (
      window.confirm(
        "Are you sure you want to delete this folder and all its contents?"
      )
    ) {
      try {
        await deleteFolder(folder.id);
        await fetchFolders();
      } catch (err) {
        logger.error("Error deleting folder:", err);
      }
    }
  };

  const handleDeleteTest = async (testId) => {
    if (window.confirm("Are you sure you want to delete this test case?")) {
      try {
        await deleteTestFile(testId);
        await fetchFolders();
      } catch (err) {
        logger.error("Error deleting test:", err);
      }
    }
  };

  // In TestFileExplorer.tsx
  const handleTestClick = (testId) => {
    navigate(`/start?testcase_id=${testId}&project_id=${projectId}`);
  };

  // Create Folder Dialog
  const CreateFolderDialog = () => {
    const [name, setName] = useState("");
    const [submitting, setSubmitting] = useState(false);

    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        setSubmitting(true);
        await createFolder({
          name,
          parent_id: newFolderParentId,
          project_id: projectId
        });
        await fetchFolders();
        setCreateFolderOpen(false);
        setName("");
      } catch (err) {
        logger.error("Error creating folder:", err);
      } finally {
        setSubmitting(false);
      }
    };

    return (
      <DialogContent className="sm:max-w-[425px]">
        <form onSubmit={handleSubmit}>
          <DialogHeader>
            <DialogTitle>
              {newFolderParentId ? "Create Subfolder" : "Create Root Folder"}
            </DialogTitle>
          </DialogHeader>
          <div className="space-y-4 py-4">
            <div className="space-y-2">
              <Label htmlFor="name">
                Folder Name <span className="text-destructive">*</span>
              </Label>
              <Input
                id="name"
                placeholder="Enter folder name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                autoFocus
              />
            </div>
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button type="button" variant="secondary">
                Cancel
              </Button>
            </DialogClose>
            <Button type="submit" disabled={submitting}>
              {submitting ? "Creating..." : "Create Folder"}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    );
  };

  // Create Test Dialog
  const CreateTestDialog = () => {
    const [formData, setFormData] = useState({
      name: "",
      test_metadata: {
        description: ""
      },
      content: ""
    });
    const [submitting, setSubmitting] = useState(false);

    const handleChange = (e) => {
      const { id, value } = e.target;
      if (id === "description") {
        setFormData((prev) => ({
          ...prev,
          test_metadata: {
            ...prev.test_metadata,
            [id]: value
          }
        }));
      } else if (id === "test_steps") {
        setFormData((prev) => ({
          ...prev,
          content: value
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          [id]: value
        }));
      }
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        setSubmitting(true);
        const testFileData = {
          name: formData.name,
          folder_id: selectedFolderId,
          status: "NEW",
          test_metadata: formData.test_metadata,
          content: formData.content,
          version: 1
        };
        await createTestFile(testFileData);
        await fetchFolders();
        setCreateTestOpen(false);
        setFormData({
          name: "",
          test_metadata: { description: "" },
          content: ""
        });
      } catch (err) {
        logger.error("Error creating test case:", err);
      } finally {
        setSubmitting(false);
      }
    };

    return (
      <DialogContent className="sm:max-w-[800px]">
        <form onSubmit={handleSubmit}>
          <DialogHeader>
            <DialogTitle>Create Test Case</DialogTitle>
          </DialogHeader>
          <div className="space-y-6 py-4">
            <div className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="name">
                  Name <span className="text-destructive">*</span>
                </Label>
                <Input
                  id="name"
                  placeholder="Enter test case name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  autoFocus
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="description">
                  Description <span className="text-destructive">*</span>
                </Label>
                <Textarea
                  id="description"
                  placeholder="Describe the purpose and objectives of this test case"
                  className="resize-none"
                  rows={3}
                  value={formData.test_metadata.description}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="space-y-4">
              <div className="grid gap-4">
                <div className="space-y-2">
                  <Label htmlFor="test_steps">
                    Test Steps <span className="text-destructive">*</span>
                  </Label>
                  <Textarea
                    id="test_steps"
                    placeholder="List the steps to execute this test"
                    className="resize-none"
                    rows={4}
                    value={formData.content}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button type="button" variant="secondary">
                Cancel
              </Button>
            </DialogClose>
            <Button type="submit" disabled={submitting}>
              {submitting ? "Creating..." : "Create Test Case"}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    );
  };

  if (loading) {
    return (
      <div className="h-full w-full flex items-center justify-center">
        <div className="animate-spin h-8 w-8 border-4 border-primary border-t-transparent rounded-full" />
      </div>
    );
  }

  return (
    <div className="h-full bg-background p-6">
      <div className="w-full bg-background border rounded-lg p-6">
        <div className="flex items-center justify-between mb-6">
          <div className="space-y-1">
            <h2 className="text-xl font-semibold text-foreground">Files</h2>
            <p className="text-sm text-muted-foreground">
              Manage your project files and test cases
            </p>
          </div>
          <div className="flex items-center gap-2">
            <Button
              variant="default"
              size="sm"
              className="gap-2"
              onClick={handleGitHubSync}
              disabled={syncLoading}
            >
              <RefreshCw
                className={cn("h-4 w-4", {
                  "animate-spin": syncLoading
                })}
              />
              Sync with GitHub
            </Button>

            <Dialog open={createFolderOpen} onOpenChange={setCreateFolderOpen}>
              <DialogTrigger asChild></DialogTrigger>
              <CreateFolderDialog />
            </Dialog>
          </div>
        </div>

        <div className="space-y-1.5">
          {folders?.map((folder) => (
            <FolderItem
              key={folder.id}
              item={folder}
              onCreateFolder={handleCreateFolder}
              onCreateTest={handleCreateTest}
              onDeleteFolder={handleDeleteFolder}
              onDeleteTest={handleDeleteTest}
              onTestClick={handleTestClick}
            />
          ))}
        </div>

        {/* Create Test Dialog */}
        <Dialog open={createTestOpen} onOpenChange={setCreateTestOpen}>
          <CreateTestDialog />
        </Dialog>
      </div>
    </div>
  );
};

export default TestFileExplorer;
