import React, {
  useRef,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback
} from "react";
import * as monaco from "monaco-editor";
import { useAppSelector, useAppDispatch } from "@/store/hooks";
import { LineOperations } from "./LineOperations";
import {
  initializeMonaco,
  getEditorConfig,
  setupEditorShortcuts,
  updateEditorOptions
} from "@/utils/monaco-utils";
import {
  selectConfig,
  selectSingleModeLoading,
  selectExecutedLines,
  clearExecutedLines,
  addExecutedLine
} from "@/store/features/editorSlice";
import "@/style/editor.css";
import "@/style/monaco-editor.css";
import { LineEditorProps, LineEditorRef } from "@/types";
import { SINGLE_TEST_URL } from "@/config";
import { useLogger } from "@/hooks/useLogger";

export const LineMonacoEditor = forwardRef<LineEditorRef, LineEditorProps>(
  (
    {
      value,
      onChange,
      height = "100%",
      language = "plaintext",
      theme = "vs-dark"
    },
    ref
  ) => {
    const dispatch = useAppDispatch();
    const singleModeLoading = useAppSelector(selectSingleModeLoading);
    const config = useAppSelector(selectConfig);
    const executedLines = useAppSelector(selectExecutedLines);

    const editorRef = useRef<HTMLDivElement>(null);
    const [editor, setEditor] =
      useState<monaco.editor.IStandaloneCodeEditor | null>(null);
    const [executingLine, setExecutingLine] = useState<number | null>(null);
    const [localExecutedLines, setLocalExecutedLines] = useState<Set<number>>(
      new Set()
    );
    const { addLog } = useLogger();
    const [selectedLine, setSelectedLine] = useState<number | null>(null);
    const [activeLine, setActiveLine] = useState<number>(0);
    const [tooltip, setTooltip] = useState<{
      text: string;
      x: number;
      y: number;
    } | null>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [lineStatuses, setLineStatuses] = useState<
      Record<
        number,
        {
          status: "idle" | "success" | "error";
          isProcessing: boolean;
        }
      >
    >({});

    // Expose editor methods
    useImperativeHandle(ref, () => ({
      getValue: () => editor?.getValue() ?? "",
      setValue: (value: string) => editor?.setValue(value),
      editor: editor!,
      clearExecutedLines: () => {
        setLocalExecutedLines(new Set());
        if (editor) {
          editor.deltaDecorations([], []);
        }
      }
    }));

    // Initialize editor
    useEffect(() => {
      let mounted = true;

      const init = async () => {
        await initializeMonaco();

        if (!mounted || !editorRef.current || editor) return;

        const editorInstance = monaco.editor.create(editorRef.current, {
          ...getEditorConfig(value, language, theme, false, true),
          glyphMargin: false,
          lineDecorationsWidth: undefined,
          folding: false,
          readOnly: singleModeLoading
        });

        editorInstance.onDidChangeModelContent(() => {
          onChange(editorInstance.getValue());
        });

        setupEditorShortcuts(editorInstance);
        setEditor(editorInstance);
      };

      init();

      return () => {
        mounted = false;
        if (editor) {
          editor.dispose();
        }
      };
    }, []);

    // Sync executed lines with Redux state
    useEffect(() => {
      setLocalExecutedLines(new Set(executedLines));
    }, [executedLines]);

    // Update editor options when running state changes
    useEffect(() => {
      if (editor) {
        updateEditorOptions(editor, singleModeLoading);
      }
    }, [singleModeLoading, editor]);

    // Update editor value
    useEffect(() => {
      if (editor && value !== editor.getValue()) {
        editor.setValue(value);
      }
    }, [value]);

    // Track active line
    useEffect(() => {
      if (!editor) return;

      const updateActiveLine = () => {
        const position = editor.getPosition();
        if (position) {
          setActiveLine(position.lineNumber);
        }
      };

      const disposables = [
        editor.onDidChangeCursorPosition(() => {
          updateActiveLine();
        })
      ];

      updateActiveLine();

      return () => disposables.forEach((d) => d.dispose());
    }, [editor]);

    const handleLineSelect = useCallback(
      (e: monaco.editor.IEditorMouseEvent) => {
        if (e.target.position) {
          setSelectedLine(e.target.position.lineNumber);
        }
      },
      []
    );

    const handleLineReorder = useCallback(
      async (fromIndex: number, toIndex: number) => {
        if (!editor) return;

        const content = editor.getValue();
        const lines = content.split("\n");
        const [movedLine] = lines.splice(fromIndex, 1);
        lines.splice(toIndex, 0, movedLine);
        const newContent = lines.join("\n");

        // Update executed lines after reordering
        const updatedExecutedLines = new Set<number>();
        executedLines.forEach((line) => {
          if (line === fromIndex + 1) {
            updatedExecutedLines.add(toIndex + 1);
          } else if (line > fromIndex + 1 && line <= toIndex + 1) {
            updatedExecutedLines.add(line - 1);
          } else if (line < fromIndex + 1 && line >= toIndex + 1) {
            updatedExecutedLines.add(line + 1);
          } else {
            updatedExecutedLines.add(line);
          }
        });

        dispatch(clearExecutedLines());
        updatedExecutedLines.forEach((lineNumber) => {
          dispatch(addExecutedLine(lineNumber));
        });

        // Update editor content
        editor.setValue(newContent);
        onChange(newContent);

        // Update line statuses after reordering
        const updatedStatuses: typeof lineStatuses = {};
        Object.entries(lineStatuses).forEach(([line, status]) => {
          const lineNum = parseInt(line);
          if (lineNum === fromIndex + 1) {
            updatedStatuses[toIndex + 1] = status;
          } else if (lineNum > fromIndex + 1 && lineNum <= toIndex + 1) {
            updatedStatuses[lineNum - 1] = status;
          } else if (lineNum < fromIndex + 1 && lineNum >= toIndex + 1) {
            updatedStatuses[lineNum + 1] = status;
          } else {
            updatedStatuses[lineNum] = status;
          }
        });
        setLineStatuses(updatedStatuses);
      },
      [editor, executedLines, lineStatuses, onChange, dispatch]
    );

    const handleMoveUp = useCallback(
      (lineNumber: number) => {
        if (lineNumber <= 1) return;
        handleLineReorder(lineNumber - 1, lineNumber - 2);
      },
      [handleLineReorder]
    );

    const handleMoveDown = useCallback(
      (lineNumber: number) => {
        if (!editor) return;
        const totalLines = editor.getModel()?.getLineCount() || 0;
        if (lineNumber < totalLines) {
          handleLineReorder(lineNumber - 1, lineNumber);
        }
      },
      [editor, handleLineReorder]
    );

    const handleClearLine = useCallback(
      (lineNumber: number) => {
        if (!editor) return;
        const model = editor.getModel();
        if (model) {
          editor.executeEdits("", [
            {
              range: new monaco.Range(
                lineNumber,
                1,
                lineNumber,
                model.getLineMaxColumn(lineNumber)
              ),
              text: ""
            }
          ]);
        }
      },
      [editor]
    );

    const handleLineExecution = async (lineNumber: number) => {
      if (!editor) return;
      const content = editor.getModel()?.getLineContent(lineNumber)?.trim();
      if (!content) return;

      setExecutingLine(lineNumber);
      setLineStatuses((prev) => ({
        ...prev,
        [lineNumber]: { status: "idle", isProcessing: false }
      }));

      try {
        const response = await fetch(
          `${SINGLE_TEST_URL}/submit_instruction/${config.instanceName}_client123`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({ instruction: content })
          }
        );

        const data = await response.json();

        if (data.status === "success") {
          addLog(`✓ Line ${lineNumber}: Instruction submitted successfully`);
          addLog(`Instruction: ${data.instruction}`, "info");
          setLineStatuses((prev) => ({
            ...prev,
            [lineNumber]: {
              status: "success",
              isProcessing: data.instruction_queue?.includes(content) ?? false
            }
          }));
          dispatch(addExecutedLine(lineNumber));
        } else {
          setLineStatuses((prev) => ({
            ...prev,
            [lineNumber]: { status: "error", isProcessing: false }
          }));
        }
      } catch (error) {
        console.error("Error executing line:", error);
        setLineStatuses((prev) => ({
          ...prev,
          [lineNumber]: { status: "error", isProcessing: false }
        }));
      } finally {
        setExecutingLine(null);
      }
    };

    // Update editor decorations
    useEffect(() => {
      if (!editor) return;

      const model = editor.getModel();
      if (!model) return;

      const decorations = [];
      const lineCount = model.getLineCount();

      for (let i = 1; i <= lineCount; i++) {
        const lineContent = model.getLineContent(i).trim();
        if (lineContent) {
          const isExecuting = executingLine === i;
          const isExecuted = localExecutedLines.has(i);
          const isSelected = selectedLine === i;

          decorations.push({
            range: new monaco.Range(i, 1, i, 1),
            options: {
              isWholeLine: true,
              linesDecorationsClassName: `line-execution-margin ${
                isExecuting ? "executing" : ""
              } ${isExecuted ? "executed" : ""} ${
                isSelected ? "selected" : ""
              }`,
              className: `line-background ${isSelected ? "selected-line" : ""}`,
              glyphMarginClassName: "line-execution-glyph",
              stickiness:
                monaco.editor.TrackedRangeStickiness.NeverGrowsWhenTypingAtEdges
            }
          });
        }
      }

      editor.deltaDecorations([], decorations);
    }, [editor, value, executingLine, localExecutedLines, selectedLine]);

    // Handle line selection and cursor movement
    useEffect(() => {
      if (!editor) return;

      const disposables = [
        editor.onDidChangeCursorPosition((e) => {
          setSelectedLine(e.position.lineNumber);
        }),

        editor.onMouseMove(handleLineSelect),

        editor.onDidBlurEditorText(() => {
          // Optionally clear selection when editor loses focus
          // setSelectedLine(null);
        })
      ];

      return () => {
        disposables.forEach((d) => d.dispose());
      };
    }, [editor, handleLineSelect]);

    // Handle tooltip
    useEffect(() => {
      if (!editor) return;

      const disposable = editor.onMouseMove((e) => {
        if (
          e.target.type === monaco.editor.MouseTargetType.GUTTER_GLYPH_MARGIN
        ) {
          const { lineNumber } = e.target.position || {};
          if (lineNumber) {
            const lineContent = editor
              .getModel()
              ?.getLineContent(lineNumber)
              ?.trim();
            if (lineContent) {
              setTooltip({
                text: localExecutedLines.has(lineNumber)
                  ? lineStatuses[lineNumber]?.isProcessing
                    ? "Processing..."
                    : lineStatuses[lineNumber]?.status === "error"
                    ? "Execution failed"
                    : "Line executed"
                  : `Execute: ${lineContent}`,
                x: e.event.posx + 10,
                y: e.event.posy + 10
              });
            }
          }
        } else {
          setTooltip(null);
        }
      });

      return () => disposable.dispose();
    }, [editor, localExecutedLines, lineStatuses]);

    // Handle keyboard shortcuts
    useEffect(() => {
      if (!editor) return;

      const handleKeyboard = (e: KeyboardEvent) => {
        if (!selectedLine) return;

        // Execute current line with Shift + Enter
        if (e.key === "Enter" && e.shiftKey) {
          e.preventDefault();
          handleLineExecution(selectedLine);
        }

        // Move line up with Alt + ArrowUp
        if (e.key === "ArrowUp" && e.altKey) {
          e.preventDefault();
          handleMoveUp(selectedLine);
        }

        // Move line down with Alt + ArrowDown
        if (e.key === "ArrowDown" && e.altKey) {
          e.preventDefault();
          handleMoveDown(selectedLine);
        }
      };

      editor.getDomNode()?.addEventListener("keydown", handleKeyboard);

      return () => {
        editor.getDomNode()?.removeEventListener("keydown", handleKeyboard);
      };
    }, [
      editor,
      selectedLine,
      handleMoveUp,
      handleMoveDown,
      handleLineExecution
    ]);

    return (
      <>
        <div
          ref={editorRef}
          className={`monaco-editor-container single-line-mode ${
            isDragging ? "dragging" : ""
          } ${theme}`}
          style={{
            height,
            width: "100%",
            overflow: "hidden",
            border: "1px solid #ccc",
            borderRadius: "4px"
          }}
        />
        {selectedLine !== null && (
          <LineOperations
            lineNumber={activeLine}
            onExecute={handleLineExecution}
            onMoveUp={handleMoveUp}
            onMoveDown={handleMoveDown}
            onClear={handleClearLine}
            isExecuted={localExecutedLines.has(activeLine)}
            editor={editor}
            status={lineStatuses[activeLine]?.status ?? "idle"}
            isProcessing={lineStatuses[activeLine]?.isProcessing ?? false}
          />
        )}
        {tooltip && (
          <div
            className="execution-tooltip"
            style={{
              left: tooltip.x,
              top: tooltip.y
            }}
          >
            {tooltip.text}
          </div>
        )}
      </>
    );
  }
);

LineMonacoEditor.displayName = "LineMonacoEditor";

//     return (
//       <div style={{ position: "relative" }}>
//         <div
//           ref={editorRef}
//           className={`monaco-editor-container single-line-mode ${
//             isDragging ? "dragging" : ""
//           } ${theme}`}
//           style={{
//             height,
//             width: "calc(100% - 150px)", // Adjust width to account for sidebar
//             overflow: "hidden",
//             border: "1px solid #ccc",
//             borderRadius: "4px"
//           }}
//         />
//         {editor && (
//           <LineOperationsBar
//             editor={editor}
//             onExecute={handleLineExecution}
//             onMoveUp={handleMoveUp}
//             onMoveDown={handleMoveDown}
//             onClear={handleClearLine}
//             executedLines={localExecutedLines}
//           />
//         )}
//         {tooltip && (
//           <div
//             className="execution-tooltip"
//             style={{
//               left: tooltip.x,
//               top: tooltip.y
//             }}
//           >
//             {tooltip.text}
//           </div>
//         )}
//       </div>
//     );
//   }
// );

// LineMonacoEditor.displayName = "LineMonacoEditor";
