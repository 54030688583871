import React, { useState, useEffect } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter
} from "@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/components/ui/select";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Settings,
  Package,
  Smartphone,
  Save,
  Plus,
  X,
  FileText,
  Loader2,
  Layers
} from "lucide-react";
import { useAuthenticatedApi } from "@/api/api";
import { useAuth0 } from "@auth0/auth0-react";
import { API_BASE_URL } from "@/config";
import { useProjects } from "../../hooks/useProject";
import { useFolder } from "../../hooks/useFolder";
import { useTestFile } from "../../hooks/useTestFile";
import { useNavigate } from "react-router-dom";

// Basic Information Component
const BasicInformation = ({ planName, setPlanName }) => (
  <Card>
    <CardHeader>
      <div className="flex items-center gap-2">
        <Settings className="h-5 w-5 text-primary" />
        <CardTitle>Basic Information</CardTitle>
      </div>
    </CardHeader>
    <CardContent className="space-y-4">
      <div className="grid grid-cols-2 gap-4">
        <div className="space-y-2">
          <Label>Plan Name</Label>
          <Input
            placeholder="Enter test plan name"
            value={planName}
            onChange={(e) => setPlanName(e.target.value)}
          />
        </div>
      </div>
    </CardContent>
  </Card>
);

// APK Selection Component
const ApkSelection = ({ selectedApks, setSelectedApks, onAddClick }) => (
  <Card>
    <CardHeader>
      <div className="flex items-center gap-2">
        <Package className="h-5 w-5 text-primary" />
        <CardTitle>APK Configuration</CardTitle>
      </div>
    </CardHeader>
    <CardContent>
      <div className="space-y-4">
        <div className="flex flex-wrap gap-2">
          {selectedApks.map((apk) => (
            <Badge
              key={apk.packageName}
              variant="secondary"
              className="flex items-center gap-2 p-2"
            >
              <span>
                {apk.name} ({apk.selectedVersion})
              </span>
              <X
                className="h-4 w-4 cursor-pointer hover:text-destructive"
                onClick={() =>
                  setSelectedApks(
                    selectedApks.filter(
                      (a) => a.packageName !== apk.packageName
                    )
                  )
                }
              />
            </Badge>
          ))}
        </div>
        <Button variant="outline" onClick={onAddClick}>
          <Plus className="h-4 w-4 mr-2" />
          Add APK
        </Button>
      </div>
    </CardContent>
  </Card>
);

// Device Mapping Component
const DeviceMapping = ({
  deviceMappings,
  setDeviceMappings,
  onAddClick,
  disabled
}) => (
  <Card>
    <CardHeader>
      <div className="flex items-center gap-2">
        <Smartphone className="h-5 w-5 text-primary" />
        <CardTitle>Device Mapping</CardTitle>
      </div>
    </CardHeader>
    <CardContent>
      <div className="space-y-4">
        {deviceMappings.map((mapping, index) => (
          <div key={index} className="p-4 border rounded-lg">
            <div className="flex items-center justify-between">
              <div>
                <h3 className="font-medium">{mapping.device.name}</h3>
                <div className="text-sm text-muted-foreground mt-1">
                  Installing:{" "}
                  {mapping.installApks.map((apk) => apk.name).join(", ")}
                </div>
              </div>
              <X
                className="h-4 w-4 cursor-pointer hover:text-destructive"
                onClick={() =>
                  setDeviceMappings(
                    deviceMappings.filter((_, i) => i !== index)
                  )
                }
              />
            </div>
          </div>
        ))}
        <Button variant="outline" onClick={onAddClick} disabled={disabled}>
          <Plus className="h-4 w-4 mr-2" />
          Add Device Mapping
        </Button>
      </div>
    </CardContent>
  </Card>
);

// Test Groups Component
const TestGroups = ({
  testGroups,
  setTestGroups,
  onAddClick,
  disabled,
  onEditGroup
}) => (
  <Card>
    <CardHeader>
      <div className="flex items-center gap-2">
        <Layers className="h-5 w-5 text-primary" />
        <CardTitle>Test Groups</CardTitle>
      </div>
    </CardHeader>
    <CardContent>
      <div className="space-y-4">
        {testGroups.map((group, index) => (
          <div key={index} className="p-4 border rounded-lg">
            <div className="flex items-center justify-between">
              <div>
                <h3 className="font-medium">{group.name}</h3>
                <div className="text-sm text-muted-foreground mt-1">
                  {group.testScripts.length} Test Scripts,{" "}
                  {group.devices.length} Devices
                </div>
              </div>
              <div className="flex items-center gap-2">
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => onEditGroup(group)}
                >
                  Edit
                </Button>
                <X
                  className="h-4 w-4 cursor-pointer hover:text-destructive"
                  onClick={() =>
                    setTestGroups(testGroups.filter((_, i) => i !== index))
                  }
                />
              </div>
            </div>
          </div>
        ))}
        <Button variant="outline" onClick={onAddClick} disabled={disabled}>
          <Plus className="h-4 w-4 mr-2" />
          Add Test Group
        </Button>
      </div>
    </CardContent>
  </Card>
);

// Dialog Components
const ApkDialog = ({
  open,
  onOpenChange,
  availableApks,
  isLoadingApks,
  selectedApkId,
  handleApkSelect,
  isLoadingVersions,
  currentApkVersions,
  handleAddApk
}) => (
  <Dialog open={open} onOpenChange={onOpenChange}>
    <DialogContent className="max-w-2xl">
      <DialogHeader>
        <DialogTitle>Select APK and Version</DialogTitle>
      </DialogHeader>
      <ScrollArea className="h-[500px]">
        <div className="grid gap-4 py-4 pr-6">
          {isLoadingApks ? (
            <div className="flex items-center justify-center p-4">
              <Loader2 className="h-6 w-6 animate-spin" />
              <span className="ml-2">Loading APKs...</span>
            </div>
          ) : (
            availableApks.map((apk) => (
              <div key={apk.id} className="p-4 border rounded-lg">
                <div className="flex items-center justify-between mb-2">
                  <div>
                    <h3 className="font-medium">{apk.name}</h3>
                    <div className="text-sm text-muted-foreground">
                      {apk.packageName}
                    </div>
                  </div>
                </div>
                <div className="mt-2">
                  <div className="flex flex-col gap-2">
                    <Label className="text-sm">Select Version</Label>
                    {selectedApkId !== apk.id && (
                      <Button
                        variant="outline"
                        onClick={() => handleApkSelect(apk.id)}
                        className="w-full justify-start"
                      >
                        Click to load versions
                      </Button>
                    )}
                    {selectedApkId === apk.id && isLoadingVersions && (
                      <div className="flex items-center mt-2">
                        <Loader2 className="h-4 w-4 animate-spin" />
                        <span className="ml-2 text-sm">
                          Loading versions...
                        </span>
                      </div>
                    )}
                    {selectedApkId === apk.id && !isLoadingVersions && (
                      <Select
                        onValueChange={(version) => handleAddApk(apk, version)}
                      >
                        <SelectTrigger className="mt-1">
                          <SelectValue placeholder="Choose version" />
                        </SelectTrigger>
                        <SelectContent>
                          {currentApkVersions.map((version) => (
                            <SelectItem
                              key={version.id}
                              value={version.displayVersion}
                            >
                              {version.displayVersion}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    )}
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </ScrollArea>
    </DialogContent>
  </Dialog>
);

const DeviceDialog = ({
  open,
  onOpenChange,
  selectedApks,
  selectedApksForDevice,
  setSelectedApksForDevice,
  onAddDevice,
  availableDevices,
  isLoadingDevices
}) => {
  const [selectedDevice, setSelectedDevice] = useState("");

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle>Configure Device Mapping</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="space-y-2">
            <Label>Select Device</Label>
            {isLoadingDevices ? (
              <div className="flex items-center gap-2">
                <Loader2 className="h-4 w-4 animate-spin" />
                <span>Loading devices...</span>
              </div>
            ) : (
              <Select onValueChange={setSelectedDevice} value={selectedDevice}>
                <SelectTrigger>
                  <SelectValue placeholder="Select a device" />
                </SelectTrigger>
                <SelectContent>
                  {availableDevices.map((device) => (
                    <SelectItem key={device.id} value={device.name}>
                      {device.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            )}
          </div>

          <div className="space-y-4">
            <Label className="text-sm">Select APKs to Install</Label>
            <div className="flex flex-wrap gap-2">
              {selectedApks.map((apk) => (
                <Badge
                  key={apk.packageName}
                  variant="outline"
                  className={`cursor-pointer ${
                    selectedApksForDevice.some(
                      (a) => a.packageName === apk.packageName
                    )
                      ? "bg-primary/10"
                      : "hover:bg-primary/5"
                  }`}
                  onClick={() => {
                    setSelectedApksForDevice((prev) => {
                      const isSelected = prev.some(
                        (a) => a.packageName === apk.packageName
                      );
                      if (isSelected) {
                        return prev.filter(
                          (a) => a.packageName !== apk.packageName
                        );
                      }
                      return [...prev, apk];
                    });
                  }}
                >
                  {apk.name} ({apk.selectedVersion})
                </Badge>
              ))}
            </div>
          </div>

          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => {
                onOpenChange(false);
                setSelectedDevice("");
                setSelectedApksForDevice([]);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (selectedDevice) {
                  onAddDevice({
                    device: {
                      id: selectedDevice,
                      name: selectedDevice
                    },
                    installApks: selectedApksForDevice
                  });
                  setSelectedDevice("");
                }
              }}
              disabled={!selectedDevice || selectedApksForDevice.length === 0}
            >
              Add Device
            </Button>
          </DialogFooter>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const TestGroupDialog = ({
  open,
  onOpenChange,
  groupName,
  setGroupName,
  selectedTests,
  setSelectedTests,
  selectedGroupDevices,
  setSelectedGroupDevices,
  isSequential,
  setIsSequential,
  deviceMappings,
  currentGroup,
  onAddGroup
}) => {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [folders, setFolders] = useState([]);
  const [testFiles, setTestFiles] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);

  const api = useAuthenticatedApi();
  const { listProjects } = useProjects();
  const { getFolders } = useFolder();
  const { getFolderTestFiles } = useTestFile();

  useEffect(() => {
    const loadProjects = async () => {
      if (open) {
        setIsLoadingData(true);
        try {
          const projectsData = await listProjects({});
          setProjects(projectsData || []);

          // Auto-select first project and load its data
          if (projectsData && projectsData.length > 0) {
            const firstProject = projectsData[0];
            setSelectedProject(firstProject);
            await loadProjectData(firstProject.id);
          }
        } catch (error) {
          console.error("Error loading projects:", error);
        } finally {
          setIsLoadingData(false);
        }
      }
    };

    loadProjects();
  }, [open]);

  const loadProjectData = async (projectId) => {
    setIsLoadingData(true);
    try {
      // Fetch folders for the project
      const foldersData = await getFolders(projectId);
      setFolders(foldersData || []);

      // Fetch test files for each folder
      const allTestFiles = [];
      for (const folder of foldersData) {
        const folderTestFiles = await getFolderTestFiles(folder.id);
        allTestFiles.push(...(folderTestFiles || []));
      }
      setTestFiles(allTestFiles);
    } catch (error) {
      console.error("Error loading project data:", error);
    } finally {
      setIsLoadingData(false);
    }
  };

  const handleProjectChange = async (projectId) => {
    const project = projects.find((p) => p.id === parseInt(projectId));
    setSelectedProject(project);
    await loadProjectData(projectId);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle>
            {currentGroup ? "Edit Test Group" : "Create Test Group"}
          </DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="space-y-2">
            <Label>Group Name</Label>
            <Input
              placeholder="Enter group name"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
            />
          </div>

          <div className="space-y-2">
            <Label>Select Project</Label>
            <Select
              onValueChange={handleProjectChange}
              value={selectedProject?.id?.toString()}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select a project" />
              </SelectTrigger>
              <SelectContent>
                {projects.map((project) => (
                  <SelectItem key={project.id} value={project.id.toString()}>
                    {project.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="space-y-2">
            <Label>Select Test Scripts</Label>
            <ScrollArea className="h-48 border rounded-lg p-2">
              {isLoadingData ? (
                <div className="flex items-center justify-center h-full">
                  <Loader2 className="h-4 w-4 animate-spin" />
                  <span className="ml-2">Loading test files...</span>
                </div>
              ) : (
                <div className="space-y-2">
                  {testFiles.map((file) => (
                    <div
                      key={file.id}
                      className={`flex items-center justify-between p-2 rounded-md cursor-pointer ${
                        selectedTests.find((s) => s.id === file.id)
                          ? "bg-primary/10"
                          : "hover:bg-secondary/50"
                      }`}
                      onClick={() => {
                        setSelectedTests((prev) => {
                          const isSelected = prev.find((s) => s.id === file.id);
                          if (isSelected) {
                            return prev.filter((s) => s.id !== file.id);
                          }
                          return [...prev, { id: file.id, name: file.name }];
                        });
                      }}
                    >
                      <div>
                        <div className="font-medium">{file.name}</div>
                        <div className="text-sm text-muted-foreground">
                          ID: {file.id}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </ScrollArea>
          </div>

          <div className="space-y-2">
            <Label>Select Devices</Label>
            <div className="space-y-2">
              {deviceMappings.map((mapping, index) => (
                <div
                  key={index}
                  className={`flex items-center justify-between p-2 rounded-md cursor-pointer ${
                    selectedGroupDevices.find(
                      (d) => d.device.name === mapping.device.name
                    )
                      ? "bg-primary/10"
                      : "hover:bg-secondary/50"
                  }`}
                  onClick={() => {
                    setSelectedGroupDevices((prev) => {
                      const isSelected = prev.find(
                        (d) => d.device.name === mapping.device.name
                      );
                      if (isSelected) {
                        return prev.filter(
                          (d) => d.device.name !== mapping.device.name
                        );
                      }
                      return [...prev, mapping];
                    });
                  }}
                >
                  <div>
                    <div className="font-medium">{mapping.device.name}</div>
                    <div className="text-sm text-muted-foreground">
                      Running:{" "}
                      {mapping.installApks.map((apk) => apk.name).join(", ")}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="space-y-2">
            <Label>Sequential Execution</Label>
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                className="rounded border-gray-400"
                checked={isSequential}
                onChange={(e) => setIsSequential(e.target.checked)}
              />
              <span className="text-sm text-muted-foreground">
                Run tests sequentially on the same device
              </span>
            </div>
          </div>
        </div>

        <DialogFooter>
          <Button
            variant="outline"
            onClick={() => {
              onOpenChange(false);
              setGroupName("");
              setSelectedTests([]);
              setSelectedGroupDevices([]);
              setIsSequential(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={onAddGroup}
            disabled={
              !groupName ||
              selectedTests.length === 0 ||
              selectedGroupDevices.length === 0
            }
          >
            {currentGroup ? "Update Group" : "Create Group"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

// Main TestPlanCreator Component
const TestPlanCreator = () => {
  // Basic Information State
  const [planName, setPlanName] = useState("");
  // APK Configuration State
  const [availableApks, setAvailableApks] = useState([]);
  const [selectedApks, setSelectedApks] = useState([]);
  const [showApkDialog, setShowApkDialog] = useState(false);
  const [isLoadingApks, setIsLoadingApks] = useState(false);
  const [isLoadingVersions, setIsLoadingVersions] = useState(false);
  const [currentApkVersions, setCurrentApkVersions] = useState([]);
  const [selectedApkId, setSelectedApkId] = useState(null);

  // Device Configuration State
  const [deviceMappings, setDeviceMappings] = useState([]);
  const [showDeviceDialog, setShowDeviceDialog] = useState(false);
  const [newDeviceName, setNewDeviceName] = useState("");
  const [selectedApksForDevice, setSelectedApksForDevice] = useState([]);
  const [availableDevices, setAvailableDevices] = useState([]);
  const [isLoadingDevices, setIsLoadingDevices] = useState(false);
  const { isAuthenticated, user } = useAuth0();

  // Test Group State
  const [testGroups, setTestGroups] = useState([]);
  const [showGroupDialog, setShowGroupDialog] = useState(false);
  const [currentGroup, setCurrentGroup] = useState(null);
  const [groupName, setGroupName] = useState("");
  const [selectedTests, setSelectedTests] = useState([]);
  const [selectedGroupDevices, setSelectedGroupDevices] = useState([]);
  const [isSequential, setIsSequential] = useState(false);
  const navigate = useNavigate();

  const api = useAuthenticatedApi();

  useEffect(() => {
    const fetchApks = async () => {
      setIsLoadingApks(true);
      try {
        const data = await api.get("/apks/organization-apks");
        setAvailableApks(
          data.map((apk) => ({
            name: apk.app_name,
            packageName: apk.package_name,
            id: apk.id
          }))
        );
      } catch (error) {
        console.error("Failed to fetch apps:", error);
      }
      setIsLoadingApks(false);
    };

    fetchApks();
  }, []);

  // Add this effect to fetch devices once
  useEffect(() => {
    const fetchDevices = async () => {
      if (isAuthenticated && user?.company_name) {
        setIsLoadingDevices(true);
        try {
          const response = await fetch(
            `${API_BASE_URL}/emulators?org=${user.company_name}`,
            {
              headers: {
                "ngrok-skip-browser-warning": "1"
              }
            }
          );
          const data = await response.json();
          const devices = data.related_recipes.map((device) => ({
            name: device.name,
            id: device.uuid
          }));
          setAvailableDevices(devices);
        } catch (error) {
          console.error("Failed to fetch devices:", error);
        }
        setIsLoadingDevices(false);
      }
    };

    fetchDevices();
  }, [isAuthenticated, user?.company_name]);

  const handleApkSelect = async (apkId) => {
    setSelectedApkId(apkId);
    setCurrentApkVersions([]);
    setIsLoadingVersions(true);

    try {
      const versions = await api.get(`/apk-versions/${apkId}/releases`);
      setCurrentApkVersions(versions);
    } catch (error) {
      console.error(`Failed to fetch versions for app ${apkId}:`, error);
    }
    setIsLoadingVersions(false);
  };

  const handleAddApk = (apk, version) => {
    setSelectedApks([
      ...selectedApks,
      {
        ...apk,
        selectedVersion: version
      }
    ]);
    setCurrentApkVersions([]);
    setSelectedApkId(null);
  };

  const handleAddDevice = (deviceConfig) => {
    setDeviceMappings([...deviceMappings, deviceConfig]);
    setShowDeviceDialog(false);
    setSelectedApksForDevice([]);
  };

  const handleEditGroup = (group) => {
    setCurrentGroup(group);
    setGroupName(group.name);
    setSelectedTests(group.testScripts);
    setSelectedGroupDevices(group.devices);
    setIsSequential(group.sequential);
    setShowGroupDialog(true);
  };

  const handleAddGroup = () => {
    const newGroup = {
      name: groupName,
      testScripts: selectedTests,
      devices: selectedGroupDevices,
      sequential: isSequential
    };

    if (currentGroup) {
      setTestGroups(
        testGroups.map((group) =>
          group.name === currentGroup.name ? newGroup : group
        )
      );
    } else {
      setTestGroups([...testGroups, newGroup]);
    }

    setShowGroupDialog(false);
    setCurrentGroup(null);
    setGroupName("");
    setSelectedTests([]);
    setSelectedGroupDevices([]);
    setIsSequential(false);
  };

  const handleSave = async () => {
    const testPlanData = {
      name: planName,

      dependencies: selectedApks.map((apk) => ({
        apk_name: apk.name,
        package_name: apk.packageName,
        version: apk.selectedVersion
      })),
      devices: deviceMappings.map((mapping) => ({
        device_name: mapping.device.name,
        install_apks: mapping.installApks.map((apk) => apk.name)
      })),
      test_groups: testGroups.map((group) => ({
        name: group.name,
        sequential: group.sequential,
        max_parallel_test_executions: 2,
        devices: group.devices.map((device) => device.device.name),
        test_scripts: group.testScripts.map((script) => ({
          test_file_id: parseInt(script.id),
          execution_order: 1
        }))
      }))
    };

    console.log("Saving Test Plan with data:", {
      testPlanData: JSON.stringify(testPlanData, null, 2)
    });

    try {
      await api.post("/testplans", testPlanData);
      console.log("Test Plan saved successfully");
      navigate("/testplan");
    } catch (err) {
      console.error("Failed to create test plan:", err);
      // You can add error notification here
    }
  };

  return (
    <div className="w-full max-w-6xl mx-auto p-6 space-y-6">
      {/* Header */}
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-2xl font-semibold">Create Test Plan</h1>
          <p className="text-muted-foreground mt-1">
            Configure your test execution plan
          </p>
        </div>
        <div className="flex gap-2">
          <Button variant="default" className="gap-2" onClick={handleSave}>
            <Save className="h-4 w-4" />
            Save Plan
          </Button>
        </div>
      </div>

      <BasicInformation planName={planName} setPlanName={setPlanName} />

      <ApkSelection
        selectedApks={selectedApks}
        setSelectedApks={setSelectedApks}
        onAddClick={() => setShowApkDialog(true)}
      />

      <DeviceMapping
        deviceMappings={deviceMappings}
        setDeviceMappings={setDeviceMappings}
        onAddClick={() => setShowDeviceDialog(true)}
        disabled={selectedApks.length === 0}
      />

      <TestGroups
        testGroups={testGroups}
        setTestGroups={setTestGroups}
        onAddClick={() => setShowGroupDialog(true)}
        disabled={deviceMappings.length === 0}
        onEditGroup={handleEditGroup}
      />

      <ApkDialog
        open={showApkDialog}
        onOpenChange={setShowApkDialog}
        availableApks={availableApks}
        isLoadingApks={isLoadingApks}
        selectedApkId={selectedApkId}
        handleApkSelect={handleApkSelect}
        isLoadingVersions={isLoadingVersions}
        currentApkVersions={currentApkVersions}
        handleAddApk={handleAddApk}
      />

      <DeviceDialog
        open={showDeviceDialog}
        onOpenChange={setShowDeviceDialog}
        selectedApks={selectedApks}
        selectedApksForDevice={selectedApksForDevice}
        setSelectedApksForDevice={setSelectedApksForDevice}
        onAddDevice={handleAddDevice}
        availableDevices={availableDevices}
        isLoadingDevices={isLoadingDevices}
      />

      <TestGroupDialog
        open={showGroupDialog}
        onOpenChange={setShowGroupDialog}
        groupName={groupName}
        setGroupName={setGroupName}
        selectedTests={selectedTests}
        setSelectedTests={setSelectedTests}
        selectedGroupDevices={selectedGroupDevices}
        setSelectedGroupDevices={setSelectedGroupDevices}
        isSequential={isSequential}
        setIsSequential={setIsSequential}
        deviceMappings={deviceMappings}
        currentGroup={currentGroup}
        onAddGroup={handleAddGroup}
      />
    </div>
  );
};

export default TestPlanCreator;
