import { useState, useCallback, useRef } from "react";
import * as Sentry from "@sentry/react";

interface LogEntry {
  id: number;
  timestamp: string;
  message: string;
  type: "info" | "error";
  isJson?: boolean; // Add this to identify JSON logs
}

export const useLogger = () => {
  const [logs, setLogs] = useState<LogEntry[]>([]);
  const logIdCounter = useRef(0);
  const logsContainerRef = useRef<HTMLDivElement>(null);

  // Function to check if a string is valid JSON
  const isJsonString = useCallback((str: string): boolean => {
    try {
      const result = JSON.parse(str);
      return typeof result === "object" && result !== null;
    } catch (e) {
      return false;
    }
  }, []);

  // Function to format JSON string
  const formatJsonString = useCallback((jsonString: string): string => {
    try {
      const parsed = JSON.parse(jsonString);
      return JSON.stringify(parsed, null, 2);
    } catch (e) {
      return jsonString;
    }
  }, []);

  const logError = useCallback((message: string) => {
    const logEntry = `[${new Date().toISOString()}] ERROR: ${message}\n`;
    console.error(logEntry);
    Sentry.captureException(new Error(message));

    addLog(message, "error");
  }, []);

  const addLog = useCallback(
    (message: string, type: "info" | "error" = "info") => {
      // Check if the message is JSON
      let isJsonLog = false;
      let formattedMessage = message;

      // Try to detect JSON in the message
      const jsonMatch = message.match(/(\{[\s\S]*\}|\[[\s\S]*\])/);
      if (jsonMatch) {
        const potentialJson = jsonMatch[0];
        if (isJsonString(potentialJson)) {
          isJsonLog = true;
          // Format only the JSON part of the message
          formattedMessage = message.replace(
            potentialJson,
            formatJsonString(potentialJson)
          );
        }
      }

      setLogs((prevLogs) => [
        ...prevLogs,
        {
          id: logIdCounter.current++,
          timestamp: new Date().toISOString(),
          message: formattedMessage,
          type,
          isJson: isJsonLog
        }
      ]);

      if (logsContainerRef.current) {
        setTimeout(() => {
          if (logsContainerRef.current) {
            logsContainerRef.current.scrollTop =
              logsContainerRef.current.scrollHeight;
          }
        }, 0);
      }
    },
    [isJsonString, formatJsonString]
  );

  const clearLogs = useCallback(() => {
    setLogs([]);
    logIdCounter.current = 0;
  }, []);

  const parseANSIColors = useCallback((text: string): string => {
    const processedText = text
      .replace(/(Instance)/g, '<span class="ansi-bright-cyan">$1</span>')
      .replace(/(GENYMOTION)/g, '<span class="ansi-bright-magenta">$1</span>')
      .replace(
        /(Upload successful)/g,
        '<span class="ansi-bright-green">$1</span>'
      );

    return processedText.replace(/\x1b\[(\d{1,2})m/g, (match, colorCode) => {
      const ANSI_COLORS: Record<number, string> = {
        30: "ansi-black",
        31: "ansi-red",
        32: "ansi-green",
        33: "ansi-yellow",
        34: "ansi-blue",
        35: "ansi-magenta",
        36: "ansi-cyan",
        37: "ansi-white",
        90: "ansi-bright-black",
        91: "ansi-bright-red",
        92: "ansi-bright-green",
        93: "ansi-bright-yellow",
        94: "ansi-bright-blue",
        95: "ansi-bright-magenta",
        96: "ansi-bright-cyan",
        97: "ansi-bright-white"
      };

      const cssClass = ANSI_COLORS[Number(colorCode)] || "";
      return cssClass ? `<span class="${cssClass}">` : "</span>";
    });
  }, []);

  // Function to combine JSON formatting with ANSI colors
  const formatLogMessage = useCallback(
    (log: LogEntry): string => {
      let formattedMessage = log.message;

      if (log.isJson) {
        // For JSON logs, we wrap them in a pre tag with specific styling
        formattedMessage = `<pre class="json-log">${formattedMessage}</pre>`;
      }

      return parseANSIColors(formattedMessage);
    },
    [parseANSIColors]
  );

  return {
    logs,
    logError,
    addLog,
    clearLogs,
    parseANSIColors,
    formatLogMessage,
    logsContainerRef
  };
};
