import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from "@/components/ui/alert-dialog";
import yaml from "js-yaml";
import { API_BASE_URL } from "@/config";
import { Loader2 } from "lucide-react";

import {
  ArrowLeft,
  MoreVertical,
  PlayCircle,
  Clock,
  Edit,
  History,
  Smartphone,
  Package
} from "lucide-react";
import { useAuthenticatedApi } from "@/api/api";
import { format } from "date-fns";

const TestPlanDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const api = useAuthenticatedApi();

  const [testPlan, setTestPlan] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const [isExecuting, setIsExecuting] = useState(false);

  useEffect(() => {
    const fetchTestPlan = async () => {
      try {
        const response = await api.get(`/testplans/${id}`);
        setTestPlan(response);
        console.log("TestPlan", response);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchTestPlan();
  }, [id]);

  const handleDelete = async () => {
    try {
      await api.delete(`/testplans/${id}`);
      navigate("/testplan");
    } catch (err) {
      console.error("Failed to delete test plan:", err);
    }
  };

  const executeTestPlan = async () => {
    try {
      setIsExecuting(true);

      // Generate YAML content from testPlan data
      const yamlObj = {
        TestPlanMetadata: {
          TestPlanID: testPlan.test_plan_id
        },
        Dependencies: {
          APKs: testPlan.dependencies.reduce((acc, apk) => {
            acc[apk.apk_name] = {
              PackageName: apk.package_name,
              Version: apk.version
            };
            return acc;
          }, {})
        },
        DeviceMapping: testPlan.devices.reduce((acc, device) => {
          acc[device.device_name] = {
            InstallAPKs: device.install_apks || []
          };
          return acc;
        }, {}),
        TestGroups: testPlan.test_groups.reduce((acc, group) => {
          acc[group.name.replace(/\s+/g, "")] = {
            Name: group.name,
            Sequential: group.sequential,
            TestScripts: group.test_scripts.map((script) => ({
              ScriptID: script.test_file_id.toString()
            })),
            Devices: group.devices.map((device) => device.device_name)
          };
          return acc;
        }, {})
      };

      console.log("Executing test plan with YAML:", yamlObj);

      // Create FormData with YAML
      const formData = new FormData();
      const yamlBlob = new Blob([yaml.dump(yamlObj)], {
        type: "application/x-yaml"
      });
      formData.append("yaml_file", yamlBlob, "test_plan.yml");
      formData.append("caller_id", "web_ui");

      // Make API call
      const response = await fetch(`${API_BASE_URL}/test-plan/`, {
        method: "POST",
        body: formData
      });

      const result = await response.json();
      console.log("Execution started:", result);
      // You could navigate to executions page or show success notification
    } catch (error) {
      console.error("Error executing test plan:", error);
      // You could show error notification
    } finally {
      setIsExecuting(false);
    }
  };

  if (loading) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <div className="text-lg text-muted-foreground">
          Loading test plan details...
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <div className="text-lg text-destructive">Error: {error}</div>
      </div>
    );
  }

  if (!testPlan) {
    return null;
  }

  return (
    <div className="w-full max-w-6xl mx-auto p-6 space-y-6">
      {/* Header */}
      <div className="flex items-start justify-between">
        <div className="flex items-start gap-4">
          <Link to="/testplan">
            <Button variant="secondary" size="icon" className="mt-1">
              <ArrowLeft className="h-5 w-5" />
            </Button>
          </Link>
          <div>
            <h1 className="text-2xl font-semibold text-primary">
              {testPlan.name}
            </h1>
            <div className="flex items-center gap-3 mt-1">
              {testPlan.updated_at && (
                <span className="text-sm text-muted-foreground flex items-center gap-1">
                  <Clock className="h-3.5 w-3.5" />
                  Last modified:{" "}
                  {format(new Date(testPlan.updated_at), "yyyy-MM-dd HH:mm")}
                </span>
              )}
              {testPlan.description && (
                <span className="text-sm text-muted-foreground">
                  {testPlan.description}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <Button
            className="gap-2"
            onClick={executeTestPlan}
            disabled={isExecuting}
          >
            {isExecuting ? (
              <Loader2 className="h-4 w-4 animate-spin" />
            ) : (
              <PlayCircle className="h-4 w-4" />
            )}
            {isExecuting ? "Executing..." : "Run Tests"}
          </Button>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="default" size="icon">
                <MoreVertical className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem
                className="text-destructive"
                onClick={() => setShowDeleteDialog(true)}
              >
                Delete Plan
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>

      {/* Main Content */}
      <Tabs defaultValue="overview" className="space-y-6">
        <TabsList>
          <TabsTrigger value="overview">Overview</TabsTrigger>
          <TabsTrigger value="configuration">Configuration</TabsTrigger>
        </TabsList>

        <TabsContent value="overview" className="space-y-6">
          {/* Test Groups Overview */}
          {testPlan.test_groups && testPlan.test_groups.length > 0 && (
            <Card>
              <CardHeader>
                <CardTitle className="text-lg">Test Groups</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  {testPlan.test_groups.map((group) => (
                    <div key={group.id} className="border rounded-lg p-4">
                      <div className="flex items-center justify-between mb-4">
                        <div>
                          <h3 className="font-medium">{group.name}</h3>
                          {group.sequential && (
                            <Badge variant="outline" className="mt-1">
                              Sequential Execution
                            </Badge>
                          )}
                          {group.max_parallel_test_executions && (
                            <div className="text-sm text-muted-foreground mt-1">
                              Max Parallel Executions:{" "}
                              {group.max_parallel_test_executions}
                            </div>
                          )}
                        </div>
                        <Button variant="outline" size="sm">
                          Run Group
                        </Button>
                      </div>
                      {group.test_scripts && group.test_scripts.length > 0 && (
                        <div className="space-y-2">
                          {group.test_scripts.map((script) => (
                            <div
                              key={script.id}
                              className="flex items-center justify-between p-3 bg-muted/50 rounded-md"
                            >
                              <div>
                                <div className="font-medium">
                                  Test File ID: {script.test_file_id}
                                </div>
                                <div className="text-sm text-muted-foreground">
                                  Execution Order: {script.execution_order}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>
          )}
        </TabsContent>

        <TabsContent value="configuration" className="space-y-6">
          {/* APK Configuration */}
          {testPlan.dependencies && testPlan.dependencies.length > 0 && (
            <Card>
              <CardHeader>
                <div className="flex items-center gap-2">
                  <Package className="h-5 w-5 text-primary" />
                  <CardTitle>APK Configuration</CardTitle>
                </div>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  {testPlan.dependencies.map((dependency) => (
                    <div key={dependency.id} className="p-4 border rounded-lg">
                      <div className="font-medium">{dependency.apk_name}</div>
                      <div className="text-sm text-muted-foreground mt-1">
                        Package: {dependency.package_name}
                      </div>
                      <div className="text-sm text-muted-foreground">
                        Version: {dependency.version}
                      </div>
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>
          )}

          {/* Device Mapping */}
          {testPlan.devices && testPlan.devices.length > 0 && (
            <Card>
              <CardHeader>
                <div className="flex items-center gap-2">
                  <Smartphone className="h-5 w-5 text-primary" />
                  <CardTitle>Device Mapping</CardTitle>
                </div>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  {testPlan.devices.map((device) => (
                    <div key={device.id} className="p-4 border rounded-lg">
                      <div className="flex items-center justify-between">
                        <div>
                          <div className="font-medium">
                            {device.device_name}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>
          )}

          {/* Notification Config */}
          {testPlan.notification_config && (
            <Card>
              <CardHeader>
                <CardTitle>Notification Configuration</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  {testPlan.notification_config.emails &&
                    testPlan.notification_config.emails.length > 0 && (
                      <div className="p-4 border rounded-lg">
                        <h3 className="font-medium mb-2">
                          Email Notifications
                        </h3>
                        <div className="flex flex-wrap gap-2">
                          {testPlan.notification_config.emails.map(
                            (email, index) => (
                              <Badge key={index} variant="secondary">
                                {email}
                              </Badge>
                            )
                          )}
                        </div>
                      </div>
                    )}
                  {testPlan.notification_config.discord && (
                    <div className="p-4 border rounded-lg">
                      <h3 className="font-medium mb-2">
                        Discord Notifications
                      </h3>
                      <div className="text-sm text-muted-foreground">
                        Channel: {testPlan.notification_config.discord.channel}
                      </div>
                    </div>
                  )}
                </div>
              </CardContent>
            </Card>
          )}
        </TabsContent>
      </Tabs>

      {/* Delete Confirmation Dialog */}
      <AlertDialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This will permanently delete the test plan "{testPlan.name}". This
              action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={handleDelete}
              className="bg-destructive text-destructive-foreground"
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default TestPlanDetails;
