import { useEffect, useState } from "react";
import * as monaco from "monaco-editor";
import { LineOperationsProps } from "@/types";

export const LineOperations: React.FC<LineOperationsProps> = ({
  lineNumber,
  onExecute,
  onMoveUp,
  onMoveDown,
  onClear,
  editor,
  status,
  isProcessing
}) => {
  const [position, setPosition] = useState({ top: 0 });

  useEffect(() => {
    if (!editor) return;
    const lineHeight = editor.getOption(monaco.editor.EditorOption.lineHeight);
    const topOffset = editor.getTopForLineNumber(lineNumber);
    const editorScrollTop = editor.getScrollTop();

    setPosition({
      top: topOffset - editorScrollTop + lineHeight / 2
    });

    const disposable = editor.onDidScrollChange(() => {
      const newTopOffset = editor.getTopForLineNumber(lineNumber);
      const newScrollTop = editor.getScrollTop();
      setPosition({
        top: newTopOffset - newScrollTop + lineHeight / 2
      });
    });

    return () => disposable.dispose();
  }, [editor, lineNumber]);

  // Get status indicator styles
  const getStatusColor = () => {
    if (isProcessing) return "bg-yellow-400";
    switch (status) {
      case "success":
        return "bg-green-500";
      case "error":
        return "bg-red-500";
      default:
        return "bg-gray-400";
    }
  };

  // Get button disabled state
  const isDisabled = isProcessing;

  return (
    <div className="line-operations flex items-center gap-2" style={position}>
      {/* Status indicator */}
      <div
        className={`w-2 h-2 rounded-full ${getStatusColor()} transition-colors duration-200`}
        title={isProcessing ? "Processing" : status}
      />

      <button
        className={`line-operation-button operation-play `}
        onClick={() => onExecute(lineNumber)}
        title={"Execute line"}
      />
      <button
        className={`line-operation-button operation-move-up `}
        onClick={() => onMoveUp(lineNumber)}
        title="Move up"
      />
      <button
        className={`line-operation-button operation-move-down `}
        onClick={() => onMoveDown(lineNumber)}
        title="Move down"
      />
      <button
        className={`line-operation-button operation-clear `}
        onClick={() => !isDisabled && onClear(lineNumber)}
        title="Clear line"
      />
    </div>
  );
};

export default LineOperations;
