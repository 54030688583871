import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Switch } from "@/components/ui/switch";
import { Badge } from "@/components/ui/badge";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  Github,
  Lock,
  Globe,
  Plus,
  Loader2,
  Check,
  RefreshCw
} from "lucide-react";
import { useAuthenticatedApi } from "@/api/api";
import { toast } from "@/hooks/use-toast";
import { logger } from "@/utils/logger";

const GitHubIntegration = ({ selectedRepo, onRepoSelect, onCreateRepo }) => {
  const api = useAuthenticatedApi();

  // States
  const [isConnecting, setIsConnecting] = useState(false);
  const [githubStatus, setGithubStatus] = useState(null);
  const [repositories, setRepositories] = useState([]);
  const [newRepoData, setNewRepoData] = useState({
    repo_name: "",
    description: "",
    private: true
  });

  // Check installation status
  const checkInstallationStatus = async (sessionId) => {
    try {
      const response = await api.get(
        `/github/installation/${sessionId}/status`
      );
      if (response.status === "completed") {
        setGithubStatus(response);
        return true;
      }
      return false;
    } catch (error) {
      logger.error("Failed to check installation status:", error);
      return false;
    }
  };

  // Start GitHub connection
  const handleConnectGitHub = async () => {
    setIsConnecting(true);
    try {
      const response = await api.post("/github/installation/init", {});
      const { installation_url, session_id } = response;

      // Open GitHub installation in new tab
      window.open(installation_url, "_blank");

      // Poll for status
      const pollInterval = setInterval(async () => {
        const isComplete = await checkInstallationStatus(session_id);
        if (isComplete) {
          clearInterval(pollInterval);
          setIsConnecting(false);
          fetchRepositories();
        }
      }, 2000);
    } catch (error) {
      setIsConnecting(false);
      toast({
        title: "Error",
        description: "Failed to initialize GitHub connection",
        variant: "destructive"
      });
    }
  };

  // Fetch repositories
  const fetchRepositories = async () => {
    try {
      const response = await api.get("/github/repositories");
      setRepositories(response.repositories);
    } catch (error) {
      logger.error("Failed to fetch repositories:", error);
      toast({
        title: "Error",
        description: "Failed to fetch repositories",
        variant: "destructive"
      });
    }
  };

  // Create new repository
  const handleCreateRepo = async () => {
    try {
      logger.log("newRepoData", newRepoData);
      const repoData = {
        repo_name: newRepoData.repo_name,
        description: newRepoData.description,
        private: newRepoData.private
      };

      const response = await api.post("/github/create_repo", repoData);
      logger.log("Respose", response);
      // Create repo object in the format expected by the component
      const newRepo = {
        id: response.id,
        name: response.name,
        full_name: response.full_name,
        description: response.description,
        private: response.private,
        owner: response.owner,
        default_branch: response.default_branch
      };

      // Update repositories list and select the new repo
      setRepositories((prev) => [...prev, newRepo]);
      onRepoSelect(newRepo);

      // Switch to select tab
      const tabsTrigger = document.querySelector(
        '[value="select"]'
      ) as HTMLButtonElement;
      if (tabsTrigger) tabsTrigger.click();

      toast({
        title: "Success",
        description: "Repository created successfully"
      });

      // Clear form
      setNewRepoData({
        repo_name: "",
        description: "",
        private: true
      });
    } catch (error) {
      logger.error("Failed to create repository:", error);
      toast({
        title: "Error",
        description: "Failed to create repository",
        variant: "destructive"
      });
    }
  };

  // Disconnect GitHub
  const handleDisconnect = async () => {
    try {
      await api.delete("/github/integration");
      setGithubStatus(null);
      setRepositories([]);
      toast({
        title: "Success",
        description: "GitHub disconnected successfully"
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to disconnect GitHub",
        variant: "destructive"
      });
    }
  };

  // Check initial GitHub status
  useEffect(() => {
    const checkInitialStatus = async () => {
      try {
        const integration = await api.get("/github/integration");
        if (integration) {
          setGithubStatus({
            github_username: integration.username,
            status: "completed"
          });
          fetchRepositories();
        }
      } catch (error) {
        // Integration not found is expected for new users
        if (error.response?.status !== 404) {
          logger.error("Failed to check GitHub status:", error);
        }
      }
    };
    checkInitialStatus();
  }, []);

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center gap-2 text-lg">
          <Github className="h-5 w-5 text-primary" />
          GitHub Integration
        </CardTitle>
        <CardDescription>
          Connect your GitHub repository to sync test cases
        </CardDescription>
      </CardHeader>

      <CardContent className="space-y-6">
        {!githubStatus ? (
          <div className="flex flex-col items-center justify-center py-6 gap-4">
            <div className="h-12 w-12 rounded-full bg-primary/10 flex items-center justify-center">
              <Github className="h-6 w-6 text-primary" />
            </div>
            <div className="text-center">
              <h3 className="font-medium mb-1">Connect GitHub Repository</h3>
              <p className="text-sm text-muted-foreground max-w-sm">
                Link your GitHub repository to sync and manage your test cases
              </p>
            </div>
            <Button
              variant="outline"
              className="gap-2"
              onClick={handleConnectGitHub}
              disabled={isConnecting}
            >
              {isConnecting ? (
                <>
                  <Loader2 className="h-4 w-4 animate-spin" />
                  Connecting...
                </>
              ) : (
                <>
                  <Github className="h-4 w-4" />
                  Connect GitHub
                </>
              )}
            </Button>
          </div>
        ) : (
          <div className="space-y-6">
            <div className="flex items-center justify-between p-3 rounded-lg bg-primary/5 border border-primary/10">
              <div className="flex items-center gap-3">
                <div className="h-8 w-8 rounded-full bg-primary/10 flex items-center justify-center">
                  <Check className="h-4 w-4 text-primary" />
                </div>
                <div>
                  <div className="font-medium">Connected to GitHub</div>
                  <div className="text-sm text-muted-foreground">
                    {githubStatus.github_username}
                  </div>
                </div>
              </div>
              {/* <div className="flex items-center gap-2">
                <Button
                  variant="ghost"
                  size="sm"
                  className="gap-2"
                  onClick={fetchRepositories}
                >
                  <RefreshCw className="h-4 w-4" />
                  Refresh
                </Button>
                <Button
                  variant="ghost"
                  size="sm"
                  className="gap-2 text-destructive hover:text-destructive hover:bg-destructive/10"
                  onClick={handleDisconnect}
                >
                  <Github className="h-4 w-4" />
                  Disconnect
                </Button>
              </div> */}
            </div>

            <Tabs defaultValue="select" className="w-full">
              <TabsList className="w-full mb-4">
                <TabsTrigger value="select" className="w-1/2">
                  Select Repository
                </TabsTrigger>
                <TabsTrigger value="create" className="w-1/2">
                  Create Repository
                </TabsTrigger>
              </TabsList>

              <TabsContent value="select">
                <div className="space-y-4">
                  {repositories.map((repo) => (
                    <div
                      key={repo.id}
                      className={`flex items-center justify-between p-3 rounded-lg border cursor-pointer transition-colors 
  ${
    selectedRepo?.id === repo.id
      ? "bg-primary/10 border-primary"
      : "hover:bg-accent"
  }`}
                      onClick={() => onRepoSelect(repo)}
                    >
                      <div className="flex items-center gap-2">
                        {repo.private ? (
                          <Lock className="h-4 w-4" />
                        ) : (
                          <Globe className="h-4 w-4" />
                        )}
                        <div>
                          <div className="font-medium">{repo.name}</div>
                          <div className="text-sm text-muted-foreground">
                            {repo.description || "No description"}
                          </div>
                        </div>
                      </div>
                      <Badge
                        variant={
                          selectedRepo === repo.name ? "default" : "outline"
                        }
                      >
                        {repo.private ? "Private" : "Public"}
                      </Badge>
                    </div>
                  ))}
                </div>
              </TabsContent>

              <TabsContent value="create" className="space-y-4">
                <Input
                  placeholder="Repository name"
                  value={newRepoData.repo_name}
                  onChange={(e) =>
                    setNewRepoData({
                      ...newRepoData,
                      repo_name: e.target.value
                    })
                  }
                />
                <Textarea
                  placeholder="Repository description"
                  value={newRepoData.description}
                  onChange={(e) =>
                    setNewRepoData({
                      ...newRepoData,
                      description: e.target.value
                    })
                  }
                />
                <div className="flex items-center justify-between p-3 rounded-lg border">
                  <span>Private Repository</span>
                  <Switch
                    checked={newRepoData.private}
                    onCheckedChange={(checked) =>
                      setNewRepoData({
                        ...newRepoData,
                        private: checked
                      })
                    }
                  />
                </div>
                <Button
                  className="w-full gap-2"
                  onClick={handleCreateRepo}
                  disabled={!newRepoData.repo_name}
                >
                  <Plus className="h-4 w-4" />
                  Create Repository
                </Button>
              </TabsContent>
            </Tabs>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default GitHubIntegration;
