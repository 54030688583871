import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Badge } from "@/components/ui/badge";
import { Folder, ArrowLeft, FileCode, Plus, Loader2 } from "lucide-react";
import { toast } from "@/hooks/use-toast";
import { useAuthenticatedApi } from "@/api/api";
import { cn } from "@/lib/utils";
import GitHubIntegration from "../../components/GitHubIntegration";

const CreateProject = () => {
  const navigate = useNavigate();
  const api = useAuthenticatedApi();

  // Project states
  const [projectName, setProjectName] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // GitHub states
  const [selectedRepo, setSelectedRepo] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState("");

  // Handle project creation
  const handleSubmit = async () => {
    if (!projectName.trim()) {
      toast({
        title: "Error",
        description: "Project name is required",
        variant: "destructive"
      });
      return;
    }

    setIsSubmitting(true);
    try {
      const projectData = {
        name: projectName,
        description: projectDescription || "",
        github_repo_name: selectedRepo?.name || null,
        github_repo_owner: selectedRepo?.owner?.login || null,
        github_branch: selectedBranch || "main", // Default to main if not specified
        github_sync_enabled: Boolean(selectedRepo),
        github_repo_id: selectedRepo?.id || null
      };

      await api.post("/projects", projectData);

      toast({
        title: "Success",
        description: "Project created successfully"
      });

      navigate("/project");
    } catch (error) {
      console.error("Failed to create project:", error);

      let errorMessage = "Failed to create project";
      try {
        const errorData = JSON.parse(error.message);
        if (Array.isArray(errorData.detail)) {
          errorMessage = errorData.detail.map((err) => err.msg).join(", ");
        } else {
          errorMessage = errorData.detail || errorMessage;
        }
      } catch (e) {
        errorMessage = error.message || errorMessage;
      }

      toast({
        title: "Error",
        description: errorMessage,
        variant: "destructive"
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle repository selection
  const handleRepoSelect = (repo) => {
    setSelectedRepo(repo);
    setSelectedBranch(repo.default_branch || "main");
  };

  return (
    <div className="min-h-screen bg-background">
      <div className="p-8 max-w-6xl mx-auto">
        {/* Header */}
        <div className="flex items-center gap-4 mb-8">
          <Link to="/project">
            <Button
              variant="secondary"
              size="icon"
              className="hover:bg-accent hover:text-accent-foreground"
            >
              <ArrowLeft className="h-5 w-5" />
            </Button>
          </Link>

          <div className="flex-1">
            <h1 className="text-2xl font-semibold tracking-tight text-foreground">
              Create Project
            </h1>
            <p className="text-muted-foreground mt-1">
              Add a new test project to Drizz
            </p>
          </div>
        </div>

        <div className="space-y-6">
          {/* Project Details Card */}
          <Card className="border-border bg-card">
            <CardHeader>
              <CardTitle className="flex items-center gap-2 text-lg text-card-foreground">
                <Folder className="h-5 w-5 text-primary" />
                Project Details
              </CardTitle>
              <CardDescription className="text-muted-foreground">
                Basic information about your test project
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="projectName" className="text-foreground">
                  Project Name
                </Label>
                <Input
                  id="projectName"
                  value={projectName}
                  onChange={(e) => setProjectName(e.target.value)}
                  placeholder="Enter project name"
                  className={cn(
                    "bg-background text-foreground",
                    "border-input",
                    "focus-visible:ring-ring focus-visible:ring-2",
                    "placeholder:text-muted-foreground"
                  )}
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="description" className="text-foreground">
                  Description
                </Label>
                <Textarea
                  id="description"
                  value={projectDescription}
                  onChange={(e) => setProjectDescription(e.target.value)}
                  placeholder="Brief description about your test project"
                  className={cn(
                    "resize-none",
                    "bg-background text-foreground",
                    "border-input",
                    "focus-visible:ring-ring focus-visible:ring-2",
                    "placeholder:text-muted-foreground"
                  )}
                  rows={3}
                />
              </div>
            </CardContent>
          </Card>

          {/* GitHub Integration */}
          <GitHubIntegration
            selectedRepo={selectedRepo}
            onRepoSelect={handleRepoSelect}
            onCreateRepo={handleRepoSelect}
          />

          {/* Selected Repository Info */}
          {selectedRepo && (
            <div className="flex items-center gap-2 p-3 rounded-md bg-secondary text-sm">
              <FileCode className="h-4 w-4 text-primary" />
              <span>Test files will be synced from:</span>
              <Badge variant="secondary" className="font-mono">
                {selectedRepo.full_name}/{selectedBranch}/tests
              </Badge>
            </div>
          )}

          {/* Submit Buttons */}
          <div className="sticky bottom-0 border-t border-border bg-background/80 backdrop-blur-xl p-4 mt-6">
            <div className="max-w-6xl mx-auto flex justify-end gap-4">
              <Button
                variant="secondary"
                onClick={() => navigate("/project")}
                disabled={isSubmitting}
                className="border-border hover:bg-accent hover:text-accent-foreground"
              >
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                disabled={isSubmitting || !projectName.trim()}
                className={cn(
                  "gap-2 min-w-[120px]",
                  "bg-primary text-primary-foreground",
                  "hover:bg-primary/90"
                )}
              >
                {isSubmitting ? (
                  <>
                    <Loader2 className="h-4 w-4 animate-spin" />
                    Creating...
                  </>
                ) : (
                  <>
                    <Plus className="h-4 w-4" />
                    Create Project
                  </>
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateProject;
