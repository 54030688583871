import React, {
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle
} from "react";
import { useAppSelector } from "@/store/hooks";
import { EditorToolbar } from "@/components/features/editor/EditorToolbar";
import { MonacoEditor } from "@/components/features/editor/MonacoEditor";
import {
  selectConfig,
  selectSingleModeLoading
} from "@/store/features/editorSlice";
import {
  EditorContainerProps,
  EditorContainerRef,
  MonacoEditorRef
} from "@/types";
import "@/style/editor.css";

export const EditorContainer = forwardRef<
  EditorContainerRef,
  EditorContainerProps
>((props, ref) => {
  const {
    onEditorChange,
    onRun,
    onStop,
    isInCooldown,
    onReset,
    initialValue = "",
    onSaveCode,
    onNewFile,
    onSaveAs,
    isRunning,
    canStop,
    isStopping,
    isSingleLineMode = false,
    onCloseInstance,
    onDownloadCode,
    isLoading,
    setIsLoading,
    showGithubSync,
    onGitHubSync,
    onSaveInstanceChange,
    testcaseId
  } = props;

  const [editorContent, setEditorContent] = useState(initialValue);
  const [saveInstance, setSaveInstance] = useState(false);
  const editorRef = React.useRef<MonacoEditorRef>(null);
  const singleModeLoading = useAppSelector(selectSingleModeLoading);
  const config = useAppSelector(selectConfig);

  useImperativeHandle(ref, () => ({
    getValue: () => editorRef.current?.getValue() ?? "",
    setValue: (value: string) => {
      editorRef.current?.setValue(value);
      setEditorContent(value);
      onEditorChange?.(value);
    },
    clearExecutedLines: () => {
      editorRef.current?.clearExecutedLines?.();
    }
  }));

  const handleFileUpload = useCallback(
    (content: string) => {
      if (editorRef.current) {
        editorRef.current.setValue(content);
        setEditorContent(content);
        onEditorChange?.(content);
      }
    },
    [onEditorChange]
  );

  const handleRun = useCallback(async () => {
    await onRun();
  }, [onRun]);

  const handleStop = useCallback(async () => {
    try {
      setIsLoading(true);
      await onStop?.();
    } catch (error) {
      console.error("Error stopping execution:", error);
    } finally {
      setIsLoading(false);
    }
  }, [onStop]);

  const handleClear = useCallback(() => {
    onReset();
  }, [onReset]);

  const handleEditorChange = useCallback(
    (value: string) => {
      if (isRunning) return; // Prevent changes while running

      setEditorContent(value);
      onEditorChange(value);

      if (saveInstance) {
        localStorage.setItem("editorContent", value);
      }
    },
    [onEditorChange, saveInstance, isRunning]
  );

  React.useEffect(() => {
    const savedContent = localStorage.getItem("editorContent");
    if (saveInstance && savedContent) {
      setEditorContent(savedContent);
      onEditorChange?.(savedContent);
    }
  }, []);

  return (
    <div id="editorSection" className="flex flex-col ">
      <EditorToolbar
        onRun={handleRun}
        onStop={handleStop}
        onClear={handleClear}
        onFileUpload={handleFileUpload}
        canStop={canStop}
        isInCooldown={isInCooldown}
        isStopping={isStopping}
        onSaveCode={onSaveCode}
        isRunning={isRunning}
        saveInstance={config.saveInstance}
        onSaveInstanceChange={onSaveInstanceChange}
        isSingleLineMode={isSingleLineMode}
        isLoading={isLoading}
        onCloseInstance={onCloseInstance}
        onDownloadCode={onDownloadCode}
        testcaseId={testcaseId}
        onNewFile={onNewFile}
        onSaveAs={onSaveAs}
        showGithubSync={showGithubSync}
        onGitHubSync={onGitHubSync}
      />
      <div
        className={`editor-container relative ${
          isRunning ? "editor-disabled" : ""
        }`}
      >
        <MonacoEditor
          ref={editorRef}
          value={editorContent}
          onChange={handleEditorChange}
          height="100%"
          isRunning={isRunning}
          isSingleLineMode={isSingleLineMode}
        />
        {isLoading && (
          <div className="absolute inset-0 bg-black bg-opacity-20 flex items-center justify-center">
            <div className="spinner" id="spinner"></div>
          </div>
        )}
        {(isRunning && !isSingleLineMode) ||
        (isSingleLineMode && singleModeLoading) ? (
          <div className="absolute inset-0 bg-black bg-opacity-10 cursor-not-allowed" />
        ) : null}
      </div>
    </div>
  );
});
