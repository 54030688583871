import { useConfigCheck } from "@/hooks/useConfigRedirect";

export function ConfigGuard({ children }) {
  const { hasValidConfig } = useConfigCheck();

  if (!hasValidConfig) {
    return null;
  }

  return children;
}
