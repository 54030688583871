import { Auth0Provider } from "@auth0/auth0-react";
import { ProtectedPageWrapper } from "@/components/PageWrapper";
import { UnauthorizedPage } from "@/screens/Auth/UnauthorizedPage";
import { Dashboard } from "@/screens/Dashboard/index";
import { LoginPage } from "@/screens/Auth/LoginPage";
import { StartPage } from "@/screens/Start/index";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import { ProtectedRoute, PublicRoute } from "./routes";
import { ProjectListScreen } from "@/screens/project/ProjectListScreen/index";
import ProjectsDashboard from "./screens/new-design/screen/project/ProjectsDashboard";
import CreateProject from "./screens/new-design/screen/project/CreateProject";
import TestFileExplorer from "./screens/new-design/screen/project/ProjectItem";
import ProjectSettings from "./screens/new-design/screen/project/ProjectSettings";
import TestPlanExplorer from "./screens/new-design/screen/project-plan/TestPlanScreen";
import CreateTestPlan from "./screens/new-design/screen/project-plan/CreateTestPlan";
import TestPlanDetails from "./screens/new-design/screen/project-plan/TestPlanDetails";
import AppLayout from "./screens/new-design/components/Layout";
import NotFound from "./screens/NotFound";
import TestEditor from "./screens/new-design/screen/playground/Dashboard";
import GithubCallback from "./components/GithubCallback";
import { ConfigGuard } from "./routes/ConfigGuard";
import TestPlanExecutionScreen from "./screens/new-design/screen/project-plan/TestPlanExecute";

function App() {
  return (
    <div className="dark bg-background">
      <Auth0Provider
        domain={import.meta.env.VITE_AUTH0_DOMAIN}
        clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
        authorizationParams={{
          redirect_uri: `${window.location.origin}/login`,
          scope: "openid profile email offline_access",
          audience: "https://auth.drizz.dev",
          response_type: "token id_token"
        }}
        cacheLocation="localstorage"
        useRefreshTokens={true}
      >
        <BrowserRouter>
          <Routes>
            <Route
              path="/login"
              element={
                <PublicRoute>
                  <LoginPage />
                </PublicRoute>
              }
            />
            <Route
              path="/unauthorized"
              element={
                <ProtectedRoute>
                  <UnauthorizedPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <ConfigGuard>
                  <ProtectedPageWrapper>
                    <Dashboard />
                  </ProtectedPageWrapper>
                </ConfigGuard>
              }
            />
            <Route
              path="/start"
              element={
                <ProtectedPageWrapper>
                  <AppLayout>
                    <StartPage />
                  </AppLayout>
                </ProtectedPageWrapper>
              }
            />
            <Route
              path="/github/callback"
              element={
                <ProtectedRoute>
                  <GithubCallback />
                </ProtectedRoute>
              }
            />

            <Route
              path="/testplan/execute"
              element={
                <ProtectedRoute>
                  <AppLayout>
                    <TestPlanExecutionScreen />
                  </AppLayout>
                </ProtectedRoute>
              }
            />

            <Route
              path="/testplan/:id"
              element={
                <ProtectedRoute>
                  <AppLayout>
                    <TestPlanDetails />
                  </AppLayout>
                </ProtectedRoute>
              }
            />

            <Route
              path="/testplan/new"
              element={
                <ProtectedRoute>
                  <AppLayout>
                    <CreateTestPlan />
                  </AppLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/testplan"
              element={
                <ProtectedRoute>
                  <AppLayout>
                    <TestPlanExplorer />
                  </AppLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/project/create"
              element={
                <ProtectedRoute>
                  <AppLayout>
                    <CreateProject />
                  </AppLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/project/setting/:projectId"
              element={
                <ProtectedRoute>
                  <AppLayout>
                    <ProjectSettings />
                  </AppLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/project/:projectId"
              element={
                <ProtectedRoute>
                  <AppLayout>
                    <TestFileExplorer />
                  </AppLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/project"
              element={
                <ProtectedRoute>
                  <AppLayout>
                    <ProjectsDashboard />
                  </AppLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/playground"
              element={
                <ProtectedRoute>
                  <TestEditor />
                </ProtectedRoute>
              }
            />
            {/* End Here */}
            <Route path="/" element={<Navigate to="/dashboard" replace />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </Auth0Provider>
    </div>
  );
}

export default App;
