import React, { useState, useRef, useEffect } from "react";
import { LoggerProps, ScriptStep, LogEntry } from "@/types";
import DOMPurify from "dompurify";
import { ChevronDown } from "lucide-react";
import "@/style/editor.css";

// Utility function to detect and format JSON content
const formatLogMessage = (message: string): string => {
  // Try to find JSON content in the message
  const jsonRegex = /(\{[\s\S]*\}|\[[\s\S]*\])/;
  const match = message.match(jsonRegex);

  if (!match) return message;

  try {
    const jsonPart = match[0];
    const parsedJson = JSON.parse(jsonPart);
    const formattedJson = JSON.stringify(parsedJson, null, 2);

    // Replace the JSON part with formatted version wrapped in pre tags
    return message.replace(
      jsonPart,
      `<pre class="json-content bg-gray-800 rounded p-3 my-2 overflow-x-auto">${formattedJson}</pre>`
    );
  } catch (e) {
    // If JSON parsing fails, return original message
    return message;
  }
};

export const Logger: React.FC<LoggerProps> = ({ testScript, logs }) => {
  const [activeTab, setActiveTab] = useState<"testScript" | "testLogs">(
    "testLogs"
  );
  const logsEndRef = useRef<HTMLDivElement>(null);
  const logsContainerRef = useRef<HTMLDivElement>(null);
  const [autoScroll, setAutoScroll] = useState(true);

  useEffect(() => {
    if (autoScroll && logsEndRef.current) {
      logsEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [logs, autoScroll]);

  const handleScroll = () => {
    if (!logsContainerRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = logsContainerRef.current;
    const isScrolledToBottom = scrollHeight - scrollTop - clientHeight < 50;
    setAutoScroll(isScrolledToBottom);
  };

  const scrollToBottom = () => {
    if (logsEndRef.current) {
      logsEndRef.current.scrollIntoView({ behavior: "smooth" });
      setAutoScroll(true);
    }
  };

  const renderTestScripts = (steps: ScriptStep[]) => {
    return steps.map((step) => (
      <div
        key={step.id}
        className="step-item hover:bg-gray-800 transition-colors"
      >
        <div
          className={`step-icon ${step.status}`}
          data-id={step.id}
          title={`Status: ${step.status}`}
        />
        <span className="step-text">{step.text}</span>
      </div>
    ));
  };

  const renderLogs = (entries: LogEntry[]) => {
    if (entries.length === 0) {
      return (
        <div className="text-gray-500 italic p-3 text-sm">
          Logs will appear here...
        </div>
      );
    }

    return (
      <>
        {entries.map((entry) => {
          const formattedMessage = formatLogMessage(entry.message);

          return (
            <div
              key={entry.id}
              className={`log-entry py-1 px-3 leading-tight font-mono text-sm hover:bg-gray-800 transition-colors ${
                entry.type === "error" ? "text-red-400" : "text-gray-300"
              }`}
            >
              <span className="log-timestamp text-gray-500 text-xs mr-2">
                [{entry.timestamp}]
              </span>
              <span
                className="log-message"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(formattedMessage)
                }}
              />
            </div>
          );
        })}
        <div ref={logsEndRef} className="h-0" />
      </>
    );
  };

  return (
    <div className="logger-section bg-gray-900 border border-gray-800">
      {/* Style tag for JSON formatting */}
      <style>
        {`
          .json-content {
            font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', monospace;
            font-size: 12px;
            line-height: 1.4;
            tab-size: 2;
          }
          .json-content::-webkit-scrollbar {
            height: 6px;
          }
          .json-content::-webkit-scrollbar-track {
            background: #2d2d2d;
          }
          .json-content::-webkit-scrollbar-thumb {
            background: #666;
            border-radius: 3px;
          }
        `}
      </style>

      <div className="tab-container flex items-center justify-between px-2 border-b border-gray-800">
        <div className="flex">
          <button
            className={`tab-button text-sm px-4 py-2 ${
              activeTab === "testScript" ? "active" : ""
            }`}
            onClick={() => setActiveTab("testScript")}
            type="button"
          >
            Test Status
          </button>
          <button
            className={`tab-button text-sm px-4 py-2 ${
              activeTab === "testLogs" ? "active" : ""
            }`}
            onClick={() => setActiveTab("testLogs")}
            type="button"
          >
            Log
          </button>
        </div>

        {activeTab === "testLogs" && (
          <button
            onClick={scrollToBottom}
            className="flex items-center gap-1 px-2 py-1 text-xs text-gray-400 hover:text-white transition-colors"
            title="Scroll to bottom"
            type="button"
          >
            <ChevronDown size={14} />
            <span>Scroll to Bottom</span>
          </button>
        )}
      </div>

      <div className="tab-panels">
        {activeTab === "testScript" ? (
          <div id="testScript" className="tab-content active">
            <div
              id="testScriptContent"
              className="test-script-panel p-2 space-y-1"
            >
              {renderTestScripts(testScript)}
            </div>
          </div>
        ) : (
          <div id="testLogs" className="tab-content active">
            <div
              ref={logsContainerRef}
              id="logs"
              className="log-content h-full overflow-auto"
              onScroll={handleScroll}
            >
              {renderLogs(logs)}
            </div>

            {!autoScroll && logs.length > 0 && (
              <button
                onClick={scrollToBottom}
                className="scroll-to-bottom bg-gray-800 hover:bg-gray-700"
                aria-label="Scroll to bottom"
                type="button"
              >
                <ChevronDown size={20} />
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Logger;
