import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  ChevronRight,
  ChevronDown,
  Folder,
  FileText,
  Loader2,
  FolderOpen
} from "lucide-react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Separator } from "@/components/ui/separator";
import { cn } from "@/lib/utils";

const ProjectSelector = ({ projects = [], selectedProject, onSelect }) => {
  if (!projects || projects.length === 0) {
    return null;
  }

  return (
    <div className="space-y-1.5">
      <label className="text-xs font-medium text-foreground/70">
        Current Project
      </label>
      <select
        className="w-full rounded-md border border-input/50 bg-transparent px-2.5 py-1.5 text-sm 
                 ring-offset-background focus:outline-none focus:ring-1 focus:ring-ring focus:border-ring
                 transition-colors"
        value={selectedProject?.id || ""}
        onChange={(e) => {
          const project = projects.find(
            (p) => p.id === parseInt(e.target.value)
          );
          onSelect(project);
        }}
      >
        {projects.map((project) => (
          <option key={project.id} value={project.id}>
            {project.name}
          </option>
        ))}
      </select>
    </div>
  );
};

const FolderItem = ({ item, level = 0, onFileSelect, selectedFileId }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const hasChildren =
    item.children?.filter((child) => child.children)?.length > 0;
  const hasFiles =
    item.children?.filter((child) => !child.children)?.length > 0;

  return (
    <div className="w-full">
      <div
        className={cn(
          "group flex items-center gap-1.5 py-1.5 px-2 rounded-md cursor-pointer",
          "hover:bg-accent/30 dark:hover:bg-accent/20",
          "border border-transparent",
          "hover:border-accent/20",
          "transition-all duration-200"
        )}
        style={{ paddingLeft: `${level * 12 + 8}px` }}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="flex items-center gap-1.5 flex-1">
          {(hasChildren || hasFiles) && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(!isExpanded);
              }}
              className="flex items-center justify-center w-3.5 h-3.5 
                       text-muted-foreground hover:text-foreground
                       transition-colors"
            >
              {isExpanded ? (
                <ChevronDown className="h-3.5 w-3.5" />
              ) : (
                <ChevronRight className="h-3.5 w-3.5" />
              )}
            </button>
          )}
          {!hasChildren && !hasFiles && <div className="w-3.5" />}
          {isExpanded ? (
            <FolderOpen className="h-4 w-4 text-blue-500/90 dark:text-blue-400/90" />
          ) : (
            <Folder className="h-4 w-4 text-blue-500/70 dark:text-blue-400/70" />
          )}
          <span className="text-sm font-medium text-foreground/80 group-hover:text-foreground/90">
            {item.name}
          </span>
        </div>
      </div>

      {isExpanded && (
        <div className="w-full">
          {item.children
            ?.filter((child) => child.children)
            ?.map((folder) => (
              <FolderItem
                key={folder.id}
                item={folder}
                level={level + 1}
                onFileSelect={onFileSelect}
                selectedFileId={selectedFileId}
              />
            ))}

          {item.children
            ?.filter((child) => !child.children)
            ?.map((file) => (
              <div
                key={file.id}
                className={cn(
                  "flex items-center gap-1.5 py-1.5 px-2 rounded-md cursor-pointer",
                  "hover:bg-accent/20",
                  "border border-transparent",
                  selectedFileId === file.id.toString()
                    ? "bg-primary/10 dark:bg-primary/20 border-primary/30"
                    : "hover:bg-accent/20 border-transparent hover:border-accent/10"
                )}
                style={{ paddingLeft: `${(level + 1) * 12 + 8}px` }}
                onClick={() => onFileSelect(file)}
              >
                <div className="w-3.5" />
                <FileText
                  className={cn(
                    "h-4 w-4",
                    selectedFileId === file.id.toString()
                      ? "text-foreground"
                      : "text-muted-foreground/70"
                  )}
                />
                <span
                  className={cn(
                    "text-sm",
                    selectedFileId === file.id.toString()
                      ? "text-foreground font-medium"
                      : "text-foreground/70 hover:text-foreground/90"
                  )}
                >
                  {file.name}
                </span>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export function FileExplorerContent({
  projects = [],
  folders = null,
  selectedProject = null,
  isLoading = false,
  onProjectSelect,
  onFileSelect,
  onNewFile
}) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [localLoading, setLocalLoading] = useState(false);
  const selectedFileId = searchParams.get("testcase_id");

  const handleProjectChange = async (project) => {
    setLocalLoading(true);
    await onProjectSelect(project);
    setLocalLoading(false);
  };

  return (
    <div className="space-y-4">
      <div className="px-1">
        <ProjectSelector
          projects={projects}
          selectedProject={selectedProject}
          onSelect={handleProjectChange}
        />
      </div>

      {selectedProject && (
        <>
          <Separator className="bg-border/50" />
          <ScrollArea className="h-[calc(100vh-220px)]">
            <div className="space-y-0.5 pr-3 pl-1">
              {isLoading || localLoading ? (
                <div className="space-y-3">
                  <div className="space-y-2">
                    <div className="h-4 w-[60%] rounded-lg bg-muted animate-pulse" />
                    <div className="h-4 w-[80%] rounded-lg bg-muted animate-pulse" />
                    <div className="h-4 w-[70%] rounded-lg bg-muted animate-pulse" />
                  </div>
                  <div className="space-y-2">
                    <div className="h-4 w-[75%] rounded-lg bg-muted animate-pulse" />
                    <div className="h-4 w-[65%] rounded-lg bg-muted animate-pulse" />
                  </div>
                </div>
              ) : (
                folders?.map((folder) => (
                  <FolderItem
                    key={folder.id}
                    item={folder}
                    onFileSelect={onFileSelect}
                    selectedFileId={selectedFileId}
                  />
                ))
              )}
            </div>
          </ScrollArea>
        </>
      )}
    </div>
  );
}
