import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { ChevronRight, ChevronDown, Folder } from "lucide-react";
import { cn } from "@/lib/utils";

const ProjectSelector = ({ projects, selectedProject, onSelect }) => {
  return (
    <div className="space-y-2">
      <Label>Current Project</Label>
      <select
        className={cn(
          "w-full rounded-md px-3 py-2 text-sm",
          "dark:bg-gray-950 dark:border-gray-800"
        )}
        value={selectedProject?.id || ""}
        onChange={(e) => {
          const project = projects.find(
            (p) => p.id === parseInt(e.target.value)
          );
          onSelect(project);
        }}
      >
        {projects.map((project) => (
          <option
            key={project.id}
            value={project.id}
            className="dark:bg-gray-950"
          >
            {project.name}
          </option>
        ))}
      </select>
    </div>
  );
};

const FolderItem = ({ item, level = 0, selectedFolder, onSelect }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const hasChildren =
    item.children?.filter((child) => child.children)?.length > 0;
  const isSelected = selectedFolder?.id === item.id;

  return (
    <div className="w-full">
      <div
        className={cn(
          "group flex items-center gap-2 p-2 rounded-md cursor-pointer transition-all duration-200",
          isSelected
            ? "bg-gray-400 text-primary-foreground shadow-sm"
            : "hover:bg-accent hover:text-accent-foreground"
        )}
        style={{ paddingLeft: `${level * 16 + 8}px` }}
        onClick={() => onSelect(item)}
      >
        <div className="flex items-center gap-2 flex-1">
          {hasChildren && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(!isExpanded);
              }}
              className="flex items-center justify-center w-4 h-4 dark:hover:text-gray-300"
            >
              {isExpanded ? (
                <ChevronDown className="h-4 w-4" />
              ) : (
                <ChevronRight className="h-4 w-4" />
              )}
            </button>
          )}
          {!hasChildren && <div className="w-4" />}
          <Folder
            className={cn(
              "h-4 w-4",
              isSelected ? "text-primary" : "dark:text-gray-400"
            )}
          />
          <span
            className={cn(
              "text-sm font-medium",
              isSelected
                ? "text-primary"
                : "dark:text-gray-400 dark:group-hover:text-gray-200"
            )}
          >
            {item.name}
          </span>
        </div>
      </div>

      {isExpanded && hasChildren && (
        <div className="w-full">
          {item.children
            ?.filter((child) => child.children)
            ?.map((folder) => (
              <FolderItem
                key={folder.id}
                item={folder}
                level={level + 1}
                selectedFolder={selectedFolder}
                onSelect={onSelect}
              />
            ))}
        </div>
      )}
    </div>
  );
};

interface SaveFileDialogProps {
  isOpen: boolean;
  onClose: () => void;
  projectId?: number;
  folderId?: number;
  onSave: (data: {
    projectId: number;
    folderId: number;
    fileName: string;
  }) => void;
  // Add new props
  projects: any[];
  folders: any[] | null;
  selectedProject: any | null;
  isLoading: boolean;
  onProjectSelect: (project: any) => void;
}

export const TestManagementFileDialog: React.FC<SaveFileDialogProps> = ({
  isOpen,
  onClose,
  projectId,
  folderId,
  onSave,
  // Add new props
  projects = [],
  folders = null,
  selectedProject = null,
  isLoading = false,
  onProjectSelect
}) => {
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [selectedFolder, setSelectedFolder] = useState(null);

  useEffect(() => {
    if (!isOpen) {
      setName("");
      setError("");
      setSelectedFolder(null);
    }
  }, [isOpen]);

  // Remove projects fetching effect since we're getting it as prop

  // If projectId is provided, find and select that project
  useEffect(() => {
    if (projectId) {
      const project = projects.find((p) => p.id === projectId);
      if (project) {
        onProjectSelect(project);
        // If folderId is provided, find that folder after folders are loaded
        if (folderId && folders) {
          const findFolder = (folderList) => {
            for (const folder of folderList) {
              if (folder.id === folderId) return folder;
              if (folder.children) {
                const found = findFolder(folder.children);
                if (found) return found;
              }
            }
            return null;
          };
          const folder = findFolder(folders);
          if (folder) setSelectedFolder(folder);
        }
      }
    }
  }, [projectId, folderId, projects, folders]);

  const handleSave = () => {
    if (!selectedProject) {
      setError("Please select a project");
      return;
    }
    if (!selectedFolder) {
      setError("Please select a folder");
      return;
    }
    if (!name.trim()) {
      setError("Please enter a file name");
      return;
    }

    onSave({
      projectId: selectedProject.id,
      folderId: selectedFolder.id,
      fileName: name.trim()
    });
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent
        className={cn(
          "sm:max-w-[500px] h-[600px] flex flex-col",
          "dark:bg-gray-950 dark:border-gray-800"
        )}
      >
        <DialogHeader className="border-b pb-4 dark:border-gray-800">
          <DialogTitle className="dark:text-gray-200">
            Save Test Case
          </DialogTitle>
        </DialogHeader>

        <div className="flex flex-col flex-1 gap-4">
          <ProjectSelector
            projects={projects}
            selectedProject={selectedProject}
            onSelect={onProjectSelect}
          />

          <div className="space-y-2">
            <Label className="dark:text-gray-200">File Name</Label>
            <Input
              placeholder="Enter test case name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setError("");
              }}
              className={cn(
                "dark:bg-gray-950 dark:border-gray-800",
                "dark:text-gray-200 dark:placeholder:text-gray-500"
              )}
            />
          </div>

          {selectedProject && (
            <>
              <Label className="dark:text-gray-200">Select Folder</Label>
              <div className="flex-1 border rounded-lg overflow-y-auto dark:bg-gray-950/50 dark:border-gray-800">
                <div className="p-2 space-y-0.5">
                  {folders?.map((folder) => (
                    <FolderItem
                      key={folder.id}
                      item={folder}
                      selectedFolder={selectedFolder}
                      onSelect={setSelectedFolder}
                    />
                  ))}
                </div>
              </div>
            </>
          )}

          {selectedFolder && (
            <div className="text-xs px-2 py-1.5 rounded-md dark:bg-gray-800/30 dark:text-gray-200">
              Project: {selectedProject?.name} → Folder: {selectedFolder.name}
            </div>
          )}

          {error && <p className="text-xs text-destructive">{error}</p>}
        </div>

        <DialogFooter className="border-t pt-4 dark:border-gray-800">
          <Button
            variant="outline"
            onClick={onClose}
            className="dark:bg-gray-950 dark:text-gray-200 dark:hover:bg-gray-800"
          >
            Cancel
          </Button>
          <Button onClick={handleSave} className="dark:hover:bg-primary/90">
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
