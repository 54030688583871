import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "@/components/ui/table";
import { Progress } from "@/components/ui/progress";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  PlayCircle,
  StopCircle,
  RefreshCcw,
  CheckCircle2,
  XCircle,
  Clock,
  Smartphone,
  AlertCircle,
  ChevronRight,
  Terminal
} from "lucide-react";

const TestPlanExecutionScreen = () => {
  const { id } = useParams();
  const [isRunning, setIsRunning] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState("carinfo-prod-1");
  const [executionLogs, setExecutionLogs] = useState([]);
  const [progress, setProgress] = useState(0);

  // Sample execution data
  const testPlan = {
    id: "CI_2025_001",
    name: "RC Search Flow Validation",
    device: "carinfo-prod-1",
    app: {
      name: "CarInfo",
      version: "1.0.0"
    },
    testGroups: [
      {
        name: "RC Search Basic Flow",
        tests: [
          {
            id: "RC001",
            name: "Valid RC Number Search",
            status: "Running",
            duration: "-"
          },
          {
            id: "RC002",
            name: "Invalid RC Format Validation",
            status: "Pending",
            duration: "-"
          }
        ]
      },
      {
        name: "RC Details Verification",
        tests: [
          {
            id: "RC003",
            name: "Vehicle Details Display",
            status: "Pending",
            duration: "-"
          }
        ]
      }
    ]
  };

  const handleExecute = () => {
    setIsRunning(true);
    setProgress(0);
    setExecutionLogs([
      {
        timestamp: new Date().toISOString(),
        type: "info",
        message: "Starting test execution..."
      }
    ]);

    // Simulate test execution progress
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          setIsRunning(false);
          return 100;
        }
        return prev + 10;
      });
    }, 1000);
  };

  const handleStop = () => {
    setIsRunning(false);
    setExecutionLogs((prev) => [
      ...prev,
      {
        timestamp: new Date().toISOString(),
        type: "warning",
        message: "Test execution stopped by user"
      }
    ]);
  };

  const getStatusBadge = (status) => {
    const variants = {
      Passed: {
        color: "text-green-500",
        icon: CheckCircle2,
        bg: "bg-green-500/10"
      },
      Failed: { color: "text-red-500", icon: XCircle, bg: "bg-red-500/10" },
      Running: {
        color: "text-blue-500",
        icon: RefreshCcw,
        bg: "bg-blue-500/10"
      },
      Pending: { color: "text-gray-500", icon: Clock, bg: "bg-gray-500/10" }
    };
    const config = variants[status] || variants.Pending;
    const Icon = config.icon;

    return (
      <div className={`flex items-center gap-2 px-2 py-1 rounded ${config.bg}`}>
        <Icon className={`h-4 w-4 ${config.color}`} />
        <span className={config.color}>{status}</span>
      </div>
    );
  };

  return (
    <div className="w-full p-6 space-y-6">
      {/* Header */}
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-2xl font-semibold">Test Execution</h1>
          <p className="text-sm text-muted-foreground mt-1">{testPlan.name}</p>
        </div>
        <div className="flex gap-2">
          {!isRunning ? (
            <Button onClick={handleExecute} className="gap-2">
              <PlayCircle className="h-4 w-4" />
              Execute Tests
            </Button>
          ) : (
            <Button
              onClick={handleStop}
              variant="destructive"
              className="gap-2"
            >
              <StopCircle className="h-4 w-4" />
              Stop Execution
            </Button>
          )}
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4">
        {/* Test Progress */}
        <Card className="col-span-2">
          <CardHeader>
            <div className="flex items-center justify-between">
              <CardTitle>Execution Progress</CardTitle>
              <Badge variant={isRunning ? "default" : "outline"}>
                {isRunning ? "Running" : "Ready"}
              </Badge>
            </div>
            <CardDescription>
              Device: {selectedDevice} | App: {testPlan.app.name} v
              {testPlan.app.version}
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-6">
            <div className="space-y-2">
              <div className="flex justify-between text-sm">
                <span>Progress</span>
                <span>{progress}%</span>
              </div>
              <Progress value={progress} className="h-2" />
            </div>

            <div className="space-y-6">
              {testPlan.testGroups.map((group, groupIndex) => (
                <div key={groupIndex}>
                  <h3 className="font-medium mb-3">{group.name}</h3>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead className="w-[100px]">ID</TableHead>
                        <TableHead>Test Case</TableHead>
                        <TableHead className="w-[150px]">Status</TableHead>
                        <TableHead className="w-[100px]">Duration</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {group.tests.map((test) => (
                        <TableRow key={test.id}>
                          <TableCell className="font-medium">
                            {test.id}
                          </TableCell>
                          <TableCell>{test.name}</TableCell>
                          <TableCell>{getStatusBadge(test.status)}</TableCell>
                          <TableCell>{test.duration}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>

        {/* Execution Logs */}
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <Terminal className="h-4 w-4" />
              Execution Logs
            </CardTitle>
          </CardHeader>
          <CardContent>
            <ScrollArea className="h-[500px] pr-4">
              <div className="space-y-2">
                {executionLogs.map((log, index) => (
                  <div
                    key={index}
                    className="text-sm border-l-2 border-muted pl-3 py-1"
                  >
                    <span className="text-muted-foreground">
                      {new Date(log.timestamp).toLocaleTimeString()}
                    </span>
                    <p
                      className={`mt-1 ${
                        log.type === "error"
                          ? "text-red-500"
                          : log.type === "warning"
                          ? "text-yellow-500"
                          : "text-foreground"
                      }`}
                    >
                      {log.message}
                    </p>
                  </div>
                ))}
              </div>
            </ScrollArea>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default TestPlanExecutionScreen;
