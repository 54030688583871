import React from "react";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { selectApis, selectGlobalVariables } from "@/store/features/apiSlice";
import { useAppSelector } from "@/store/hooks";
import { UseFormReturn } from "react-hook-form";
import { Plus, Trash2 } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
  FormField
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/components/ui/select";
import { Card } from "@/components/ui/card";
import { formSchema, FormValues, ApiItem } from "@/types";
import * as z from "zod";

const defaultBody = `{
  "type": "object",
  "required": ["email"],
  "properties": {
    "email": {
      "type": "string",
      "description": "The user's email address.",
      "example": "user@example.com"
    }
  }
}`;

const VariableAwareInput = ({
  value,
  onChange,
  multiline = false,
  className = "",
  ...props
}) => {
  const [cursorPosition, setCursorPosition] = React.useState(0);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setCursorPosition(e.target.selectionStart || 0);
    onChange(e);
  };

  const Component = multiline ? Textarea : Input;
  const combinedClassName = `bg-black ${className}`.trim();

  return (
    <div className="relative">
      <Component
        {...props}
        value={value || ""}
        onChange={handleChange}
        className={combinedClassName}
        autoComplete="on"
      />
    </div>
  );
};

export function ApiFormInputs({
  form,
  formFilled,
  initialData
}: {
  form: UseFormReturn<z.infer<typeof formSchema>>;
  formFilled: boolean;
  initialData?: ApiItem;
}) {
  const existingApis = useAppSelector(selectApis);
  const urlValue = form.watch("url");
  const headers = form.watch("headers") || {};
  const params = form.watch("params") || {};
  const bodyValue = form.watch("body");
  const body =
    typeof bodyValue === "string"
      ? bodyValue
      : bodyValue
      ? JSON.stringify(bodyValue, null, 2)
      : defaultBody;

  const showHeadersSection = formFilled || urlValue;

  React.useEffect(() => {
    if (initialData) {
      // Transform headers from API format to form format
      if (initialData.headers) {
        const formattedHeaders = Object.entries(initialData.headers).reduce(
          (acc, [key, value]: [string, any]) => {
            acc[key] = {
              key,
              description: value.description || "",
              default: value.example || value.default || ""
            };
            return acc;
          },
          {}
        );
        form.setValue("headers", formattedHeaders);
      }

      // Transform params to match header format
      if (initialData.params) {
        const formattedParams = Object.entries(initialData.params).reduce(
          (acc, [key, value]: [string, any]) => {
            if (value && typeof value === "object" && "key" in value) {
              acc[key] = {
                key: value.key,
                description: value.description || "",
                default: value.example || value.default || ""
              };
            } else {
              acc[key] = {
                key,
                description: "",
                default: value.toString()
              };
            }
            return acc;
          },
          {}
        );
        form.setValue("params", formattedParams);
      }

      // Transform body if it's not a string
      if (initialData.body && typeof initialData.body !== "string") {
        form.setValue("body", JSON.stringify(initialData.body, null, 2));
      }
    }
  }, [initialData, form]);

  const addHeader = () => {
    const tempId = Date.now().toString();
    const newHeaders = { ...headers };
    newHeaders[tempId] = {
      key: "",
      description: "",
      default: ""
    };
    form.setValue("headers", newHeaders);
  };

  const addParam = () => {
    const tempKey = Date.now().toString();
    const newParams = { ...params };
    newParams[tempKey] = {
      key: "",
      description: "",
      default: ""
    };
    form.setValue("params", newParams);
  };

  const removeHeader = (key: string) => {
    const newHeaders = { ...headers };
    delete newHeaders[key];
    form.setValue("headers", newHeaders);
  };

  const removeParam = (key: string) => {
    const newParams = { ...params };
    delete newParams[key];
    form.setValue("params", newParams);
  };

  return (
    <div className="space-y-6">
      {/* API Name */}
      <FormField
        control={form.control}
        name="name"
        rules={{
          validate: (value) => {
            if (initialData?.name === value) return true;
            const nameExists = existingApis.some((api) => api.name === value);
            return !nameExists || "An API with this name already exists";
          }
        }}
        render={({ field }) => (
          <FormItem>
            <FormLabel>API Name</FormLabel>
            <FormControl>
              <Input
                placeholder="enter_api_name"
                name="name"
                autoComplete="on"
                value={field.value}
                onChange={(e) => {
                  const value = e.target.value;
                  const snakeCaseValue = value
                    .toLowerCase()
                    .replace(/\s+/g, "_")
                    .replace(/[^a-z0-9_]/g, "");
                  field.onChange(snakeCaseValue);
                }}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      {/* Method & URL */}
      <div className="grid grid-cols-4 gap-4">
        <FormItem>
          <FormLabel>Method</FormLabel>
          <FormControl>
            <Select
              value={form.getValues("method")}
              onValueChange={(val) =>
                form.setValue("method", val as FormValues["method"])
              }
            >
              <SelectTrigger>
                <SelectValue placeholder="Select method" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="GET">GET</SelectItem>
                <SelectItem value="POST">POST</SelectItem>
                <SelectItem value="PUT">PUT</SelectItem>
                <SelectItem value="PATCH">PATCH</SelectItem>
                <SelectItem value="DELETE">DELETE</SelectItem>
              </SelectContent>
            </Select>
          </FormControl>
        </FormItem>

        <FormItem className="col-span-3">
          <FormLabel>URL</FormLabel>
          <FormControl>
            <VariableAwareInput
              placeholder="https://api.example.com"
              name="url"
              value={form.getValues("url")}
              onChange={(e) => form.setValue("url", e.target.value)}
              autoComplete="on"
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      </div>

      {showHeadersSection && (
        <>
          {/* Parameters */}
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <FormLabel>Parameters</FormLabel>
              <Button
                type="button"
                variant="outline"
                size="sm"
                onClick={addParam}
              >
                <Plus className="h-4 w-4 mr-2" />
                Add Parameter
              </Button>
            </div>

            <div className="space-y-4">
              {Object.entries(params).map(([key, param]) => (
                <Card key={key} className="p-3">
                  <div className="space-y-3">
                    <div className="grid grid-cols-2 gap-2">
                      <Input
                        placeholder="Key"
                        name={`params.${key}.key`}
                        value={param.key}
                        className="text-sm"
                        onChange={(e) => {
                          form.setValue(`params.${key}.key`, e.target.value);
                        }}
                        autoComplete="on"
                      />
                      <Input
                        placeholder="Value"
                        name={`params.${key}.default`}
                        value={param.default}
                        className="text-sm"
                        onChange={(e) => {
                          form.setValue(
                            `params.${key}.default`,
                            e.target.value
                          );
                        }}
                        autoComplete="on"
                      />
                    </div>

                    <Input
                      placeholder="Description"
                      name={`params.${key}.description`}
                      value={param.description}
                      className="text-sm"
                      onChange={(e) => {
                        form.setValue(
                          `params.${key}.description`,
                          e.target.value
                        );
                      }}
                      autoComplete="on"
                    />

                    <div className="flex justify-end">
                      <Button
                        type="button"
                        variant="ghost"
                        size="sm"
                        onClick={() => removeParam(key)}
                        className="h-6 w-6 p-0"
                      >
                        <Trash2 className="h-4 w-4 text-destructive" />
                      </Button>
                    </div>
                  </div>
                </Card>
              ))}
            </div>
          </div>

          {/* Headers */}
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <FormLabel>Headers</FormLabel>
              <Button
                type="button"
                variant="outline"
                size="sm"
                onClick={addHeader}
              >
                <Plus className="h-4 w-4 mr-2" />
                Add Header
              </Button>
            </div>

            <div className="space-y-4">
              {Object.entries(headers).map(([key, header]) => (
                <Card key={key} className="p-3">
                  <div className="space-y-3">
                    <div className="grid grid-cols-2 gap-2">
                      <Input
                        placeholder="Key"
                        name={`headers.${key}.key`}
                        value={header.key}
                        className="text-sm"
                        onChange={(e) => {
                          form.setValue(`headers.${key}.key`, e.target.value);
                        }}
                        autoComplete="on"
                      />
                      <Input
                        placeholder="Value"
                        name={`headers.${key}.default`}
                        value={header.default}
                        className="text-sm"
                        onChange={(e) => {
                          form.setValue(
                            `headers.${key}.default`,
                            e.target.value
                          );
                        }}
                        autoComplete="on"
                      />
                    </div>

                    <Input
                      placeholder="Description"
                      name={`headers.${key}.description`}
                      value={header.description}
                      className="text-sm"
                      onChange={(e) => {
                        form.setValue(
                          `headers.${key}.description`,
                          e.target.value
                        );
                      }}
                      autoComplete="on"
                    />

                    <div className="flex justify-end">
                      <Button
                        type="button"
                        variant="ghost"
                        size="sm"
                        onClick={() => removeHeader(key)}
                        className="h-6 w-6 p-0"
                      >
                        <Trash2 className="h-4 w-4 text-destructive" />
                      </Button>
                    </div>
                  </div>
                </Card>
              ))}
            </div>
          </div>

          {/* Body */}
          <FormField
            control={form.control}
            name="body"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Request Body (JSON)</FormLabel>
                <FormControl>
                  <VariableAwareInput
                    multiline={true}
                    placeholder={defaultBody}
                    className="font-mono min-h-[300px]"
                    value={body}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </>
      )}
    </div>
  );
}
