import React from "react";
import { Dialog, DialogContent } from "@/components/ui/custom-dailog";
import { Loader2, Terminal } from "lucide-react";

const LoadingDialog = ({ isOpen }: { isOpen: boolean }) => {
  const [step, setStep] = React.useState(0);
  const steps = [
    "Setting up emulator environment...",
    "Installing required dependencies...",
    "Configuring workspace...",
    "Finalizing setup..."
  ];

  React.useEffect(() => {
    if (isOpen) {
      const interval = setInterval(() => {
        setStep((prev) => (prev < steps.length - 1 ? prev + 1 : prev));
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [isOpen]);

  return (
    <Dialog open={isOpen} modal={true}>
      <DialogContent className="sm:max-w-md border-none bg-gradient-to-br from-gray-900 to-gray-800">
        <div className="flex flex-col items-center justify-center space-y-6 py-8">
          <div className="relative">
            <div className="absolute inset-0 animate-ping opacity-25">
              <Terminal className="w-12 h-12 text-blue-500" />
            </div>
            <Terminal className="w-12 h-12 text-blue-400" />
          </div>

          <div className="space-y-4 text-center">
            <h3 className="text-xl font-semibold text-gray-100">
              Preparing Single Line Mode
            </h3>
            <p className="text-sm text-gray-400">
              Please wait while we set up your environment
            </p>
          </div>

          <div className="w-full max-w-xs space-y-4">
            {steps.map((text, index) => (
              <div key={text} className="flex items-center space-x-3">
                {index <= step ? (
                  <div className="flex-shrink-0">
                    {index === step ? (
                      <Loader2 className="w-4 h-4 text-blue-400 animate-spin" />
                    ) : (
                      <div className="w-4 h-4 rounded-full bg-blue-500/20 border-2 border-blue-500" />
                    )}
                  </div>
                ) : (
                  <div className="w-4 h-4 rounded-full bg-gray-700" />
                )}
                <span
                  className={`text-sm ${
                    index <= step ? "text-gray-300" : "text-gray-500"
                  }`}
                >
                  {text}
                </span>
              </div>
            ))}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default LoadingDialog;
