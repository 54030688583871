import { useAuthenticatedApi } from "@/api/api";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "@/hooks/use-toast";
import { logger } from "@/utils/logger";

const GithubCallback = () => {
  const navigate = useNavigate();
  const api = useAuthenticatedApi();

  const handleCallback = async () => {
    try {
      // Get the code from URL
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");

      if (!code) {
        throw new Error("No code provided in callback URL");
      }

      // Let backend handle the OAuth exchange and user info fetch
      const authResponse = await api.get(`/github/callback?code=${code}`);

      if (!authResponse.access_token) {
        throw new Error("Failed to get access token");
      }

      // Connect GitHub to the user's account
      await api.post("/github/connect", {
        access_token: authResponse.access_token,
        username: authResponse.username,
        installation_id: null // Only needed if using GitHub Apps
      });

      // Show success message
      toast({
        title: "Success",
        description: "Successfully connected to GitHub"
      });

      // Redirect to project creation page
      navigate("/project/create");
    } catch (error) {
      logger.error("GitHub authentication failed:", error);

      // Show error message
      toast({
        title: "Error",
        description: "Failed to connect to GitHub. Please try again.",
        variant: "destructive"
      });

      // Redirect back to project creation page
      navigate("/project/create");
    }
  };

  useEffect(() => {
    handleCallback();
  }, []);

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="text-center">
        <h2 className="text-xl mb-4">Connecting to GitHub...</h2>
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 mx-auto"></div>
      </div>
    </div>
  );
};

export default GithubCallback;
