import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "@/components/ui/table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator
} from "@/components/ui/dropdown-menu";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Plus,
  MoreVertical,
  PlayCircle,
  Edit,
  Copy,
  Trash2
} from "lucide-react";
import { useAuthenticatedApi } from "@/api/api";
import { format } from "date-fns";

const TestPlanList = () => {
  const [testPlans, setTestPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const api = useAuthenticatedApi();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTestPlans = async () => {
      try {
        const response = await api.get("/testplans/org");
        setTestPlans(response);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchTestPlans();
  }, []);

  const getStatusBadgeVariant = (status) => {
    switch (status?.toLowerCase()) {
      case "active":
        return "default";
      case "draft":
        return "secondary";
      case "failed":
        return "destructive";
      default:
        return "outline";
    }
  };

  if (loading) {
    return (
      <div className="w-full p-6 pt-20 flex items-center justify-center">
        <div className="text-lg text-muted-foreground">
          Loading test plans...
        </div>
      </div>
    );
  }

  return (
    <div className="w-full p-6 pt-20">
      {/* Header */}
      <div className="flex items-center justify-between mb-6">
        <div>
          <h1 className="text-2xl font-semibold text-foreground">Test Plans</h1>
          <p className="text-sm text-muted-foreground mt-1">
            Manage and monitor your test execution plans
          </p>
        </div>
        <Link to="/testplan/new">
          <Button className="gap-2">
            <Plus className="h-4 w-4" />
            Create Test Plan
          </Button>
        </Link>
      </div>

      {/* Table */}
      <div className="border rounded-lg bg-card">
        <Table>
          <TableHeader>
            <TableRow className="hover:bg-muted/50">
              <TableHead className="font-medium">Name</TableHead>
              <TableHead className="font-medium">Created At</TableHead>
              <TableHead className="font-medium">Details</TableHead>
              <TableHead className="font-medium">Devices</TableHead>
              <TableHead className="w-[50px]"></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {testPlans.map((plan) => (
              <TableRow
                key={plan.test_plan_id}
                className="group hover:bg-muted/50 cursor-pointer"
                onClick={() => navigate(`/testplan/${plan.id}`)}
              >
                <TableCell>
                  <div className="space-y-1">
                    <div className="font-medium text-foreground">
                      {plan.name}
                    </div>
                  </div>
                </TableCell>
                <TableCell className="text-foreground">
                  {format(new Date(plan.created_at), "yyyy-MM-dd")}
                </TableCell>
                <TableCell>
                  <div className="text-sm text-foreground">
                    {plan.test_groups?.length || 0} Groups,{" "}
                    {plan.test_groups?.reduce(
                      (acc, group) => acc + (group.test_scripts?.length || 0),
                      0
                    )}{" "}
                    Test Cases
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex flex-wrap gap-1">
                    {plan.devices.map((device, index) => (
                      <Badge
                        key={index}
                        variant="outline"
                        className="text-xs font-medium bg-background"
                      >
                        {device.device_name}
                      </Badge>
                    ))}
                  </div>
                </TableCell>
                <TableCell>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button
                        variant="ghost"
                        size="icon"
                        className="opacity-0 group-hover:opacity-100 transition-opacity"
                      >
                        <MoreVertical className="h-4 w-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end" className="w-48">
                      <DropdownMenuItem
                        onClick={() =>
                          navigate(`/testplan/${plan.test_plan_id}`)
                        }
                        className="cursor-pointer"
                      >
                        <PlayCircle className="h-4 w-4 mr-2" />
                        Run Tests
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() =>
                          navigate(`/testplan/${plan.test_plan_id}/edit`)
                        }
                        className="cursor-pointer"
                      >
                        <Edit className="h-4 w-4 mr-2" />
                        Edit Plan
                      </DropdownMenuItem>
                      <DropdownMenuItem className="cursor-pointer">
                        <Copy className="h-4 w-4 mr-2" />
                        Duplicate
                      </DropdownMenuItem>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem className="text-destructive cursor-pointer">
                        <Trash2 className="h-4 w-4 mr-2" />
                        Delete Plan
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {testPlans.length === 0 && (
          <div className="flex flex-col items-center justify-center p-8 text-center">
            <div className="text-lg font-medium text-foreground">
              No test plans found
            </div>
            <p className="text-sm text-muted-foreground mt-1 mb-4">
              Create your first test plan to get started
            </p>
            <Link to="/testplan/new">
              <Button className="gap-2">
                <Plus className="h-4 w-4" />
                Create Test Plan
              </Button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default TestPlanList;
