import { useAuthenticatedApi } from "@/api/api";

export const useTestFile = () => {
  const api = useAuthenticatedApi();

  const getFolderTestFiles = async (
    folderId: number,
    includeDeleted: boolean = false
  ) => {
    return api.get(`/test-files/folder/${folderId}`);
  };

  const getTestFile = async (testFileId: number) => {
    return api.get(`/test-files/${testFileId}`);
  };

  const createTestFile = async (testFileData) => {
    return api.post("/test-files", testFileData);
  };

  const updateTestFile = async (testFileId, testFileData) => {
    return api.patch(`/test-files/${testFileId}`, testFileData);
  };

  const deleteTestFile = async (testFileId) => {
    return api.delete(`/test-files/${testFileId}`);
  };

  return {
    getFolderTestFiles,
    getTestFile,
    createTestFile,
    updateTestFile,
    deleteTestFile
  };
};
