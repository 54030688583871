import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Upload, ArrowLeft, Loader2 } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { ApiItem, ApiResponse, FormValues, formSchema } from "@/types";
import { useAppDispatch } from "@/store/hooks";
import {
  addApi,
  setApis,
  updateApi,
  setNeedsRefresh
} from "@/store/features/apiSlice";
import { ApiFormInputs } from "./ApiFormInputs";
import { useAuthenticatedApi } from "@/api/api";
import { logger } from "@/utils/logger";

interface ApiFormProps {
  initialData?: ApiItem;
  onSaved: () => void;
}

export function ApiForm({ initialData, onSaved }: ApiFormProps) {
  const dispatch = useAppDispatch();
  const authenticatedApi = useAuthenticatedApi();

  const [isLoading, setIsLoading] = React.useState(false);
  const [formFilled, setFormFilled] = React.useState(false);
  const [apiResponse, setApiResponse] = React.useState<ApiResponse | null>(
    null
  );
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  // Parse body if it's a string, otherwise use empty string
  const initialBody = initialData?.body
    ? typeof initialData.body === "string"
      ? initialData.body
      : JSON.stringify(initialData.body, null, 2)
    : "";

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: initialData?.name || "",
      method: initialData?.method || "GET",
      url: initialData?.url || "",
      headers: initialData?.headers || {},
      params: initialData?.params || {},
      body: initialBody
    },
    mode: "onChange" // Add this to ensure form updates on change
  });

  // Watch for changes in form fields
  const watchedFields = form.watch(["name", "url", "method"]);

  React.useEffect(() => {
    // Update formFilled based on watched fields
    setFormFilled(Boolean(watchedFields[0] && watchedFields[1]));
  }, [watchedFields]);

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      const fileContent = await file.text();
      const jsonData = JSON.parse(fileContent);

      if (!jsonData.method || !jsonData.url) {
        throw new Error(
          "Invalid JSON format: must contain method and url fields"
        );
      }

      // Use setValue instead of reset to ensure proper form updates
      Object.entries({
        name: form.getValues("name"),
        method: jsonData.method,
        url: jsonData.url,
        headers: jsonData.headers || {},
        params: jsonData.params || {},
        body:
          typeof jsonData.body === "string"
            ? jsonData.body
            : JSON.stringify(jsonData.body, null, 2)
      }).forEach(([key, value]) => {
        form.setValue(key as keyof FormValues, value, {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true
        });
      });

      setFormFilled(true);

      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } catch (error) {
      logger.error("Error parsing JSON file:", error);
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  const onSubmit = async (values: FormValues) => {
    try {
      setIsLoading(true);
      dispatch(setNeedsRefresh(true));

      // Format the headers and params correctly
      const formattedHeaders = Object.entries(values.headers).reduce(
        (acc, [_, value]) => {
          if (value.key) {
            acc[value.key] = {
              key: value.key,
              description: value.description,
              default: value.default
            };
          }
          return acc;
        },
        {}
      );

      const formattedParams = Object.entries(values.params).reduce(
        (acc, [_, value]) => {
          if (value.key) {
            acc[value.key] = {
              key: value.key,
              description: value.description,
              default: value.default
            };
          }
          return acc;
        },
        {}
      );

      // Try to parse body if it's not empty and is valid JSON
      let parsedBody = null;
      if (values.body) {
        try {
          parsedBody = JSON.parse(values.body);
        } catch (e) {
          parsedBody = values.body;
        }
      }

      const formData = {
        name: values.name,
        method: values.method,
        url: values.url,
        headers:
          Object.keys(formattedHeaders).length > 0 ? formattedHeaders : null,
        params:
          Object.keys(formattedParams).length > 0 ? formattedParams : null,
        body: parsedBody,
        responses: apiResponse
      };

      let savedApi;
      if (initialData) {
        // For PATCH, only include changed fields
        const changedData = Object.entries(formData).reduce(
          (acc, [key, value]) => {
            if (JSON.stringify(initialData[key]) !== JSON.stringify(value)) {
              acc[key] = value;
            }
            return acc;
          },
          {}
        );

        savedApi = await authenticatedApi.patch(
          `/apis/${initialData.id}`,
          changedData
        );
        dispatch(updateApi({ id: initialData.id, data: formData }));
      } else {
        savedApi = await authenticatedApi.post("/apis", formData);
        dispatch(addApi(savedApi));
      }

      const latestApis = await authenticatedApi.get("/apis");
      dispatch(setApis(latestApis));

      onSaved();
    } catch (error) {
      logger.error("Failed to save API:", error);
      form.setError("name", {
        type: "manual",
        message: "Error saving API. Please try again."
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <Button
          type="button"
          variant="outline"
          className="flex items-center gap-2 text-foreground"
          onClick={onSaved}
          disabled={isLoading}
        >
          <ArrowLeft className="h-4 w-4" />
          Back
        </Button>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileUpload}
          className="hidden"
        />
        <Button
          type="button"
          variant="outline"
          onClick={handleUploadClick}
          size="sm"
          disabled={isLoading}
        >
          <Upload className="h-4 w-4 mr-2" />
          Import JSON
        </Button>
      </div>

      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
          <ApiFormInputs
            form={form}
            formFilled={formFilled}
            initialData={initialData}
          />

          <div className="flex justify-end">
            <Button type="submit" disabled={!formFilled || isLoading}>
              {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
              {initialData ? "Update API" : "Save API"}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
}
