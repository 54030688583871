import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import {
  setConfig,
  setShowEditor,
  selectIsLoading,
  setIsLoading
} from "@/store/features/editorSlice";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Badge } from "@/components/ui/badge";
import {
  Loader2,
  AlertTriangle,
  Check,
  Server,
  Activity,
  Power,
  SmartphoneCharging,
  X,
  ChevronRight
} from "lucide-react";
import { cn } from "@/lib/utils";
import Header from "@/components/layout/Header";
import { useAuthenticatedApi } from "@/api/api";
import { API_BASE_URL } from "@/config";
import { logger } from "@/utils/logger";
import { getDeviceDisplayStatus } from "@/utils/getDisplayStatus";

// Keep all your original interfaces
interface AppInfo {
  package_name: string;
  app_name: string;
  app_id: string;
  firebase_project_id: number;
  id: number;
  org_id: number;
  created_at: string;
  updated_at: string;
}

interface EmulatorStatus {
  instance_name: string;
  emulator_status: string;
  test_status: string;
  provider: string | null;
  metadata: any | null;
}

interface EmulatorDevice {
  uuid: string;
  name: string;
  android_version: string;
  screen: string;
  hwprofile: {
    name: string;
  };
}

interface StartInstanceResponse {
  message: string;
  request_uuid: string;
  status: string;
  status_command: string;
}

interface TestConfig {
  instanceName: string;
  appName: any;
  saveInstance: boolean;
  logId: string;
  test_log_id: string;
}

interface AppVersion {
  name: string;
  displayVersion: string;
  buildVersion: string;
  createTime: string;
}

interface AppWithVersion extends AppInfo {
  selectedVersion?: AppVersion;
  versions?: AppVersion[];
  isLoadingVersions?: boolean;
}

export const StartPage = () => {
  // Keep all your original state definitions
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, isLoading: authLoading, user } = useAuth0();
  const isLoading = useAppSelector(selectIsLoading);
  const api = useAuthenticatedApi();

  const [error, setError] = useState<string>("");
  const [status, setStatus] = useState<EmulatorStatus | null>(null);
  const [existingInstance, setExistingInstance] = useState<boolean>(false);
  const [devices, setDevices] = useState<EmulatorDevice[]>([]);
  const [apps, setApps] = useState<AppWithVersion[]>([]);
  const [loadingVersions, setLoadingVersions] = useState<boolean>(false);
  const [selectedApps, setSelectedApps] = useState<AppWithVersion[]>([]);
  const [stoppingInstance, setStoppingInstance] = useState<boolean>(false);
  const [selectedDevice, setSelectedDevice] = useState<EmulatorDevice | null>(
    null
  );
  const [showManualApp, setShowManualApp] = useState<boolean>(false);
  const [manualAppName, setManualAppName] = useState<string>("");
  const [instances, setInstances] = useState<Record<string, any>>({});
  const [showManual, setShowManual] = useState<boolean>(false);
  const [devicesLoading, setDevicesLoading] = useState<boolean>(true);
  const [appsLoading, setAppsLoading] = useState<boolean>(true);

  const [config, setLocalConfig] = useState<TestConfig>({
    instanceName: "",
    appName: {},
    saveInstance: false,
    logId: "",
    test_log_id: ""
  });

  // Keep all your original useEffect hooks and handlers
  const queryParams = new URLSearchParams(location.search);
  const testcaseId = queryParams.get("testcase_id");
  const projectId = queryParams.get("project_id");

  useEffect(() => {
    const fetchApps = async () => {
      setAppsLoading(true);
      try {
        const data = await api.get("/apks/organization-apks");
        setApps(data);
      } catch (error) {
        console.error("Failed to fetch apps:", error);
        setError("Failed to fetch available apps. Please try again.");
      } finally {
        setAppsLoading(false);
      }
    };

    fetchApps();
  }, []);

  useEffect(() => {
    const fetchDevices = async () => {
      if (!user?.company_name) return;
      setDevicesLoading(true);

      try {
        const response = await fetch(
          `${API_BASE_URL}/emulators?org=${user.company_name}`,
          {
            headers: {
              "ngrok-skip-browser-warning": "1"
            }
          }
        );
        const data = await response.json();

        if (data.related_recipes) {
          setDevices(data.related_recipes);
        }
        if (data.recognised_instances?.instances) {
          setInstances(data.recognised_instances.instances);
        }
      } catch (error) {
        console.error("Failed to fetch devices:", error);
      } finally {
        setDevicesLoading(false);
      }
    };

    fetchDevices();
  }, [user?.company_name]);

  // Keep all your original handlers
  const getDeviceStatus = (deviceName: string) => {
    const instance = instances[deviceName];
    if (!instance) return null;
    return getDeviceDisplayStatus(instance.status, instance.test_status);
  };

  const fetchAppVersions = async (appId: number) => {
    try {
      const response = await api.get(`/apk-versions/${appId}/releases`);
      return response;
    } catch (error) {
      console.error(`Failed to fetch versions for app ${appId}:`, error);
      return [];
    }
  };

  const handleDeviceSelect = (device: EmulatorDevice) => {
    setSelectedDevice(device);
    setError("");
    setStatus(null);
    setExistingInstance(false);
  };

  const handleAppSelect = async (app: AppInfo) => {
    if (selectedApps.some((selected) => selected.id === app.id)) {
      setSelectedApps(
        selectedApps.filter((selected) => selected.id !== app.id)
      );
    } else {
      const appWithVersion: AppWithVersion = {
        ...app,
        isLoadingVersions: true
      };
      setSelectedApps([appWithVersion]);

      const versions = await fetchAppVersions(app.id);
      setSelectedApps([
        {
          ...app,
          versions,
          isLoadingVersions: false
        }
      ]);
    }
  };

  const handleVersionSelect = (appId: number, version: AppVersion) => {
    setSelectedApps((prevApps) =>
      prevApps.map((app) =>
        app.id === appId ? { ...app, selectedVersion: version } : app
      )
    );
  };

  const handleStopInstance = async (instanceName: string) => {
    try {
      setStoppingInstance(true);
      const formData = new FormData();
      formData.append("instance_name", instanceName);
      formData.append("caller_id", user?.email ? user.email.split("@")[0] : "");

      const response = await fetch(`${API_BASE_URL}/stop_instance/`, {
        method: "POST",
        headers: {
          "ngrok-skip-browser-warning": "1"
        },
        body: formData
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Failed to stop instance");
      }

      if (user?.company_name) {
        const refreshResponse = await fetch(
          `${API_BASE_URL}/emulators?org=${user.company_name}`,
          {
            headers: {
              "ngrok-skip-browser-warning": "1"
            }
          }
        );
        const data = await refreshResponse.json();
        if (data.related_recipes) {
          setDevices(data.related_recipes);
        }
        if (data.recognised_instances?.instances) {
          setInstances(data.recognised_instances.instances);
        }
      }

      setStatus(null);
      setExistingInstance(false);
      setError(
        "Instance stopped successfully. Please wait a few seconds before starting a new instance."
      );
    } catch (error) {
      console.error("Error stopping instance:", error);
      setError(error.message || "Failed to stop instance. Please try again.");
    } finally {
      setStoppingInstance(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setStatus(null);
    setExistingInstance(false);
    dispatch(setIsLoading(true));

    const instanceName = selectedDevice?.name || config.instanceName;

    if (
      (!showManualApp &&
        (selectedApps.length === 0 || !selectedApps[0].selectedVersion)) ||
      (showManualApp && !manualAppName.trim())
    ) {
      setError(
        showManualApp
          ? "Please enter app name"
          : "Please select an app and version"
      );
      dispatch(setIsLoading(false));
      return;
    }

    logger.log("Work");

    try {
      const statusResponse = await fetch(
        `${API_BASE_URL}/emulator-status/?instance_name=${instanceName}`,
        {
          headers: {
            "ngrok-skip-browser-warning": "1"
          }
        }
      );

      const data = await statusResponse.json();
      logger.log("Data", data);

      if (
        statusResponse.status === 404 ||
        (data && data.test_status === "waiting")
      ) {
        const appPackages = selectedApps.map((app) => ({
          app_package_name: app.package_name,
          app_name: app.app_name,
          version: app.selectedVersion?.displayVersion
        }));
        const formData = new FormData();
        formData.append("instance_name", instanceName);
        formData.append("org_id", user?.company_name || "");
        formData.append(
          "caller_id",
          user?.email ? user.email.split("@")[0] : ""
        );
        formData.append("app_packages", JSON.stringify(appPackages));

        if (selectedDevice) {
          formData.append("recipe_uuid", selectedDevice.uuid);
        }

        const startResponse = await fetch(`${API_BASE_URL}/start_instance/`, {
          method: "POST",
          headers: {
            "ngrok-skip-browser-warning": "1"
          },
          body: formData
        });

        if (!startResponse.ok) {
          const errorData = await startResponse.json();
          throw new Error(errorData.detail || "Failed to start instance");
        }

        const startData = await startResponse.json();
        console.log("Start Data", startData);
        const appConfig = showManualApp
          ? {
              manualAppName: manualAppName
            }
          : {
              selectedApps: selectedApps.map((app) => ({
                packageName: app.package_name,
                appName: app.app_name,
                appId: app.app_id,
                firebaseProjectId: app.firebase_project_id,
                version: app.selectedVersion?.displayVersion
              }))
            };

        dispatch(
          setConfig({
            instanceName: instanceName,
            logId: startData.request_uuid,
            appName: {
              ...appConfig,
              selectedDevice: selectedDevice
                ? {
                    name: instanceName,
                    uuid: selectedDevice.uuid,
                    androidVersion: selectedDevice.android_version,
                    screen: selectedDevice.screen,
                    hwprofile: selectedDevice.hwprofile
                  }
                : null,
              requestId: startData.request_uuid,
              status: startData.status,
              statusCommand: startData.status_command,
              orgId: user?.company_name || "",
              userId: user?.email ? user.email.split("@")[0] : ""
            },
            saveInstance: config.saveInstance
          })
        );

        dispatch(setShowEditor(true));

        const params = {
          ...(testcaseId && { testcase_id: testcaseId }),
          ...(projectId && { project_id: projectId }),
          log_id: startData.request_uuid
        };

        navigate({
          pathname: "/dashboard",
          search: new URLSearchParams(params).toString()
        });
      } else {
        if (data.detail) {
          setError(
            `${data.detail} Please try using a different instance name.`
          );
        } else {
          setStatus(data);
          setExistingInstance(true);
        }
      }
    } catch (error) {
      console.error("Error during initialization:", error);
      setError(
        "Unable to connect to the server. Please check your connection and try again."
      );
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  if (!isAuthenticated) return null;
  if (authLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
      </div>
    );
  }

  return (
    <>
      <Header isSidebarCollapsed={true} />
      <div className="flex items-center justify-center min-h-screen p-6">
        <Card className="w-full max-w-5xl  border-0 pt-20">
          <form onSubmit={handleSubmit}>
            <CardContent className="space-y-8">
              {error && (
                <Alert variant="destructive" className="border-red-500/20">
                  <AlertTriangle className="h-4 w-4" />
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              )}

              <div className="space-y-6">
                <div className="flex items-center justify-between">
                  <Label className="text-xl font-semibold">Select Device</Label>
                  <Button
                    type="button"
                    variant="ghost"
                    className="text-red-500 hover:text-red-600 hover:bg-red-500/10"
                    onClick={() => {
                      setShowManual(true);
                      setSelectedDevice(null);
                    }}
                  >
                    Enter device name manually
                  </Button>
                </div>

                {!showManual ? (
                  <div className="grid grid-cols-2 gap-4">
                    {devicesLoading ? (
                      <div className="col-span-2 flex items-center justify-center p-8 border rounded-xl bg-muted/10">
                        <div className="flex items-center gap-3 text-muted-foreground">
                          <Loader2 className="h-5 w-5 animate-spin" />
                          <span>Loading available devices...</span>
                        </div>
                      </div>
                    ) : (
                      devices.map((device) => {
                        const deviceStatus = getDeviceStatus(device.name);
                        const isSelected = selectedDevice?.uuid === device.uuid;
                        const isInUse =
                          deviceStatus?.label === "ONLINE_TESTING";
                        const isCurrentlyInUse =
                          status?.instance_name === device.name &&
                          existingInstance;

                        return (
                          <Card
                            key={device.uuid}
                            className={cn(
                              "transition-all duration-200",
                              isSelected && "border-primary shadow-lg",
                              isInUse && "opacity-50"
                            )}
                          >
                            <CardContent
                              className={cn(
                                "p-4 cursor-pointer",
                                isSelected && "bg-primary/5"
                              )}
                              onClick={() =>
                                !isInUse && handleDeviceSelect(device)
                              }
                            >
                              <div className="flex flex-col gap-3">
                                <div className="flex items-start justify-between">
                                  <div className="space-y-2">
                                    <div className="flex items-center gap-2">
                                      <span className="font-semibold">
                                        {device.name}
                                      </span>
                                      {deviceStatus && (
                                        <Badge
                                          variant={deviceStatus.variant}
                                          className="flex items-center gap-1"
                                        >
                                          <Activity className="h-3 w-3" />
                                          {deviceStatus.label}
                                        </Badge>
                                      )}
                                    </div>
                                    <div className="grid grid-cols-2 gap-2 text-sm text-muted-foreground">
                                      <div className="flex items-center gap-1">
                                        <SmartphoneCharging className="h-4 w-4" />
                                        {device.hwprofile.name}
                                      </div>
                                      <div>
                                        Android {device.android_version}
                                      </div>
                                      <div className="col-span-2">
                                        {device.screen}
                                      </div>
                                    </div>
                                  </div>
                                  {isSelected && (
                                    <div className="rounded-full bg-primary/10 p-2">
                                      <Check className="h-5 w-5 text-primary" />
                                    </div>
                                  )}
                                </div>

                                {isCurrentlyInUse && (
                                  <div className="flex items-center justify-between p-2 bg-yellow-500/10 rounded-lg border border-yellow-500/20">
                                    <span className="text-yellow-600 dark:text-yellow-500 text-sm">
                                      Currently in use
                                    </span>
                                    <Button
                                      type="button"
                                      variant="destructive"
                                      size="sm"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleStopInstance(device.name);
                                      }}
                                      disabled={stoppingInstance}
                                    >
                                      {stoppingInstance ? (
                                        <div className="flex items-center gap-2">
                                          <Loader2 className="h-3 w-3 animate-spin" />
                                          <span>Stopping...</span>
                                        </div>
                                      ) : (
                                        <>
                                          <Power className="h-3.5 w-3.5 mr-1" />
                                          Stop
                                        </>
                                      )}
                                    </Button>
                                  </div>
                                )}
                              </div>
                            </CardContent>
                          </Card>
                        );
                      })
                    )}
                  </div>
                ) : (
                  <div className="space-y-4">
                    <Input
                      placeholder="Enter device name"
                      value={config.instanceName}
                      onChange={(e) => {
                        setLocalConfig((prev) => ({
                          ...prev,
                          instanceName: e.target.value
                        }));
                        setError("");
                        setStatus(null);
                        setExistingInstance(false);
                      }}
                      className="bg-background text-lg"
                    />
                    <Button
                      type="button"
                      variant="ghost"
                      className="text-red-500 hover:text-red-600 hover:bg-red-500/10"
                      onClick={() => {
                        setShowManual(false);
                        setLocalConfig((prev) => ({
                          ...prev,
                          instanceName: ""
                        }));
                      }}
                    >
                      Select from available devices instead
                    </Button>
                  </div>
                )}
              </div>

              <div className="space-y-6">
                {!showManualApp ? (
                  <>
                    <div className="flex items-center justify-between">
                      <Label className="text-xl font-semibold">
                        Select App and Version
                      </Label>
                      <Button
                        type="button"
                        variant="ghost"
                        className="text-red-500 hover:text-red-600 hover:bg-red-500/10"
                        onClick={() => setShowManualApp(true)}
                      >
                        Enter app name manually
                      </Button>
                    </div>
                    {appsLoading ? (
                      <div className="flex items-center justify-center p-8 border rounded-xl bg-muted/10">
                        <div className="flex items-center gap-3 text-muted-foreground">
                          <Loader2 className="h-5 w-5 animate-spin" />
                          <span>Loading available apps...</span>
                        </div>
                      </div>
                    ) : (
                      <div className="grid grid-cols-2 gap-4">
                        {apps.map((app) => {
                          const isSelected = selectedApps.some(
                            (selected) => selected.id === app.id
                          );
                          const selectedApp = selectedApps.find(
                            (selected) => selected.id === app.id
                          );

                          return (
                            <Card
                              key={app.id}
                              className={cn(
                                "transition-all duration-200",
                                isSelected && "border-primary shadow-lg"
                              )}
                            >
                              <CardContent
                                className={cn(
                                  "p-4 cursor-pointer",
                                  isSelected && "bg-primary/5"
                                )}
                                onClick={() => handleAppSelect(app)}
                              >
                                <div className="space-y-4">
                                  <div className="flex items-center justify-between">
                                    <div className="space-y-1">
                                      <div className="font-semibold">
                                        {app.app_name}
                                      </div>
                                      <div className="text-sm text-muted-foreground">
                                        {app.package_name}
                                      </div>
                                    </div>
                                    {isSelected && (
                                      <div className="rounded-full bg-primary/10 p-2">
                                        <Check className="h-5 w-5 text-primary" />
                                      </div>
                                    )}
                                  </div>

                                  {isSelected && (
                                    <div className="space-y-3 pt-3 border-t">
                                      <Label className="text-sm text-muted-foreground">
                                        Version
                                      </Label>
                                      {selectedApp?.isLoadingVersions ? (
                                        <div className="flex items-center gap-2 text-sm text-muted-foreground">
                                          <Loader2 className="h-4 w-4 animate-spin" />
                                          <span>Loading versions...</span>
                                        </div>
                                      ) : selectedApp?.versions?.length ? (
                                        <div className="grid gap-2">
                                          {selectedApp.versions.map(
                                            (version) => (
                                              <div
                                                key={version.name}
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  handleVersionSelect(
                                                    app.id,
                                                    version
                                                  );
                                                }}
                                                className={cn(
                                                  "p-2 rounded-md border cursor-pointer",
                                                  "hover:border-primary/30",
                                                  selectedApp.selectedVersion
                                                    ?.name === version.name
                                                    ? "border-primary/50 bg-primary/5"
                                                    : "border-input"
                                                )}
                                              >
                                                <div className="flex items-center justify-between">
                                                  <div>
                                                    <div className="font-medium">
                                                      v{version.displayVersion}
                                                    </div>
                                                    <div className="text-xs text-muted-foreground">
                                                      Build{" "}
                                                      {version.buildVersion}
                                                    </div>
                                                  </div>
                                                  {selectedApp.selectedVersion
                                                    ?.name === version.name && (
                                                    <Check className="h-4 w-4 text-primary" />
                                                  )}
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      ) : (
                                        <div className="text-sm text-muted-foreground">
                                          No versions available
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </CardContent>
                            </Card>
                          );
                        })}
                      </div>
                    )}
                  </>
                ) : (
                  <div className="space-y-4">
                    <div className="flex items-center justify-between">
                      <Label className="text-xl font-semibold">
                        Enter App Name
                      </Label>
                      <Button
                        type="button"
                        variant="ghost"
                        className="text-red-500 hover:text-red-600 hover:bg-red-500/10"
                        onClick={() => {
                          setShowManualApp(false);
                          setManualAppName("");
                        }}
                      >
                        Select from available apps instead
                      </Button>
                    </div>
                    <Input
                      value={manualAppName}
                      onChange={(e) => setManualAppName(e.target.value)}
                      placeholder="Enter app name"
                      className="bg-background text-lg"
                    />
                  </div>
                )}
              </div>
            </CardContent>

            <CardFooter className="px-6 pb-6">
              <Button
                type="submit"
                className="w-full h-12 text-lg font-medium"
                disabled={
                  (!selectedDevice && !config.instanceName.trim()) ||
                  (!showManualApp &&
                    (selectedApps.length === 0 ||
                      !selectedApps[0].selectedVersion)) ||
                  (showManualApp && !manualAppName.trim()) ||
                  isLoading ||
                  stoppingInstance
                }
              >
                {isLoading ? (
                  <div className="flex items-center justify-center gap-2">
                    <Loader2 className="h-5 w-5 animate-spin" />
                    <span>Processing...</span>
                  </div>
                ) : (
                  <>
                    <span>Start Session</span>
                    <ChevronRight className="h-5 w-5 ml-2" />
                  </>
                )}
              </Button>
            </CardFooter>
          </form>
        </Card>
      </div>
    </>
  );
};

export default StartPage;
