import React from "react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Globe, Plus, Trash2, Loader2 } from "lucide-react";
import { Card } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { ApiItem } from "@/types";
import {
  selectApis,
  selectExecutingApiId,
  deleteApi,
  setExecutingApiId,
  setApis
} from "@/store/features/apiSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { useState } from "react";
import { useAuthenticatedApi } from "@/api/api";
import { logger } from "@/utils/logger";

interface ApiListProps {
  onSelect: (api: ApiItem | null) => void;
  onAddNew: () => void;
  isLoading: boolean;
}

export function ApiList({ onSelect, onAddNew, isLoading }: ApiListProps) {
  const dispatch = useAppDispatch();
  const apis = useAppSelector(selectApis);
  const executingId = useAppSelector(selectExecutingApiId);
  const [localLoading, setLocalLoading] = useState(false);
  const authenticatedApi = useAuthenticatedApi();

  const handleDelete = async (id: string) => {
    setLocalLoading(true);
    try {
      await authenticatedApi.delete(`/apis/${id}`);
      const fetchedApis = await authenticatedApi.get("/apis");
      dispatch(setApis(fetchedApis));
    } catch (error) {
      logger.error("Failed to delete API:", error);
    } finally {
      setLocalLoading(false);
    }
  };

  const handleExecute = async (api: ApiItem) => {
    dispatch(setExecutingApiId(api.id));
    try {
      await authenticatedApi.post("/apis/execute", api);
    } finally {
      dispatch(setExecutingApiId(null));
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex gap-2">
        <Button
          onClick={onAddNew}
          className="flex-1"
          variant="outline"
          disabled={isLoading || localLoading}
        >
          <Plus className="h-4 w-4 mr-2" />
          Add New API
        </Button>
      </div>

      {isLoading || localLoading ? (
        <div className="flex items-center justify-center p-3">
          <Loader2 className="h-6 w-6 animate-spin text-primary/70" />
        </div>
      ) : apis.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-[400px] border-2 border-white border-dashed rounded-lg">
          <Globe className="h-12 w-12 text-muted-foreground mb-4" />
          <h3 className="text-lg font-medium">No APIs configured</h3>
          <p className="text-sm text-muted-foreground mt-1">
            Get started by creating your first API configuration.
          </p>
        </div>
      ) : (
        <ScrollArea className="h-[calc(100vh-280px)]">
          <div className="space-y-4 pr-4">
            {apis.map((api) => (
              <ApiCard
                key={api.id}
                api={api}
                isExecuting={executingId === api.id}
                onSelect={() => onSelect(api)}
                onDelete={() => handleDelete(api.id)}
                onExecute={() => handleExecute(api)}
              />
            ))}
          </div>
        </ScrollArea>
      )}
    </div>
  );
}

interface ApiCardProps {
  api: ApiItem;
  isExecuting: boolean;
  onSelect: () => void;
  onDelete: () => void;
  onExecute: () => void;
}

function ApiCard({
  api,
  isExecuting,
  onSelect,
  onDelete,
  onExecute
}: ApiCardProps) {
  const getMethodColor = (method: string) => {
    const colors = {
      GET: "bg-emerald-500/10 text-emerald-500 hover:bg-emerald-500/20",
      POST: "bg-blue-500/10 text-blue-500 hover:bg-blue-500/20",
      PUT: "bg-yellow-500/10 text-yellow-500 hover:bg-yellow-500/20",
      DELETE: "bg-red-500/10 text-red-500 hover:bg-red-500/20",
      PATCH: "bg-purple-500/10 text-purple-500 hover:bg-purple-500/20"
    } as const;

    return colors[method as keyof typeof colors] || colors.GET;
  };

  return (
    <Card
      className="transition-all border-gray-500 duration-200 cursor-pointer hover:shadow-md"
      onClick={onSelect}
    >
      <div className="p-4">
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center gap-2">
            <Badge variant="secondary" className={getMethodColor(api.method)}>
              {api.method}
            </Badge>
            <h3 className="font-medium">{api.name}</h3>
          </div>
          <Button
            variant="ghost"
            size="icon"
            className="text-destructive text-red-500 hover:text-destructive"
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
          >
            <Trash2 className="h-4 w-4" />
          </Button>
        </div>
      </div>
    </Card>
  );
}

export default ApiList;
