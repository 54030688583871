import React from "react";
import { useAppSelector } from "@/store/hooks";
import { selectSingleModeLoading } from "@/store/features/editorSlice";
import { EditorToolbarProps } from "@/types";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "@/components/ui/tooltip";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Upload,
  Play,
  Square,
  Trash2,
  Download,
  X,
  Terminal,
  Save,
  File,
  Github
} from "lucide-react";
import { cn } from "@/lib/utils";
import { Separator } from "@/components/ui/separator";

interface ExtendedEditorToolbarProps extends EditorToolbarProps {
  onSaveCode?: () => void;
  onNewFile?: () => void;
  onSaveAs?: () => void;
  showGithubSync?: boolean;
  onGitHubSync?: () => Promise<void>;
}

export const EditorToolbar: React.FC<ExtendedEditorToolbarProps> = ({
  onRun,
  onStop,
  onClear,
  onFileUpload,
  isRunning,
  canStop,
  saveInstance,
  onSaveInstanceChange,
  isSingleLineMode,
  isLoading,
  onCloseInstance,
  onDownloadCode,
  onSaveCode,
  testcaseId,
  onNewFile,
  showGithubSync,
  onGitHubSync,
  onSaveAs,
  isStopping,
  isInCooldown
}) => {
  const isStopEnabled = canStop && isRunning;
  const isDisabled = isRunning || isLoading;
  const singleModeLoading = useAppSelector(selectSingleModeLoading);
  const buttonText = testcaseId ? "Update" : "Save";
  const tooltipText = testcaseId ? "Update test case" : "Save as new test case";

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (!file.name.endsWith(".txt")) {
      alert("Please upload only text files (.txt)");
      event.target.value = "";
      return;
    }

    const reader = new FileReader();
    reader.onload = function (e) {
      const content = e.target?.result as string;
      if (content) {
        onFileUpload(content);
      }
      event.target.value = "";
    };

    reader.onerror = function (error) {
      console.error("Error reading file:", error);
      alert("Error reading file");
      event.target.value = "";
    };

    reader.readAsText(file);
  };

  if (isSingleLineMode) {
    return (
      <div className="border-b border-border pt-12">
        <TooltipProvider>
          <div className="flex items-center gap-2 p-2">
            <div className="flex items-center gap-1 bg-gray-800 px-2 py-1 rounded-md">
              <Terminal className="h-4 w-4" />
              <span className="text-xs">Single Line Mode</span>
            </div>
            <div className="flex-1" />
            <div className="flex items-center gap-2">
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={onDownloadCode}
                    disabled={isLoading}
                    className="h-7 px-2 bg-gray-800 hover:bg-gray-700"
                  >
                    <Download className="h-4 w-4 mr-1" />
                    <span className="text-xs">Download</span>
                  </Button>
                </TooltipTrigger>
                <TooltipContent>Download current script</TooltipContent>
              </Tooltip>

              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={onCloseInstance}
                    disabled={isLoading}
                    className="h-7 px-2 bg-gray-800 hover:bg-gray-700"
                  >
                    <X className="h-4 w-4 mr-1" />
                    <span className="text-xs">Stop Single Line Mode</span>
                  </Button>
                </TooltipTrigger>
                <TooltipContent>Close current instance</TooltipContent>
              </Tooltip>
            </div>
          </div>
        </TooltipProvider>
      </div>
    );
  }

  return (
    <div className="border-b border-border pt-12">
      <TooltipProvider>
        <div className="relative">
          <div className="flex items-center gap-4 p-2">
            {/* Left Group - Run Controls */}
            <div className="flex items-center gap-2">
              <Button
                variant="secondary"
                size="sm"
                onClick={onRun}
                disabled={isDisabled || isInCooldown}
                className={cn(
                  "h-7 px-2 bg-emerald-500/10 text-emerald-400 hover:bg-emerald-500/20",
                  isDisabled && "opacity-50"
                )}
              >
                <Play className="h-4 w-4 mr-1" />
                <span className="text-xs">Run</span>
              </Button>

              <div className="flex items-center gap-2 px-2 py-1 bg-gray-800 rounded-md">
                <Checkbox
                  id="checkpoint"
                  checked={saveInstance}
                  onCheckedChange={(checked) => {
                    if (typeof checked === "boolean") {
                      onSaveInstanceChange(checked);
                    }
                  }}
                  disabled={isDisabled}
                  className="data-[state=checked]:bg-blue-500 data-[state=checked]:border-blue-500"
                />
                <label
                  htmlFor="checkpoint"
                  className="text-xs cursor-pointer select-none"
                >
                  Save Instance
                </label>
              </div>

              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={onStop}
                    disabled={!isStopEnabled || isStopping}
                    className={cn(
                      "h-7 px-2 bg-red-500/10 text-red-400 hover:bg-red-500/20",
                      (!isStopEnabled || isStopping) && "opacity-50"
                    )}
                  >
                    {isStopping ? (
                      <>
                        <span className="h-4 w-4 mr-1 animate-spin rounded-full border-2 border-current border-t-transparent" />
                        <span className="text-xs">Stopping</span>
                      </>
                    ) : (
                      <>
                        <Square className="h-4 w-4 mr-1" />
                        <span className="text-xs">Stop</span>
                      </>
                    )}
                  </Button>
                </TooltipTrigger>
                <TooltipContent>Stop execution</TooltipContent>
              </Tooltip>

              {/* <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={onStop}
                    disabled={!isStopEnabled}
                    className={cn(
                      "h-7 px-2 bg-red-500/10 text-red-400 hover:bg-red-500/20",
                      !isStopEnabled && "opacity-50"
                    )}
                  >
                    <Square className="h-4 w-4 mr-1" />
                    <span className="text-xs">Stop</span>
                  </Button>
                </TooltipTrigger>
                <TooltipContent>Stop execution</TooltipContent>
              </Tooltip> */}
            </div>

            <Separator orientation="vertical" className="h-8" />

            {/* Middle Group - File Operations */}
            <div className="flex items-center gap-2">
              <div className="relative">
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="secondary"
                      size="sm"
                      disabled={isDisabled}
                      className={cn(
                        "h-7 px-2 bg-gray-800 hover:bg-gray-700",
                        isDisabled && "opacity-50"
                      )}
                    >
                      <Upload className="h-4 w-4 mr-1" />
                      <span className="text-xs">Upload</span>
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>Upload text file</TooltipContent>
                </Tooltip>
                <input
                  type="file"
                  accept=".txt,.text"
                  onChange={handleFileUpload}
                  disabled={isDisabled}
                  className="absolute inset-0 w-full h-full opacity-0 cursor-pointer disabled:cursor-not-allowed"
                />
              </div>

              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={onSaveCode}
                    disabled={isDisabled}
                    className={cn(
                      "h-7 px-2 bg-blue-500/10 text-blue-400 hover:bg-blue-500/20",
                      isDisabled && "opacity-50"
                    )}
                  >
                    <Save className="h-4 w-4 mr-1" />
                    <span className="text-xs">{buttonText}</span>
                  </Button>
                </TooltipTrigger>
                <TooltipContent>{tooltipText}</TooltipContent>
              </Tooltip>

              {testcaseId && (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="secondary"
                      size="sm"
                      onClick={onSaveAs}
                      disabled={isDisabled}
                      className={cn(
                        "h-7 px-2 bg-blue-500/10 text-blue-400 hover:bg-blue-500/20",
                        isDisabled && "opacity-50"
                      )}
                    >
                      <Save className="h-4 w-4 mr-1" />
                      <span className="text-xs">Save As</span>
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>Save as new test case</TooltipContent>
                </Tooltip>
              )}

              {showGithubSync && (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="secondary"
                      size="sm"
                      onClick={onGitHubSync}
                      disabled={isDisabled}
                      className={cn(
                        "h-7 px-2 bg-gray-800 hover:bg-gray-700",
                        isDisabled && "opacity-50"
                      )}
                    >
                      <Github className="h-4 w-4 mr-1" />
                      <span className="text-xs">Sync GitHub</span>
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>Sync with GitHub</TooltipContent>
                </Tooltip>
              )}
            </div>

            <div className="flex-1" />

            {/* Right Group - Instance Management */}
            <div className="flex items-center gap-2">
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={onClear}
                    className="h-7 px-2 bg-gray-800 hover:bg-gray-700"
                  >
                    <Trash2 className="h-4 w-4 mr-1" />
                    <span className="text-xs">Change Instance</span>
                  </Button>
                </TooltipTrigger>
                <TooltipContent>Clear current instance</TooltipContent>
              </Tooltip>
            </div>
          </div>
        </div>
      </TooltipProvider>
    </div>
  );
};

export default EditorToolbar;
