import { logger } from "@/utils/logger";
import { useAuth0 } from "@auth0/auth0-react";

const BASE_URL = import.meta.env.VITE_API_URL;

export const useAuthenticatedApi = () => {
  const { getAccessTokenSilently } = useAuth0();

  const get = async (endpoint: string) => {
    try {
      const token = await getAccessTokenSilently();
      logger.log("Token in React:", token);
      const response = await fetch(`${BASE_URL}${endpoint}`, {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      return response.json();
    } catch (error) {
      logger.error("GET Request failed:", error);
      throw error;
    }
  };

  const post = async (endpoint: string, data: any) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${BASE_URL}${endpoint}`, {
        method: "POST",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      return response.json();
    } catch (error) {
      logger.error("POST Request failed:", error);
      throw error;
    }
  };

  const put = async (endpoint: string, data: any) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${BASE_URL}${endpoint}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      return response.json();
    } catch (error) {
      logger.error("PUT Request failed:", error);
      throw error;
    }
  };

  const patch = async (endpoint: string, data: any) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${BASE_URL}${endpoint}`, {
        method: "PATCH",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      return response.json();
    } catch (error) {
      logger.error("PATCH Request failed:", error);
      throw error;
    }
  };

  const delete_ = async (endpoint: string) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${BASE_URL}${endpoint}`, {
        method: "DELETE",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      return response.json();
    } catch (error) {
      logger.error("DELETE Request failed:", error);
      throw error;
    }
  };

  return {
    get,
    post,
    put,
    patch,
    delete: delete_
  };
};
