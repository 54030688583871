import React from "react";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator
} from "@/components/ui/dropdown-menu";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { LogOut } from "lucide-react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

const Header = ({ config, testState }) => {
  const { user, logout } = useAuth0();
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate("/start");
  };

  const getThreadCode = () => {
    if (testState?.stopThreadUrl) {
      return testState.stopThreadUrl.slice(-5);
    }
    return "";
  };

  const displayName = testState
    ? `${config.instanceName} - ${getThreadCode()}`
    : config.instanceName;

  return (
    <header className="h-12 border-b border-border bg-background/80 backdrop-blur-xl fixed top-0 right-0 left-0 z-20">
      <div className="h-full px-4 flex items-center justify-between mx-auto">
        <div
          className="cursor-pointer hover:opacity-80 transition-opacity"
          onClick={handleLogoClick}
        >
          <span className="font-bold text-lg text-foreground">Drizz</span>
        </div>

        <div className="absolute left-1/2 transform -translate-x-1/2">
          <span className="text-xs text-muted-foreground">
            {testState
              ? `${config.instanceName}${
                  getThreadCode() ? ` ${getThreadCode()}` : ""
                }`
              : config.instanceName}
          </span>
        </div>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 rounded-full p-0">
              <Avatar className="h-7 w-7">
                <AvatarImage src={user?.picture} alt={user?.name} />
                <AvatarFallback className="text-xs">
                  {user?.name?.slice(0, 2).toUpperCase()}
                </AvatarFallback>
              </Avatar>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" className="w-52">
            <div className="flex flex-col px-2 py-1.5">
              <span className="text-sm font-medium truncate">{user?.name}</span>
              <span className="text-xs text-muted-foreground truncate">
                {user?.email}
              </span>
            </div>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              className="text-destructive"
              onClick={() =>
                logout({
                  logoutParams: { returnTo: window.location.origin }
                })
              }
            >
              <LogOut className="w-4 h-4 mr-2" />
              <span className="text-sm">Log out</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </header>
  );
};

export default Header;
