import React, { useState, useEffect, useCallback } from "react";

const ResizeHandle = ({ onResize }) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleMouseMove = useCallback(
    (e) => {
      if (isDragging) {
        // Get container's bottom position
        const container = document.getElementById("editor-body");
        const containerRect = container.getBoundingClientRect();
        const containerBottom = containerRect.bottom;

        // Calculate new height based on mouse position
        const newHeight = Math.max(
          200,
          Math.min(600, containerBottom - e.clientY)
        );
        onResize(newHeight);
      }
    },
    [isDragging, onResize]
  );

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, handleMouseMove]);

  return (
    <div
      className="resize-handle cursor-row-resize h-1 bg-gray-800 hover:bg-blue-500 transition-colors"
      onMouseDown={handleMouseDown}
    />
  );
};

const ResizableLogger = ({ children, initialHeight = 300 }) => {
  const [height, setHeight] = useState(initialHeight);

  const handleResize = useCallback((newHeight) => {
    setHeight(newHeight);
  }, []);

  return (
    <div className="relative" style={{ height }}>
      <ResizeHandle onResize={handleResize} />
      <div className="h-full overflow-hidden">{children}</div>
    </div>
  );
};

export default ResizableLogger;
