import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";
import { useEffect } from "react";

export const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, isLoading, logout, getAccessTokenSilently } =
    useAuth0();

  useEffect(() => {
    const checkToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        const { exp } = JSON.parse(atob(token.split(".")[1]));
        if (Date.now() >= exp * 1000) {
          logout({ logoutParams: { returnTo: window.location.origin } });
        }
      } catch (error) {
        logout({ logoutParams: { returnTo: window.location.origin } });
      }
    };

    if (isAuthenticated) {
      checkToken();
    }
  }, [isAuthenticated, logout, getAccessTokenSilently]);

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

// import { useAuth0 } from "@auth0/auth0-react";
// const { getAccessTokenSilently } = useAuth0();
// const token = await getAccessTokenSilently();

// useEffect(() => {
//   const refreshAccessToken = async () => {
//     try {
//       // This will automatically use the refresh token if the access token is expired
//       const token = await getAccessTokenSilently({
//         authorizationParams: {
//           audience: "https://auth.drizz.dev",
//           scope: "openid profile email "
//         }
//       });
//       console.log("Access Token Refreshed:", token);
//     } catch (err) {
//       console.error("Failed to refresh token:", err);
//     }
//   };

//   if (isAuthenticated) {
//     refreshAccessToken();
//   }
// }, [isAuthenticated, getAccessTokenSilently]);
