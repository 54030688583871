import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Badge } from "@/components/ui/badge";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from "@/components/ui/dialog";
import {
  Settings,
  ArrowLeft,
  Save,
  Globe,
  AlertTriangle,
  Trash2,
  Loader2
} from "lucide-react";
import { toast } from "@/hooks/use-toast";
import { useProjects } from "../../hooks/useProject";
import { cn } from "@/lib/utils";
import GithubSettings from "../../components/GithubSetting";

const ProjectSettings = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { getProject, updateProject, deleteProject } = useProjects();

  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  // Form state
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [hasChanges, setHasChanges] = useState(false);

  // Fetch project details
  useEffect(() => {
    const fetchProject = async () => {
      try {
        if (!projectId) return;
        const data = await getProject(parseInt(projectId));
        setProject(data);
        setName(data.name);
        setDescription(data.description || "");
      } catch (error) {
        toast({
          title: "Error",
          description: "Failed to fetch project details",
          variant: "destructive"
        });
      } finally {
        setLoading(false);
      }
    };

    fetchProject();
  }, [projectId]);

  // Track form changes
  useEffect(() => {
    if (!project) return;
    const hasFormChanges =
      name !== project.name ||
      (description || "") !== (project.description || "");
    setHasChanges(hasFormChanges);
  }, [name, description, project]);

  const handleSave = async () => {
    if (!project || !hasChanges) return;

    setSaving(true);
    try {
      const updated = await updateProject(project.id, {
        name,
        description: description || undefined
      });

      setProject(updated);
      setHasChanges(false);

      toast({
        title: "Success",
        description: "Project settings updated successfully"
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update project settings",
        variant: "destructive"
      });
    } finally {
      setSaving(false);
    }
  };

  const handleDelete = async () => {
    if (!project) return;

    setDeleting(true);
    try {
      await deleteProject(project.id);
      toast({
        title: "Success",
        description: "Project deleted successfully"
      });
      navigate("/project");
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to delete project",
        variant: "destructive"
      });
    } finally {
      setDeleting(false);
      setShowDeleteDialog(false);
    }
  };

  if (loading) {
    return (
      <div className="h-full flex items-center justify-center">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
      </div>
    );
  }

  if (!project) {
    return (
      <div className="h-full flex items-center justify-center">
        <p className="text-muted-foreground">Project not found</p>
      </div>
    );
  }

  return (
    <div className="h-full bg-background">
      <div className="p-8 max-w-6xl mx-auto">
        {/* Header */}
        <div className="flex items-center gap-4 mb-8">
          <Link to="/project">
            <Button
              variant="ghost"
              size="icon"
              className="hover:bg-accent hover:text-accent-foreground"
            >
              <ArrowLeft className="h-5 w-5" />
            </Button>
          </Link>
          <div className="flex-1">
            <div className="flex items-center gap-2 mb-1">
              <Settings className="h-5 w-5 text-primary" />
              <h1 className="text-2xl font-semibold tracking-tight text-foreground">
                Project Settings
              </h1>
            </div>
            <div className="flex items-center gap-2 text-muted-foreground text-sm">
              <span>Project:</span>
              <Badge variant="secondary" className="font-medium">
                {project.name}
              </Badge>
            </div>
          </div>
          <Button
            className="gap-2"
            onClick={handleSave}
            disabled={!hasChanges || saving}
          >
            {saving ? (
              <Loader2 className="h-4 w-4 animate-spin" />
            ) : (
              <Save className="h-4 w-4" />
            )}
            Save Changes
          </Button>
        </div>

        <div className="space-y-6">
          {/* General Settings */}
          <Card className="border-border">
            <CardHeader>
              <CardTitle className="flex items-center gap-2 text-lg text-card-foreground">
                <Globe className="h-5 w-5 text-primary" />
                General Settings
              </CardTitle>
              <CardDescription>
                Basic project configuration and details
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-6">
              <div className="grid gap-6">
                <div className="space-y-2">
                  <Label className="text-foreground">Project Name</Label>
                  <Input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className={cn(
                      "bg-background text-foreground",
                      "border-input",
                      "focus-visible:ring-ring focus-visible:ring-2",
                      "placeholder:text-muted-foreground"
                    )}
                  />
                </div>
                <div className="space-y-2">
                  <Label className="text-foreground">Description</Label>
                  <Textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className={cn(
                      "resize-none",
                      "bg-background text-foreground",
                      "border-input",
                      "focus-visible:ring-ring focus-visible:ring-2",
                      "placeholder:text-muted-foreground"
                    )}
                    rows={3}
                  />
                </div>
                <div className="space-y-2">
                  <Label className="text-foreground">Project ID</Label>
                  <div className="flex gap-2 items-center">
                    <Input
                      value={project.id}
                      disabled
                      className="font-mono bg-muted text-muted-foreground"
                    />
                    <Badge variant="secondary">Read-only</Badge>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>

          <GithubSettings
            project={project}
            onProjectUpdate={(updatedProject) => {
              setProject(updatedProject);
              setHasChanges(false);
            }}
          />

          {/* Danger Zone */}
          <Card className="border-destructive/50">
            <CardHeader>
              <CardTitle className="flex items-center gap-2 text-lg text-destructive">
                <AlertTriangle className="h-5 w-5" />
                Danger Zone
              </CardTitle>
              <CardDescription>
                Irreversible project actions that should be taken with caution
              </CardDescription>
            </CardHeader>
            <CardContent>
              <div className="flex items-center justify-between p-4 rounded-lg border border-destructive/50 bg-destructive/5">
                <div>
                  <h3 className="font-medium text-foreground mb-1">
                    Delete Project
                  </h3>
                  <p className="text-sm text-muted-foreground">
                    Permanently remove this project and all its data
                  </p>
                </div>
                <Dialog
                  open={showDeleteDialog}
                  onOpenChange={setShowDeleteDialog}
                >
                  <DialogTrigger asChild>
                    <Button variant="destructive" className="gap-2">
                      <Trash2 className="h-4 w-4" />
                      Delete Project
                    </Button>
                  </DialogTrigger>
                  <DialogContent className="bg-background border-border">
                    <DialogHeader>
                      <DialogTitle className="flex items-center gap-2 text-destructive">
                        <AlertTriangle className="h-5 w-5" />
                        Delete Project
                      </DialogTitle>
                      <DialogDescription className="text-muted-foreground">
                        This action cannot be undone. This will permanently
                        delete the project "{project.name}" and all its
                        associated data.
                      </DialogDescription>
                    </DialogHeader>
                    <div className="p-4 rounded-md bg-destructive/5 border border-destructive/50 text-sm">
                      <p className="font-medium mb-2 text-foreground">
                        This will delete:
                      </p>
                      <ul className="list-disc list-inside space-y-1 text-muted-foreground">
                        <li>All test cases and test suites</li>
                        <li>Test execution history</li>
                        <li>Project configurations</li>
                        <li>GitHub integration settings</li>
                      </ul>
                    </div>
                    <DialogFooter>
                      <Button
                        variant="outline"
                        onClick={() => setShowDeleteDialog(false)}
                        disabled={deleting}
                        className="border-input hover:bg-accent hover:text-accent-foreground"
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="destructive"
                        className="gap-2"
                        onClick={handleDelete}
                        disabled={deleting}
                      >
                        {deleting ? (
                          <Loader2 className="h-4 w-4 animate-spin" />
                        ) : (
                          <Trash2 className="h-4 w-4" />
                        )}
                        Delete Project
                      </Button>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ProjectSettings;

// <Card>
//   <CardHeader>
//     <CardTitle className="flex items-center gap-2 text-lg">
//       <Github className="h-5 w-5 text-primary" />
//       GitHub Integration
//     </CardTitle>
//     <CardDescription>
//       Configure repository synchronization settings
//     </CardDescription>
//   </CardHeader>
//   <CardContent className="space-y-6">
//     <div className="flex items-center justify-between p-4 rounded-lg bg-primary/5 border border-primary/10">
//       <div className="flex items-center gap-3">
//         <div className="h-8 w-8 rounded-full bg-primary/10 flex items-center justify-center">
//           {isGithubEnabled ? (
//             <Check className="h-4 w-4 text-primary" />
//           ) : (
//             <Github className="h-4 w-4 text-primary" />
//           )}
//         </div>
//         <div>
//           <div className="font-medium">
//             {isGithubEnabled
//               ? "Connected to GitHub"
//               : "GitHub Integration"}
//           </div>
//           <div className="text-sm text-muted-foreground">
//             {isGithubEnabled
//               ? "Sync enabled with repository"
//               : "Connect to enable repository sync"}
//           </div>
//         </div>
//       </div>
//       <Switch
//         checked={isGithubEnabled}
//         onCheckedChange={setIsGithubEnabled}
//       />
//     </div>

//     <div className="space-y-4">
//       <div className="space-y-2">
//         <Label>Repository</Label>
//         <Select defaultValue="main">
//           <SelectTrigger className="focus:ring-primary">
//             <SelectValue />
//           </SelectTrigger>
//           <SelectContent>
//             <SelectItem value="main">
//               <div className="flex items-center gap-2">
//                 <Lock className="h-4 w-4" />
//                 username/e-commerce-tests
//               </div>
//             </SelectItem>
//           </SelectContent>
//         </Select>
//       </div>

//       <div className="space-y-2">
//         <Label>Default Branch</Label>
//         <Select defaultValue="main">
//           <SelectTrigger className="focus:ring-primary">
//             <SelectValue />
//           </SelectTrigger>
//           <SelectContent>
//             <SelectItem value="main">
//               <div className="flex items-center gap-2">
//                 <GitBranch className="h-4 w-4" />
//                 main
//               </div>
//             </SelectItem>
//             <SelectItem value="develop">
//               <div className="flex items-center gap-2">
//                 <GitBranch className="h-4 w-4" />
//                 development
//               </div>
//             </SelectItem>
//           </SelectContent>
//         </Select>
//       </div>

//       <div className="flex items-center gap-2 p-3 rounded-md bg-secondary/50 text-sm">
//         <RefreshCw className="h-4 w-4 text-primary animate-spin" />
//         <span>Last synced 5 minutes ago</span>
//       </div>
//     </div>
//   </CardContent>
// </Card>;
