import { useState, useCallback, useRef, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { API_BASE_URL, SERVICE_URL } from "@/config";
import { useLogger } from "@/hooks/useLogger";
import { ApiItem, TestState } from "@/types";
import { retryFetchOperation } from "@/lib/retryFetchOperation";
import { logger } from "@/utils/logger";

interface StreamCallbacks {
  onLogUpdate: (chunk: string) => void;
  onInstanceFound: (instanceUuid: string, url: string) => void;
}

const MAX_RECONNECT_ATTEMPTS = 6; // Maximum number of reconnection attempts
const NO_DATA_TIMEOUT = 300000; // 30 seconds timeout for no data

// Utility functions
const cleanStepText = (stepText: string): string => {
  return stepText.replace(/\s+/g, " ").trim();
};

export const useTestExecution = () => {
  const [testState, setTestState] = useState<TestState>({
    instanceId: null,
    stopThreadUrl: null,
    scriptSteps: [],
    isRunning: false,
    canStop: false,
    isStopping: false,
    isInCooldown: false
  });

  const { addLog, logError } = useLogger();
  const currentStreamController = useRef<AbortController | null>(null);
  const instanceStreams = useRef(new Map());
  const cooldownTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (cooldownTimeoutRef.current) {
        clearTimeout(cooldownTimeoutRef.current);
      }
    };
  }, []);

  const startCooldown = useCallback(() => {
    setTestState((prev) => ({
      ...prev,
      isInCooldown: true
    }));

    // Clear any existing timeout
    if (cooldownTimeoutRef.current) {
      clearTimeout(cooldownTimeoutRef.current);
    }

    // Set new timeout
    cooldownTimeoutRef.current = setTimeout(() => {
      setTestState((prev) => ({
        ...prev,
        isInCooldown: false
      }));
      cooldownTimeoutRef.current = null;
    }, 15000); // 15 seconds
  }, []);

  const parseLog = useCallback((log: string) => {
    const logLines = log.split("\n");

    logLines.forEach((line) => {
      const cleanLog = line.replace(/[\x1B\x9B][[?;0-9]*[a-zA-Z]/g, "").trim();
      logger.log("Parsing line:", cleanLog);

      if (cleanLog.includes("[[STEP]]")) {
        const command = cleanLog.split("[[STEP]] ")[1]?.trim();
        if (command) {
          const cleanedCommand = cleanStepText(command);
          logger.log("Processing step command:", cleanedCommand);

          setTestState((prev) => {
            // Find the matching step
            const stepIndex = prev.scriptSteps.findIndex((step) => {
              const stepText = cleanStepText(step.text);
              const isMatch = stepText
                .toLowerCase()
                .includes(cleanedCommand.toLowerCase());
              if (isMatch) {
                logger.log("Found matching step:", stepText);
              }
              return isMatch;
            });

            if (stepIndex > -1) {
              logger.log("Updating step status to yellow:", stepIndex);
              const updatedSteps = [...prev.scriptSteps];
              updatedSteps[stepIndex] = {
                ...updatedSteps[stepIndex],
                status: "yellow"
              };
              return {
                ...prev,
                scriptSteps: updatedSteps
              };
            }
            return prev;
          });
        }
      } else if (cleanLog.includes("[[STEP_STATUS]]")) {
        const statusParts = cleanLog.split("[[STEP_STATUS]] ")[1]?.split(": ");
        const stepStatus = statusParts?.[0]?.trim();
        const command = statusParts?.[1]?.trim();

        if (command) {
          const cleanedCommand = cleanStepText(command);
          logger.log(
            "Processing step status:",
            stepStatus,
            "for command:",
            cleanedCommand
          );

          setTestState((prev) => {
            const stepIndex = prev.scriptSteps.findIndex((step) => {
              const stepText = cleanStepText(step.text);
              const isMatch = stepText
                .toLowerCase()
                .includes(cleanedCommand.toLowerCase());
              if (isMatch) {
                logger.log("Found matching step for status:", stepText);
              }
              return isMatch;
            });

            if (stepIndex > -1) {
              const color = stepStatus === "STEP_SUCCESS" ? "green" : "red";
              logger.log(`Updating step status to ${color}:`, stepIndex);
              const updatedSteps = [...prev.scriptSteps];
              updatedSteps[stepIndex] = {
                ...updatedSteps[stepIndex],
                status: color
              };
              return {
                ...prev,
                scriptSteps: updatedSteps
              };
            }
            return prev;
          });
        }
      }
    });
  }, []);

  const parseTestSteps = useCallback((content: string) => {
    if (!content) return;

    const steps = content
      .split("\n")
      .map((step, index) => ({
        text: step,
        status: "grey" as const,
        id: index,
        isEmpty: step.trim() === ""
      }))
      .filter((step) => !step.isEmpty);

    setTestState((prev) => ({
      ...prev,
      scriptSteps: steps
    }));
  }, []);

  const streamStatus = useCallback(
    async (
      statusUrl: string,
      callbacks: StreamCallbacks,
      controller: AbortController
    ) => {
      const instanceName = statusUrl.split("/").pop() as string;
      const BASE_RETRY_DELAY = 1000;
      const MAX_RETRY_DELAY = 32000;
      let reconnectAttempt = 0;
      let lastDataTimestamp = Date.now();
      let testCompleted = false;

      if (instanceStreams.current.has(instanceName)) {
        logger.log(`Concurrent stream detected for ${instanceName}`);
      }

      instanceStreams.current.set(instanceName, controller);

      if (!testState.isRunning) {
        setTestState((prev) => ({ ...prev, isRunning: true, canStop: false }));
      }

      const processLine = async (line: string): Promise<boolean> => {
        const trimmedLine = line.trim();
        if (!trimmedLine) return false;

        lastDataTimestamp = Date.now();

        // Check for test completion first
        const completionMatch = trimmedLine.match(
          /(?:data:\s*)*(?:TEST_COMPLETED|THREAD_COMPLETED):\s*([^\n\s]+)/
        );
        if (trimmedLine.includes("Error installing APK")) {
          console.log("APK Installation Error detected");
          testCompleted = true;
          setTestState((prev) => ({
            ...prev,
            isRunning: false,
            canStop: false
          }));
          callbacks.onLogUpdate(
            `${trimmedLine}\nTest stopped due to APK installation error\n`
          );
          startCooldown();
          controller.abort();
          return true;
        }

        if (completionMatch) {
          console.log("Test Completed");
          testCompleted = true;
          setTestState((prev) => ({
            ...prev,
            isRunning: false,
            canStop: false
          }));
          callbacks.onLogUpdate(`${trimmedLine}\n`);
          startCooldown();
          controller.abort();
          return true;
        }

        if (!testCompleted) {
          // Extract and clean any prefixes (like timestamps and 'data:')
          const prefixMatch = trimmedLine.match(/^\[(.*?)\](?:data:\s*)?(.*)$/);
          if (prefixMatch) {
            const [_, timestamp, content] = prefixMatch;

            // Check if this is a connection status message
            if (
              content.includes("Successfully connected to instance") ||
              content.includes("Connected ADB devices") ||
              content.includes("List of devices attached")
            ) {
              callbacks.onLogUpdate(`${trimmedLine}\n`);
              return false;
            }

            // Check for JSON content
            try {
              JSON.parse(content);
              // If we get here, it's valid JSON - format it and only show it once
              const formattedJson = JSON.stringify(
                JSON.parse(content),
                null,
                2
              );
              if (!content.startsWith("{") && !content.startsWith("[")) {
                // If JSON was embedded in a message, preserve the message
                callbacks.onLogUpdate(
                  `[${timestamp}] ${content.split("{")[0]}\n`
                );
              }
              callbacks.onLogUpdate(`[${timestamp}]\n${formattedJson}\n`);
              return false;
            } catch {
              // Not JSON, process normally
              callbacks.onLogUpdate(`${trimmedLine}\n`);
            }
          } else {
            // No timestamp prefix, check if it's standalone JSON
            try {
              JSON.parse(trimmedLine);
              const formattedJson = JSON.stringify(
                JSON.parse(trimmedLine),
                null,
                2
              );
              callbacks.onLogUpdate(`${formattedJson}\n`);
              return false;
            } catch {
              // Not JSON, process normally
              callbacks.onLogUpdate(`${trimmedLine}\n`);
            }
          }
          // TODO: Stop if see this error more than 10 times
          // Process for "No more data" condition
          if (trimmedLine.includes("No more data!")) {
            const timeSinceLastData = Date.now() - lastDataTimestamp;
            if (timeSinceLastData > NO_DATA_TIMEOUT) {
              callbacks.onLogUpdate(
                `No data timeout reached, attempting to reconnect...\n`
              );
              return false;
            }
          }

          parseLog(trimmedLine);

          // Process instance and WebRTC information
          const uuidMatch = trimmedLine.match(/instance_uuid:\s*([a-f0-9-]+)/i);
          const webrtcUrlMatch = trimmedLine.match(
            /webrtc_url:\s*(wss:\/\/[^\s]+)/i
          );

          if (uuidMatch && webrtcUrlMatch) {
            callbacks.onInstanceFound(uuidMatch[1], webrtcUrlMatch[1]);
          }

          // Process stop thread URL
          const stopUrlMatch = trimmedLine.match(
            /stop_thread_command:\scurl\s(https:\/\/[^\s,"]+)/
          );
          if (stopUrlMatch) {
            setTestState((prev) => ({
              ...prev,
              stopThreadUrl: stopUrlMatch[1],
              canStop: true,
              isRunning: true
            }));
          }
        }

        return false;
      };

      const startStream = async () => {
        let buffer = "";
        let noDataChecker: NodeJS.Timeout | null = null;
        let jsonBuffer = "";
        let isCollectingJson = false;

        while (!testCompleted && reconnectAttempt < MAX_RECONNECT_ATTEMPTS) {
          try {
            const response = await retryFetchOperation(
              () =>
                fetch(statusUrl, {
                  method: "GET",
                  headers: {
                    "ngrok-skip-browser-warning": "1",
                    Accept: "text/event-stream",
                    Connection: "keep-alive",
                    "Cache-Control": "no-cache"
                  },
                  signal: controller.signal
                }),
              {
                onRetry: (attempt) => {
                  if (!testCompleted) {
                    callbacks.onLogUpdate(`Retrying connection`);
                  }
                }
              }
            );

            if (!response.ok) {
              throw new Error(
                `Failed to fetch status stream: ${response.status}`
              );
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder();

            try {
              if (noDataChecker) {
                clearInterval(noDataChecker);
              }

              noDataChecker = setInterval(() => {
                if (!testCompleted) {
                  const timeSinceLastData = Date.now() - lastDataTimestamp;
                  if (timeSinceLastData > NO_DATA_TIMEOUT) {
                    reader.cancel();
                    if (noDataChecker) {
                      clearInterval(noDataChecker);
                    }
                    callbacks.onLogUpdate(
                      `No data timeout reached, attempting to reconnect...\n`
                    );
                  }
                } else {
                  if (noDataChecker) {
                    clearInterval(noDataChecker);
                  }
                }
              }, 5000);

              while (!testCompleted) {
                const { done, value } = await reader.read();

                if (done || testCompleted) {
                  if (noDataChecker) {
                    clearInterval(noDataChecker);
                  }
                  break;
                }

                buffer += decoder.decode(value, { stream: true });
                const lines = buffer.split("\n");
                buffer = lines.pop() || "";

                for (const line of lines) {
                  const trimmedLine = line.trim();
                  if (!trimmedLine) continue;

                  if (isCollectingJson) {
                    jsonBuffer += "\n" + trimmedLine;
                    try {
                      JSON.parse(jsonBuffer);
                      // If successful, process the complete JSON
                      await processLine(jsonBuffer);
                      jsonBuffer = "";
                      isCollectingJson = false;
                    } catch {
                      // If parsing fails, continue collecting
                      continue;
                    }
                  } else if (
                    trimmedLine.startsWith("{") ||
                    trimmedLine.startsWith("[")
                  ) {
                    // Start collecting new JSON
                    isCollectingJson = true;
                    jsonBuffer = trimmedLine;
                  } else {
                    // Process normal line
                    const shouldStop = await processLine(trimmedLine);
                    if (shouldStop) {
                      if (noDataChecker) {
                        clearInterval(noDataChecker);
                      }
                      return;
                    }
                  }
                }
              }
            } catch (error) {
              if (error instanceof Error && error.name === "AbortError") {
                return;
              }
              throw error;
            } finally {
              reader.releaseLock();
              if (noDataChecker) {
                clearInterval(noDataChecker);
              }
            }

            if (testCompleted) {
              break;
            }
          } catch (error) {
            if (error instanceof Error) {
              if (error.name === "AbortError") {
                return;
              }

              reconnectAttempt++;
              if (reconnectAttempt < MAX_RECONNECT_ATTEMPTS && !testCompleted) {
                const backoffDelay = Math.min(
                  BASE_RETRY_DELAY * Math.pow(2, reconnectAttempt - 1),
                  MAX_RETRY_DELAY
                );
                await new Promise((resolve) =>
                  setTimeout(resolve, backoffDelay)
                );
                continue;
              } else if (!testCompleted) {
                callbacks.onLogUpdate(
                  `Max reconnection attempts reached. Stream ended.\n`
                );
                Sentry.captureException(error);
                logError(
                  `Stream failed after ${MAX_RECONNECT_ATTEMPTS} attempts: ${error.message}`
                );
              }
            }
          }
        }
      };

      try {
        await startStream();
      } catch (error) {
        if (error instanceof Error && error.name !== "AbortError") {
          Sentry.captureException(error);
          logError(`${error.message}`);
        }
      } finally {
        instanceStreams.current.delete(instanceName);
        if (instanceStreams.current.size === 0) {
          setTestState((prev) => ({
            ...prev,
            isRunning: false,
            canStop: false
          }));
        }
      }
    },
    [logError, parseLog]
  );

  const startTest = useCallback(
    async (
      instanceName: string,
      appName: string,
      testFile: File,
      saveInstance: boolean,
      apiFiles?: File[],
      appPackages?: any
    ) => {
      const formData = new FormData();
      formData.append("instance_name", instanceName);
      formData.append("app_name", appName);
      formData.append("test_file", testFile);
      if (saveInstance) {
        formData.append("save_instance", "True");
      }

      // Handle API files if provided
      if (apiFiles && apiFiles.length > 0) {
        apiFiles.forEach((file) => {
          formData.append("api_files", file);
        });
      }
      if (appPackages) {
        formData.append("app_identifiers", JSON.stringify(appPackages));
      }

      try {
        const response = await retryFetchOperation(
          () =>
            fetch(`${API_BASE_URL}/test/`, {
              method: "POST",
              body: formData
            }),
          {
            onRetry: (attempt) => {
              addLog(`Retrying test start (attempt ${attempt})...`, "info");
            }
          }
        );

        if (!response.ok) {
          const errorData = await response.json();

          throw new Error(errorData.detail || "Failed to start the test");
        }

        const data = await response.json();
        const test_log_id = data.message.match(/Request UUID: ([^\s]+)/)[1];
        console.log("test_log_id:", test_log_id);
        setTestState((prev) => ({ ...prev, isRunning: true }));
        return { ...data, test_log_id };
      } catch (error) {
        if (error instanceof Error) {
          Sentry.captureException(error);
          logError(error.message);
        }
        throw error;
      }
    },
    [logError]
  );

  const stopTest = useCallback(async () => {
    if (!testState.stopThreadUrl) {
      addLog("Stop URL not available. Cannot stop the test.", "error");
      return;
    }

    const confirmed = window.confirm(
      "Test will be stopped once the AI performs the existing step."
    );

    if (!confirmed) return;

    setTestState((prev) => ({
      ...prev,
      isStopping: true
    }));

    try {
      const STOP_URL = `${SERVICE_URL}/stop_thread/${testState.stopThreadUrl.substring(
        testState.stopThreadUrl.lastIndexOf("/") + 1
      )}`;

      const response = await fetch(STOP_URL, {
        method: "GET",
        headers: { "ngrok-skip-browser-warning": "1" }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      addLog("Test stopped successfully.", "info");

      setTestState((prev) => ({
        ...prev,
        stopThreadUrl: null,
        isRunning: false,
        isStopping: false
      }));

      if (currentStreamController.current) {
        currentStreamController.current.abort();
        currentStreamController.current = null;
      }
    } catch (error) {
      Sentry.captureException(error);

      if (error instanceof Error) {
        addLog(`Failed to stop test: ${error.message}`, "error");
      }

      setTestState((prev) => ({
        ...prev,
        isRunning: true,
        isStopping: false
      }));
    }
  }, [testState.stopThreadUrl, addLog]);

  const clearState = useCallback(async () => {
    try {
      // Stop disposable instance if it exists
      if (testState.instanceId) {
        const requestInit = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-token": import.meta.env.VITE_API_TOKEN
          }
        };

        try {
          const response = await fetch(
            `https://api.geny.io/cloud/v1/instances/${testState.instanceId}/stop-disposable`,
            requestInit
          );
          const data = await response.json();
          logger.log("Instance stop response:", data);
        } catch (error) {
          logger.error("Error stopping instance:", error);
        }
      }

      // Abort any running streams
      if (currentStreamController.current) {
        currentStreamController.current.abort();
        currentStreamController.current = null;
      }

      // Clear all existing streams
      for (const controller of instanceStreams.current.values()) {
        controller.abort();
      }
      instanceStreams.current.clear();

      // Reset all state
      setTestState({
        instanceId: null,
        stopThreadUrl: null,
        scriptSteps: [],
        isRunning: false,
        canStop: false,
        isStopping: false,
        isInCooldown: false
      });

      // Add initial log message
      addLog("Logs will appear here...", "info");

      // Reset player iframe if it exists
      const playerFrame = document.querySelector("iframe");
      if (playerFrame) {
        playerFrame.src = "about:blank";
      }
    } catch (error) {
      Sentry.captureException(error);
      logger.error("Error during reset:", error);
    }
  }, [testState.instanceId, addLog]);

  return {
    testState,
    setTestState,
    parseTestSteps,
    startTest,
    streamStatus,
    stopTest,
    clearState,
    currentStreamController
  };
};
