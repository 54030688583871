export const SINGLE_TEST_URL = "https://bf0f-98-130-11-50.ngrok-free.app";
export const API_BASE_URL = "https://e5b0-18-61-30-71.ngrok-free.app"; //geny motion service url
export const SERVICE_URL = "https://c60f-18-61-67-59.ngrok-free.app"; //core service url
export const GITHUB_CLIENT_ID = import.meta.env.VITE_GITHUB_CLIENT_ID;
export const GITHUB_REDIRECT_URI = import.meta.env.VITE_GITHUB_REDIRECT_URI;

// Create a new file: src/config/github.ts
export const GITHUB_SCOPES = [
  "repo",
  "user",
  "repo:status",
  "public_repo",
  "repo:invite"
];

export const GITHUB_SCOPE_STRING = GITHUB_SCOPES.join(" ");
